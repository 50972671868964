import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSubjectService, postPublicationsService, postTestAdminService } from "../../../services/publish"
import { useSubheader } from "../../../_metronic/layout";

import { useGeneral } from "../../../hooks/use-general";
import { Modal } from "@material-ui/core";

export const YayinEkle = props => {
    const token = localStorage.getItem('token');
    const suhbeader = useSubheader();
    suhbeader.setTitle("Yayınevleri");
    const history = useHistory();

    const { setLoading } = useGeneral();
    const [loadingSubject, setLoadingSubject] = useState(0);
    const [dekSubjectList, setDekSubjectList] = useState([]);
    const [lessonList, setLessonList] = useState(props?.location?.lesson);
    const [excelList, setExcelList] = useState([]);
    const [columnCount, setColumnCount] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState("");


    console.log(props)

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        if (loadingSubject >= props?.location?.lesson.length) {
            dataFormat();
            setLoading(false);
        }
    }, [props?.location?.excelFile, loadingSubject]);

    useEffect(() => {
        if (props?.location?.level?.id && props?.location?.season?.id && props?.location?.lesson) {
            const lessons = props?.location?.lesson;

            lessons.map(item => {
                getSubjectService(props?.location?.level?.id, item.id, props?.location?.season?.id)
                    .then(response => {
                        if (response.data) {
                            let subjectList = [...dekSubjectList] || [];
                            response.data.map(subject => subjectList.push(subject));
                            
                            subjectList.sort(function (a, b) {
                                return a.name.localeCompare(b.name);
                            });

                            
                            setDekSubjectList(subjectList);
                        }
                        setLoadingSubject(x => x + 1);
                    })
                    .catch(err => {
                        setLoadingSubject(x => x + 1);
                    });
            });
        }
    }, [props?.location?.level, props?.location?.season]);

    const dataFormat = () => {
        if (props?.location?.excelFile) {
            const dataList = props?.location?.excelFile || [];
            const excelData = [];
            let count = 0;

            dataList.map(x => {
                if (Object.keys(x).length - 3 > count) {
                    count = Object.keys(x).length - 3;
                }
            });

            // tekrar eden konu adlarında yenileme
            let isimEkKontrol = 1;
            let prevName = '';
            const isimTekrarKontrol = [];
            let changedName = '';
            let nameStatus = false;
            dataList.map((x,i) => {
                let konuAdi = x["konu adı"].trim();

                if(konuAdi !== prevName) {
                    prevName = konuAdi;
                    nameStatus = false;
                    if(isimTekrarKontrol.indexOf(konuAdi) > -1) {
                        isimEkKontrol++;
                        konuAdi = konuAdi + ' ' +  isimEkKontrol;
                        changedName = konuAdi;
                        nameStatus = true;
                        isimTekrarKontrol.push(konuAdi);
                    }
                    
                    isimTekrarKontrol.push(konuAdi);
                }

                if(nameStatus) {
                    x["konu adı"] = changedName;
                }
                else {
                    x["konu adı"] = konuAdi;
                }
            });

            dataList.map((item, i) => {
                const index = excelData.findIndex(x => x.konuAdi === item["konu adı"]);

                if (index === -1) {
                    const yayinKonuAdi = item["konu adı"].replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase();
                    const indexSubject = dekSubjectList.findIndex(lssn => (lssn.name).replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === yayinKonuAdi);
                    if (indexSubject > -1) {
                        excelData.push({
                            konuAdi: item["konu adı"],
                            dersAdi: item.ders.name,
                            dersID: item.ders.id,
                            konuDek: dekSubjectList[indexSubject].name,
                            konuDekID: dekSubjectList[indexSubject].id,
                            testler: []
                        });
                    }
                    else {
                        excelData.push({
                            konuAdi: item["konu adı"],
                            dersAdi: item.ders.name,
                            dersID: item.ders.id,
                            konuDek: '',
                            konuDekID: '',
                            testler: []
                        })
                    }
                }
            });

            const list = [];
            for (let i = 1; i <= count + 4; i++) {
                list.push(i);
            }

            dataList.map((item, i) => {
                const cevapList = [];
                list.map(x => {
                    item[x] && cevapList.push(item[x]);
                });

                if (cevapList.length < list.length) {
                    const fark = list.length - cevapList.length;
                    for (let n = 0; n < fark; n++) {
                        cevapList.push('');
                    }
                }

                const konuIndex = excelData.findIndex(x => x.konuAdi === item["konu adı"]);

                if (konuIndex > -1) {
                    excelData[konuIndex].testler.push({
                        testAdi: item["test adı"],
                        sure: item["soru süresi"],
                        cevaplar: cevapList
                    })
                }
            });

            setExcelList(excelData);
            setColumnCount(list);
        }
    }

    const modalChange = event => {
        let liste = [...excelList];

        if (selectedItem.text === "konuDek") {
            const index = dekSubjectList.findIndex(x => x.id === event.target.value);
            liste[selectedItem.index]['konuDek'] = dekSubjectList[index].name
            liste[selectedItem.index]['konuDekID'] = event.target.value
        }
        else if (selectedItem.text === "dersAdi") {
            const index = lessonList.findIndex(x => x.name === event.target.value);
            //console.log(lessonList[index])
            liste[selectedItem.index].dersID = lessonList[index].id
            liste[selectedItem.index].dersAdi = lessonList[index].name
        }

        setExcelList(liste);
    };

    const save = () => {
        let count = 0;
        setLoading(true);

        const excelData = excelList.map(item => {
            item.testler.map(test => {
                test.cevaplar = test.cevaplar.filter(cevap =>  cevap != "")
            })

            let newList = {
                konuDekID: item.konuDekID,
                konuAdi: item.konuDekID,
                testler: item.testler
            }
            return newList;
        });

        excelData.map(item => {
            if (!item?.konuDekID) {
                //console.log('konuDekID', item?.konuDek);
                count++;
            }
            else if (!item.konuAdi) {
                //console.log('konu', item?.konuAdi);
                count++;
            }
    
    
          item.testler.map(test => {
            if ('ttestadı', !test.testAdi) {
                //console.log(item?.testAdi);
                count++;
            }
            else if (!test.sure) {
                //console.log('sure', item?.sure);
                count++;
            }
    
            test.cevaplar.map((x, sira) => {
              if (!x.cevap) {
                if (test.cevaplar.length >= sira + 1) {
                  for (let m = sira + 1; m < test.cevaplar.length; m++) {
                    if (test.cevaplar[m].cevap) {
                        console.log('cevap', test?.cevaplar);
                        count++;
                    }
                  }
                }
              }
            });
          });
        });
    
        if (count > 0) {
            setError('Lütfen eksiksiz doldurun. Kırmızı ile gösterilen alanlara tıklayarak verilerini girebilirsiniz...')
        }
        else {
            const formData = new FormData();
            const data = JSON.stringify({
                name: props?.location?.excelFile[0]["kitap adı"],
                ISBN: (props?.location?.excelFile[0]["ısbn"]).toString(),
                season: {_id: props?.location?.season?.id},
                isPublished: false,
                level: {_id: props?.location?.level?.id},
                lesson: {_id: props?.location?.excelFile[0].ders.id},
                publisher: {_id: props?.location?.publisher}
            });
    
            formData.append('files.cover', props?.location?.imageFile, props?.location?.imageFile.name);
            formData.append('data', data);

            postPublicationsService(formData)
                .then((res) => {
                    const list = {
                        publicationID: res.data.id,
                        levelID: res.data.level.id,
                        list: excelData
                    }
                
                    postTestAdminService(list, res.data.id).then(response => {
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setError(error.error)
                        setLoading(false);
                    });
                }).catch(err => {
                    console.log('err', err);
                    setError(err.error)
                    setLoading(false);
                });  
        }
    }

    const editModelOpen = (index, i, j, text) => {
        setOpenModal(true);
        setSelectedItem({ index, i, j, text });
    };

    const modelClose = () => {
        setOpenModal(false);
    };

    const anserChange = (value, index, i, j) => {
        let liste = [...excelList];
        let length = value.length;
        let cevap = value.toUpperCase();

        if (length > 1) {
            cevap = cevap.substring(1, 2);
        }

        if (['A', 'B', 'C', 'D', 'E', 'F', 'T', ''].indexOf(cevap) > -1) {
            liste[index].testler[i].cevaplar[j] = cevap;
            setExcelList(liste);
        }
    }

    const textChange = (value, index, i, type) => {
        let list = [...excelList];

        if (type === 'konuAdi') {
            list[index].konuAdi = value;
            setExcelList(list);
        }
        else if (type === 'testAdi') {
            //console.log(list[index])
            list[index].testler[i].testAdi = value;
            setExcelList(list);
        }
        else if (type === 'sure') {
            value = parseInt(value);
            
            if( Number.isInteger(value)) {
                list[index].testler[i].sure = value;

                setExcelList(list);
            }
        }
    }

    return (
        <>
            <div className={"card card-custom gutter-b "}>
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="card-label">{props?.location?.excelFile[0] && props?.location?.excelFile[0]["kitap adı"]} Kitabının Cevap Anahtarı</h3>
                    </div>
                    <div className="card-toolbar">
                        <div className="headerToolbar">
                            <span className="btn font-weight-bolder font-size-sm btn-success" onClick={save}>
                                Kaydet
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="excelContainer">
                        {
                            error && <span className="fade alert alert-danger show" style={{display: 'flex'}}>{ error }</span>
                        }

                        <div className="excelTable">
                            <div className="excelHeader" style={{ width: 525 + 24 * columnCount.length }}>
                                <strong className="testAdi">Ders Adı</strong>
                                <strong className="konuAdi">Kitaptaki Konu Adı</strong>
                                <strong className="konuAdi">Müfredat Konu Adı</strong>
                                <strong className="testAdi">Test Adı</strong>
                                <strong className="testSure">Süre</strong>
                                {
                                    columnCount.map(x => <strong className="testCevap" key={x}>{x}</strong>)
                                }
                            </div>
                            <div className="excelBody" style={{ width: 525 + 24 * columnCount.length }}>
                                {
                                    excelList &&
                                    excelList.map((item, index) =>
                                        <div key={index} className="excelRow">
                                            <span className={"testAdi" + (!item.dersAdi ? ' hata' : '')} onClick={() => { editModelOpen(index, 'i', 'j', 'dersAdi'); }}>{item.dersAdi}</span>
                                            {
                                                <textarea className={"konuAdi" + (!item.konuAdi ? ' hata' : '')} value={item?.konuAdi} onChange={e => textChange(e.target.value, index, 0, 'konuAdi')} />
                                            }
                                            <span className={"konuAdi" + (!item.konuDekID ? ' hata' : '')} onClick={() => { editModelOpen(index, 'i', 'j', 'konuDek'); }}>{item.konuDek}</span>
                                            <div>
                                                {
                                                    item.testler.map((test, i) =>
                                                        <div className="excelTest" key={index + " - " + i}>
                                                            <input className={"testAdi" + (!test.testAdi ? ' hata' : '')} value={test?.testAdi || ''} onChange={e => textChange(e.target.value, index, i,  'testAdi') } />
                                                            <input className={"testSure" + (!test.sure ? ' hata' : '')}   value={test?.sure || ''}    onChange={e => textChange(e.target.value, index, i, 'sure') } />
                                                            {
                                                                test.cevaplar.map((x, j) =>
                                                                    <input className={"testCevap" + (!x ? ' hata' : '')}
                                                                        key={index + " - " + i + " - " + j}
                                                                        value={x}
                                                                        onChange={e => anserChange(e.target.value, index, i, j)} />
                                                                )
                                                            }
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={openModal}
                onClose={modelClose}>
                <div className="basicModal">
                    <div className="modalHeader">
                        <div className="modalTitle">Cevap Anahtarı Düzenle</div>
                        <div className="modalToolbar">
                            <span className="closeButton" onClick={modelClose}>
                                <i className="flaticon2-cancel-music" style={{ fontSize: 14 }} />
                            </span>
                        </div>
                    </div>
                    <div className="modalBody">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="modelContainer">
                                    {
                                        <div className="modelLabel">
                                            {
                                                selectedItem?.text === 'konuDek' ? 'Müfredattaki Konu Adını Seç' :
                                                selectedItem?.text === 'dersAdi' ? 'Ders Adını Seç' :
                                                selectedItem?.text === 'konuAdi' ? 'Konu Adı Düzenle' :
                                                selectedItem?.text === 'testAdi' ? 'Test Adı Düzenle' :
                                                selectedItem?.text === 'sure' ? 'Her Soru İçin Süre Düzenle' :
                                                selectedItem?.text === 'cevaplar' && 'Cevap Düzenle'
                                            }
                                        </div>
                                    }

                                    {
                                        selectedItem?.text === 'dersAdi' &&
                                        <select className="modelInput" type="text" value={excelList[selectedItem.index]?.dersID} onChange={modalChange}  >
                                            {
                                                excelList[selectedItem.index]?.dersAdi ?
                                                    <option value={excelList[selectedItem.index]?.dersID}>{excelList[selectedItem.index]?.dersAdi}</option>
                                                    :
                                                    <option value="">Seçiniz</option>
                                            }

                                            {
                                                lessonList.map(x => <option key={x.id} value={x.name}>{x.name}</option>)
                                            }
                                        </select>
                                    }

                                    {
                                        selectedItem?.text === 'konuDek' &&
                                        <select className="modelInput" type="text" value={excelList[selectedItem.index]?.konuDekID} onChange={modalChange} >
                                            {
                                                excelList[selectedItem.index]?.konuDek.konuDekID ?
                                                    <option value={excelList[selectedItem.index]?.konuDekID}>{excelList[selectedItem.index]?.konuDekAdi}</option>
                                                    :
                                                    <option value="">Seçiniz</option>
                                            }

                                            {
                                                dekSubjectList.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                            }
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modalFooter">
                        <span className="btn font-weight-bolder font-size-sm btn-success" onClick={modelClose}>Tamam</span>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const cardTextStyle = {
    cursor: "pointer"
};

