import React, {useState, useEffect} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {Logout, AuthPage} from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {AuthContext} from "../hooks/use-auth";
import {GeneralContext} from "../hooks/use-general";
import {LoadingScreen} from "./pages/components/Loading";
import { getSeasons } from "./pages/admin/adminServices/sezonServices";
import { getLevels }  from "./pages/admin/adminServices/seviyeServices";
import { getLessons }  from "./pages/admin/adminServices/dersServices";
import { getCustomSubjects }  from "./pages/admin/adminServices/konuServices";

export function Routes() {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [publisherID, setPublisherID] = useState(null);
    const [teacherID, setTeacherID] = useState(null);
    const [adminID, setAdminID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [seasons, setSeasons] = useState([]);
    const [levels, setLevels] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [customSubjects, setCustomSubjects] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [isMiniLoading, setIsMiniloading] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [examPublication, setExamPublication] = useState(false);
    // Level Belirleme
    const [levelID, setLevelID] = useState(null);
    const [levelName, setLevelName] = useState(null);

    // Lesson Belirleme
    const [lessonID, setLessonID] = useState(null);
    const [lessonName, setLessonName] = useState(null);

    // Yayın Belirleme
    const [publicationID, setPublicationID] = useState(null);
    const [publicationName, setPublicationName] = useState(null);
    const [publicationCover, setPublicationCover] = useState(null);
    //ISBN
    const [ISBN, setISBN] = useState(null);

    useEffect(() => {
        const getToken = localStorage.getItem('token');
        const getUser = JSON.parse(localStorage.getItem('user'));

        if (getToken && getUser) {
            setToken(getToken);
            setUser(getUser);
            setIsAuthorized(true);

            if (getUser.role.name === 'Publisher') {
                setPublisherID(getUser.publisher._id);
            }
            else if (getUser.role.name === 'Teacher') {
                setTeacherID(getUser.id);
            }
            else if (getUser.role.name === 'Admin') {
                setAdminID(getUser.id);
            }
        } else {
            logout();
        }
    }, []);

    const login = (token, user) => {
        if (token && user) {
            setToken(token);
            setUser(user);
            setIsAuthorized(true);
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user)); 
        }

        if (user.role.name === 'Publisher') {
            setPublisherID(user.publisher._id);
        }
        else if (user.role.name === 'Teacher') {
            setTeacherID(user.id);
        }
        else if (user.role.name === 'Admin') {
            setAdminID(user.id);
        }
    }

    const logout = () => {
        setToken(null);
        setUser(null);
        setIsAuthorized(false);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setPublisherID(null);
        setTeacherID(null);
        setAdminID(null);
    }

    const getAllSeasons = () => {
        if(token) {
            getSeasons(token)
                .then(res => {
                    let data = res.data;
                    data.map(item => {
                        let seasonName = item.name.split("-");
                        item.name1 = seasonName[0];
                        item.name2 = seasonName[1];
                    })
                    setSeasons(res.data);
                })
                .catch(error => console.log('error', error));
        }
    }

    const getAllLevels = () => {
        if(token) {
            getLevels(token).then(res => {
                setLevels(res.data);
            }).catch(error => console.log('error', error));
        }
    }

    const getAllLessons = () => {
        if(token) {
            getLessons(token).then(res => {
                setLessons(res.data);
            }).catch(error => console.log('error', error));
        }
    }

    const getAllCustomSubjects = (level, lesson, season) => {
        if(token && level && lesson && season) {
            setIsMiniloading(true);
            getCustomSubjects(token, level, lesson, season)
            .then(res => {
                setCustomSubjects(res.data);
                setIsMiniloading(false);
            })
            .catch(error => console.log('error', error));
        }
    }
    
    useEffect(() => {
        getAllSeasons();
        getAllLevels();
        getAllLessons();
    }, [token])

    return (
        <GeneralContext.Provider value={{ examPublication, setExamPublication ,publicationName, setPublicationName, lessonName, setLessonName, levelName, setLevelName, ISBN, setISBN, publicationCover, setPublicationCover, publicationID, setPublicationID, setLevelID, levelID, setLessonID, lessonID, loading, setLoading, seasons, levels, lessons, getAllCustomSubjects, customSubjects, isLoading, setIsloading, isMiniLoading, excelData, setExcelData }}>
            <AuthContext.Provider value={{ login, token, user, logout, isAuthorized, publisherID, teacherID, adminID }}>
                <Switch>
                    {!isAuthorized ? (
                        <Route>
                            <AuthPage/>
                        </Route>
                    ) : (
                        <Redirect from="/auth" to="/"/>
                    )}

                    <Route path="/error" component={ErrorsPage}/>
                    <Route path="/logout" component={Logout}/>

                    {!isAuthorized ? (
                        <Redirect to="/auth/login"/>
                    ) : (
                        <Layout>
                            <BasePage/>
                        </Layout>
                    )}
                </Switch>
                {
                    loading && <LoadingScreen/>
                }
            </AuthContext.Provider>
        </GeneralContext.Provider>
    );
}
