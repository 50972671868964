import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import { useAuth } from "../../hooks/use-auth";
import { getLevelLessonSubjectsService, getLevelLessonsService, getTeacherTestsService, postHomeworkTestService, getTeacherTest, getLevelsLeanService, updateHomeworkTestService } from "../../services/teacherTest";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Tests = (props) => {
  const { token, user } = useAuth();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [test, setTest] = useState(null)
  const [levels, setLevels] = useState(null);
  const [lessons, setLessons] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [editQuestions, setEditQuestions] = useState([]);

  const [level, setLevel] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [questionCount, setQuestionCount] = useState(0);
  const [optionCount, setOptionCount] = useState(5);
  const [testTime, setTestTime] = useState(0);
  const [testName, setTestName] = useState('');
  const [onay, setOnay] = useState(false);
  const [errorRow, setErrorRow] = useState([]);

  const [lessonItem, setLessonItem] = useState(null);
  const [testList, setTestList] = useState(null);
  const [testItem, setTestItem] = useState(null);
  const [testDetail, setTestDetail] = useState(null);


  useEffect(() => {
    if(props.location?.state?.status === 'newTest') {
      dialogOpen();
    }
  }, []);

  useEffect(() => {
    if( questionCount > 0 && lesson && level) {
      let questionList = [];

      for(let i = 0; i < questionCount; i++) {
        questionList.push({
          num: i + 1,
          subject: null,
          correctAnswer: null,
          expectedDuration: Math.ceil(testTime),
          optionCount
        })
      }
      setQuestions(questionList);

      getLevelLessonSubjectsService(level._id, lesson._id).then(response => {
        if(response.data) {
          setSubjects(response.data);

          questionList.map(item => {
            item.subject = response.data[0].id;
          });

          setQuestions(questionList);
        }
      }).catch(err => {
        console.log(err)
      });
    }
  }, [onay])

  useEffect(() => {
    if( edit ) {
      getLevelLessonSubjectsService(testList.id, lessonItem.id).then(response => {
        if(response.data) {
          setSubjects(response.data);

        }
      }).catch(err => {
        console.log(err)
      });
    }
  }, [edit])

  useEffect(() => {
    level &&
    getLevelLessonsService(level.id).then(response => {
      if(response.data) {
        setLessons(response.data)
      }
    })
    .catch(err => {
      console.log(err)
    });
  }, [level])

  useEffect(() => {
    getTest();
  }, [user, token]);

  const getTest = () => {
    setLoadingPage(true);

    getTeacherTestsService().then(response => {
      if(response.data) {
        setLoadingPage(false);
        if(response.data) {
          response.data.map(item => {
            let testCount = 0;
            item.lesson.levels.map(x => {
              if(x.tests) {
                testCount += x.tests.length;
              }
            });

            item.lesson.testCount = testCount;
          });

          setTest(response.data);
        }
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  const dialogOpen = () => {
    setOpen(true);
    setLoading(true);

    getLevelsLeanService().then(response => {
      setLoading(false);

      if(response.data) {
        setLevels(response.data)
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  const dialogClose = () => {
    setOpen(false);
  }

  const setQuestionTime = (id, value) => {
    const newList = [...questions];
    newList[id].expectedDuration = Math.ceil(value);
    setQuestions(newList);
  }

  const selectOption = (id, value) => {
    const newList = [...questions];
    newList[id].correctAnswer = value;
    setQuestions(newList);
  }

  const setSubject = (id, value) => {
    const newList = [...questions];

    for(let i = id; i < questionCount; i++) {
      newList[i].subject = value;
    }

    setQuestions(newList);
  }

  const setEditSubject = (id, value) => {
    const newList = [...editQuestions];
    const index = subjects.findIndex(item => item.id === value);
    newList[id].subject = subjects[index];
    setEditQuestions(newList);
  }

  const setEditQuestionTime = (id, value) => {
    const newList = [...editQuestions];
    newList[id].soruSuresi = Math.ceil(value);
    setEditQuestions(newList);
  }

  const editSelectOption = (id, value) => {
    const newList = [...editQuestions];
    newList[id].dogruCevap = value;
    setEditQuestions(newList);
  }

  const testAddDelete = () => {
    setOpen(false);
    setLoading(false);
    setOpen(false);
    setLesson(null);
    setLevel(null);
    setOptionCount(4);
    setTestName('');
    setTestTime(0);
    setQuestionCount(0);
    setOnay(false);
  }

  const save = () =>  {
    const err = [];

    questions.map((item, i) => {
      if(!item.correctAnswer) {
        err.push(i);
      }
    });

    setErrorRow(err);

    if(err.length === 0) {
      setLoading(true);
      postHomeworkTestService({ user: user.id, name: testName, level: level._id, lesson: lesson._id, questions })
        .then(response => {
          setLoading(false);
          setOpen(false);
          setLesson(null);
          setLevel(null);
          setOptionCount(4);
          setTestName('');
          setTestTime(0);
          setQuestionCount(0);
          setOnay(false);
          setOpenAlert(true);
          getTest();
        })
        .catch(err => {
          console.log(err)
        });
    }
  }

  const timeOption = (value) => {
    let dk = Math.floor(value / 60);
    let sn = value % 60;
    let sa = '';

    if(dk > 60) {
      dk = dk % 60;
      sa = Math.floor(value / 60);

      if(sa < 10){
        sa = "0" + sa;
      }

      sa = sa + ':';
    }

    if(dk < 10){
      dk = "0" + dk;
    }
    if(sn < 10){
      sn = "0" + sn;
    }

    return sa + dk + ":" + sn
  }

  const getTestDetail = value => {
    setTestItem(value);
    setTestDetail(null);
    setLoadingPage(true);

    getTeacherTest(value.id).then(response => {
      if(response.data) {
        setLoadingPage(false);
        if(response.data) {
          setTestDetail(response.data)
        }
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  const resetLesson = () => {
    setLessonItem(null);
    setTestList(null);
    setTestItem(null);
  }

  const resetLevel = () => {
    setTestList(null);
    setTestItem(null);
  }

  const resetTestItem = () => {
    setTestItem(null);
  }

  const editQuestion = () => {
    setEdit(true);
    setEditQuestions(testDetail.questions);
  }

  const editing = () => {
    //console.log('kaydet', testItem)
    setEdit(false);
    //console.log(testDetail);
    let questions = [];

    testDetail.questions.map(item =>  {
      questions.push({
        _id: item.id,
        num: item.soruNumarasi,
        subject: item.subject.id,
        correctAnswer: item.dogruCevap,
        expectedDuration:item.soruSuresi,
        optionCount: item.sikSayisi
      })
    });

    const data = {
      name: testItem.name,
      level: testList.id,
      lesson: lessonItem.id,
      questions
    }

    setLoading(true);
    updateHomeworkTestService(testItem.id, data).then(response => {
      setLoading(false);
      //console.log(response)
    })
    .catch(err => {
      console.log(err)
    });
  }

  return (<>
    {
      loadingPage &&
      <div className="teachLoading">
        <svg   width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <rect x="17.5" y="30" width="15" height="40" fill="#e15b64">
            <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="18;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
            <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="64;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
          </rect>
          <rect x="42.5" y="30" width="15" height="40" fill="#f8b26a">
            <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
            <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
          </rect>
          <rect x="67.5" y="30" width="15" height="40" fill="#abbd81">
            <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
            <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
          </rect>
        </svg>
      </div>
    }

    <div className="row">
      <div className="col-md-12">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">
                <span className="spanLink" onClick={resetLesson}>Dersler </span>
                {
                  lessonItem &&
                    <>
                      <span className="spanAra">></span>
                      <span className="spanLink" onClick={resetLevel}>{lessonItem.name} </span>
                    </>
                }
                {
                  testList &&
                  <>
                    <span className="spanAra">></span>
                    <span className="spanLink" onClick={resetTestItem}>{testList.name} </span>
                  </>
                }
              </h3>
            </div>

            <div className="card-toolbar">
              <div className="headerToolbar">
                {
                  !testItem &&
                  <span className="headerButton" onClick={dialogOpen}>
                    <span className="svg-icon svg-icon-primary svg-icon-2x">
                      <svg  width="24px" height="24px" viewBox="0 0 24 24">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect fill="#000" x="4" y="11" width="16" height="2" rx="1"/>
                          <rect fill="#000" transform="translate(12, 12) rotate(-270) translate(-12, -12)"
                                opacity="0.3" x="4" y="11" width="16" height="2" rx="1"/>
                        </g>
                      </svg>
                    </span>
                    <span>Test Ekle</span>
                  </span>
                }
                {
                  testItem  &&
                  <span className="headerButton" onClick={() => editQuestion()}>
                    <span className="svg-icon svg-icon-primary svg-icon-2x">
                      <svg  width="24px" height="24px" viewBox="0 0 24 24">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M11,3 L11,11 C11,11.0862364 11.0109158,11.1699233 11.0314412,11.2497543 C10.4163437,11.5908673 10,12.2468125 10,13 C10,14.1045695 10.8954305,15 12,15 C13.1045695,15 14,14.1045695 14,13 C14,12.2468125 13.5836563,11.5908673 12.9685588,11.2497543 C12.9890842,11.1699233 13,11.0862364 13,11 L13,3 L17.7925828,12.5851656 C17.9241309,12.8482619 17.9331722,13.1559315 17.8173006,13.4262985 L15.1298744,19.6969596 C15.051085,19.8808016 14.870316,20 14.6703019,20 L9.32969808,20 C9.12968402,20 8.94891496,19.8808016 8.87012556,19.6969596 L6.18269936,13.4262985 C6.06682778,13.1559315 6.07586907,12.8482619 6.2074172,12.5851656 L11,3 Z" fill="#000000"/>
                          <path d="M10,21 L14,21 C14.5522847,21 15,21.4477153 15,22 L15,23 L9,23 L9,22 C9,21.4477153 9.44771525,21 10,21 Z" fill="#000000" opacity="0.3"/>
                        </g>
                      </svg>
                    </span>
                    <span>Testi Düzenle</span>
                  </span>
                }
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="publishContainer">
              {
                // test detayları
                testItem && testDetail &&
                <div className="row" style={{width: '100%'}}>
                  {
                    testItem &&
                    <div className="col-md-12">
                      <h3>Test Adı: <span>{testItem.name}</span></h3>
                    </div>
                  }

                  <div className="col-md-3">
                    <div className="card card-custom bg-primary gutter-b" style={{height: 150}}>
                      <div className="card-body">
                        <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                          <svg width="24px" height="24px" viewBox="0 0 24 24">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                              <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                    fill="#000000" opacity="0.3"/>
                            </g>
                          </svg>
                        </span>
                        <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3">{testDetail.questions.length}</div>
                        <div className="text-inverse-primary font-weight-bold font-size-lg mt-1">Soru</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="card card-custom bg-primary gutter-b" style={{height: 150}}>
                      <div className="card-body">
                        <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                          <svg width="24px" height="24px" viewBox="0 0 24 24">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                              <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                    fill="#000000" opacity="0.3"/>
                            </g>
                          </svg>
                        </span>
                        <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3">{testDetail.homeworkItems.length}</div>
                        <div className="text-inverse-primary font-weight-bold font-size-lg mt-1">Atanan Ödev</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="card card-custom bg-primary gutter-b" style={{height: 150}}>
                      <div className="card-body">
                        <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                          <svg width="24px" height="24px" viewBox="0 0 24 24">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                              <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                    fill="#000000" opacity="0.3"/>
                            </g>
                          </svg>
                        </span>
                        <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3">{testDetail.basariOrtalamasi ? (testDetail.basariOrtalamasi).toFixed(2) : '0'}</div>
                        <div className="text-inverse-primary font-weight-bold font-size-lg mt-1">Başarı Ortalaması</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="card card-custom bg-primary gutter-b" style={{height: 150}}>
                      <div className="card-body">
                        <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                          <svg width="24px" height="24px" viewBox="0 0 24 24">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                              <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                    fill="#000000" opacity="0.3"/>
                            </g>
                          </svg>
                        </span>
                        <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3">{testDetail.sureOrtalamasi ? (testDetail.sureOrtalamasi).toFixed(2) : '0'}</div>
                        <div className="text-inverse-primary font-weight-bold font-size-lg mt-1">Süre Ortalaması</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="questionRow">
                      <strong>No</strong>
                      <div className="optionListBold" style={testDetail?.questions[0].sikSayisi === 5 ? {width: 125} : {width: 100}}>Şıklar</div>
                      <div className="durationBold">Süre</div>
                      <div className="subjectBold">Konu</div>
                      <div className="durationBold">En Hızlı</div>
                      <div className="durationBold">En Yavaş</div>
                      <div className="durationBold">Ortalama</div>
                      <div className="ratioBold" style={testDetail?.questions[0].sikSayisi === 5 ? {width: 375} : {width: 300}}>Cevap Sayıları</div>
                    </div>

                    {
                      testDetail.questions.map((item, i) =>
                        <div key={i} className="questionRow">
                          <strong>{item.soruNumarasi ? item.soruNumarasi : (i + 1)}</strong>
                          <div className="optionList" style={item.sikSayisi === 5 ? {width: 125} : {width: 100}}>
                            <span className={item.dogruCevap === 'A' ? 'selectedOption' : ''} title="asdas">A</span>
                            <span className={item.dogruCevap === 'B' ? 'selectedOption' : ''}>B</span>
                            <span className={item.dogruCevap === 'C' ? 'selectedOption' : ''}>C</span>
                            <span className={item.dogruCevap === 'D' ? 'selectedOption' : ''}>D</span>
                            {
                              item.sikSayisi === 5 &&
                                <span className={item.dogruCevap === 'E' ? 'selectedOption' : ''}>E</span>
                            }
                          </div>
                          <div className="duration">{item.soruSuresi} sn</div>
                          <div className="subject">{item.subject.name ? item.subject.name : ' Konu belirtilmediği için burada yok'}</div>
                          <div className="duration">{item.enHizliSure ? item.enHizliSure : '0'} sn</div>
                          <div className="duration">{item.enYavasSure ? item.enYavasSure : '0'} sn</div>
                          <div className="duration">{item.ortalamaCevaplamaSuresi ? item.ortalamaCevaplamaSuresi : '0'} sn</div>
                          <div className="ratio">A - {item.sikOrani ? item.sikOrani.A : '0'}</div>
                          <div className="ratio">B - {item.sikOrani ? item.sikOrani.B : '0'}</div>
                          <div className="ratio">C - {item.sikOrani ? item.sikOrani.C : '0'}</div>
                          <div className="ratio">D - {item.sikOrani ? item.sikOrani.D : '0'}</div>
                          {item.sikSayisi === 5 &&
                            <div className="ratio">E - {item.sikOrani ? item.sikOrani.E : '0'}</div>
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              }

              {
                // Test listesi
                !testItem && testList &&
                  testList?.tests.map((x,i) =>
                    <div key={i} className="col-md-3" >
                      <div key={i} className="d-flex align-items-center bg-light-primary rounded p-5 pointer mb-8" onClick={() => getTestDetail(x)}>
                        <span className="svg-icon svg-icon-primary mr-5 svg-icon-lg">
                          <svg width="24px" height="24px" viewBox="0 0 24 24" >
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <polygon points="0 0 24 0 24 24 0 24"/>
                              <path
                                  d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                  fill="#000000" opacity="0.3"/>
                              <path
                                  d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                  fill="#000000" opacity="0.3"/>
                              <path
                                  d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                  fill="#000000"/>
                            </g>
                          </svg>
                        </span>
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                          <span className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">{x.name}</span>
                          <span className="text-muted font-weight-bold">{x.soruSayisi} Soru | Süre:  {timeOption(x.testSuresi)}</span>
                        </div>
                      </div>
                    </div>
                  )
              }

              {
                // Level Listesi
                !testItem && !testList && lessonItem  && lessonItem.levels.map((item, i) =>
                  <div key={i} className="col-md-3" >
                    <div key={i} className="d-flex align-items-center mb-8 bg-light-danger rounded p-5 pointer" onClick={() => setTestList(item)}>
                      <span className="svg-icon svg-icon-danger mr-5 svg-icon-lg">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" >
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3"/>
                            <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000"/>
                          </g>
                        </svg>
                      </span>
                      <div className="d-flex flex-column flex-grow-1 mr-2">
                        <span className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">{item.name}</span>
                        <span className="text-muted font-weight-bold">{item.tests.length} Test</span>
                      </div>
                    </div>
                  </div>
                )
              }

              {
                //Ders Listesi
                !testItem && !testList && !lessonItem && test && test.map((item, i) =>
                  <div key={i} className="col-md-3" >
                    <div key={i} className="d-flex align-items-center mb-8 bg-light-warning rounded p-5 pointer" onClick={() => setLessonItem(item.lesson) }>
                      <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" >
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"/>
                            <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) "
                                  x="16.3255682" y="2.94551858" width="3" height="18" rx="1"/>
                          </g>
                        </svg>
                      </span>
                      <div className="d-flex flex-column flex-grow-1 mr-2">
                        <span className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">{item.lesson.name}</span>
                        <span className="text-muted font-weight-bold">{item.lesson.testCount} Test</span>
                      </div>
                    </div>
                  </div>
                )
              }

            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={dialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
      <DialogTitle >Test Ekle</DialogTitle>
      <DialogContent>
        {
          loading &&
          <div className="dialogLoading">
            <svg   width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <rect x="17.5" y="30" width="15" height="40" fill="#e15b64">
                <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="18;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="64;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
              </rect>
              <rect x="42.5" y="30" width="15" height="40" fill="#f8b26a">
                <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
              </rect>
              <rect x="67.5" y="30" width="15" height="40" fill="#abbd81">
                <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
              </rect>
            </svg>
          </div>
        }

        {
          !onay &&
          <>
            <div className="dialogContainer">
              {
                !level ?
                    <h6 className="unSelectedH6">Test seviyesini seçiniz... </h6>
                    :
                    <h6 className="unSelectedH6">Test Seviyesi: </h6>
              }

              {
                levels && levels.map((item,i) =>
                  <span key={i} style={{display: "inline-block"}} className="pr-1 mb-1">
                    <button type="button" className={"btn btn-" + (!level ? 'warning' : (level.id === item.id  ? 'primary' : 'secondary'))}
                            onClick={() => {setLevel(item); setOnay(false); setLesson(null); setQuestionCount(0); setTestTime(0); setTestName('');}}>
                      {item.name}
                    </button>
                  </span>
                )
              }
            </div>

            <div className="dialogContainer">
              {
                level && !lesson ?
                    <h6 className="unSelectedH6">Dersi seçiniz... </h6>
                    :
                    ( level &&
                    <h6 className="unSelectedH6">Ders: </h6>
                    )
              }
              {
                level && lessons && lessons.map((item,i) =>
                  <span key={i} style={{display: "inline-block"}} className="pr-1 mb-1">
                    <button type="button" className={"btn btn-" + (!lesson ? 'warning' : (lesson._id === item._id  ? 'primary' : 'secondary'))}
                            onClick={() => {setLesson(item); setOnay(false); setQuestionCount(0); setTestTime(0); setTestName('');}}>
                      {item.name}
                    </button>
                  </span>
                )
              }
            </div>
          </>
        }

        {
          !onay &&
          <>
            <div className="dialogContainer">
              {
                level && lesson &&
                <h6 className={"inputLeft unSelectedH6"}>Soru Sayısı: </h6>
              }
              {
                level && lesson &&
                <input type="text" value={questionCount} onChange={event => setQuestionCount(event.target.value)}/>
              }
            </div>

            <div className="dialogContainer">
              {
                level && lesson && questionCount > 0 &&
                <h6 className={"inputLeft unSelectedH6"}>Soru Süresi: </h6>
              }
              {
                level && lesson && questionCount > 0 &&
                <>
                  <input type="text" value={testTime} onChange={event => setTestTime(event.target.value)}/>
                  <span> Saniye</span>
                </>
              }
            </div>

            <div className="dialogContainer">
              {
                level && lesson && questionCount > 0 && testTime > 0 &&
                <>
                  <h6 className={"inputLeft unSelectedH6"}>Şık Sayısı: </h6>
                  <input type="text" value={optionCount} onChange={event => setOptionCount(event.target.value)}/>
                </>
              }
            </div>

            <div className="dialogContainer">
              {
                level && lesson && questionCount > 0 && testTime > 0 &&
                <>
                <h6 className={"inputLeft unSelectedH6"}>Test adı: </h6>
                  <input className="inputName" type="text" value={testName}
                         onChange={event => setTestName(event.target.value)}/>
                </>
              }
            </div>


          </>
        }

        {
          onay &&
            <>
              <div className="dialogContainer" style={{marginTop: 0}}>
                <h6 className="unSelectedH6"  style={{padding: 5, background: '#6993ff', color: '#fff', fontWeight: '600'}}>
                  <span className="detailList" >Test Detayları:</span>
                  {
                    level &&
                    <span className="detailList" >{level.name}</span>
                  }
                  {
                    level && lesson &&
                    <>
                      <span className="detailList">{'>'}</span>
                      <span className="detailList" >{lesson.name}</span>
                    </>
                  }
                  {
                    testName &&
                    <>
                      <span className="detailList">{'>'}</span>
                      <span className="detailList">{testName}</span>
                    </>
                  }
                </h6>
              </div>

              <div className="questionLists">
                <h6 className="unSelectedH6">Cevap anahtarı:</h6>
                {
                  questions.map((item, i) =>
                    <div key={item.num} className={"questionList " + (errorRow.indexOf(i) > -1 ? 'errorRow' : '')}>
                      <strong>{item.num}</strong>
                      <div className="secenekler">
                        <span className={item.correctAnswer === 'A' ? 'selectedOption' : ''} onClick={() => selectOption(i, "A")}>A</span>
                        <span className={item.correctAnswer === 'B' ? 'selectedOption' : ''} onClick={() => selectOption(i, "B")}>B</span>
                        <span className={item.correctAnswer === 'C' ? 'selectedOption' : ''} onClick={() => selectOption(i, "C")}>C</span>
                        <span className={item.correctAnswer === 'D' ? 'selectedOption' : ''} onClick={() => selectOption(i, "D")}>D</span>
                        <span className={optionCount === 5 ? (item.correctAnswer === 'E' ? 'selectedOption' : '') : 'optionE'} onClick={() => selectOption(i, "E")}>E</span>
                      </div>

                      <div className="duration">
                        <input type="text" value={item.expectedDuration} onChange={event => setQuestionTime(i, event.target.value)}/> sn
                      </div>

                      <div className="options">

                          <select value={item.subject ? item.subject : 'selected' } onChange={event => setSubject(i, event.target.value)}>
                            {
                              subjects &&
                              subjects.map(subject =>
                                  <option key={subject.id} value={subject.id}>{subject.name}</option>
                              )
                            }
                          </select>

                      </div>
                    </div>
                  )
                }
              </div>
          </>
        }

      </DialogContent>
      <DialogActions>
        <div className="dialogAction">

          {
            level && lesson && questionCount > 0 && testTime > 0 && testName !== '' && !onay &&
            <button type="button" className="btn btn-warning mr-2" onClick={() => setOnay(true)}> ŞIKLARI OLUŞTUR </button>
          }

          {
            onay &&
              <button type="button" className="btn btn-primary mr-2" onClick={save}>KAYDET</button>
          }

          <button type="button" className="btn btn-danger" onClick={testAddDelete}>İPTAL</button>
        </div>
      </DialogActions>
    </Dialog>

    <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={dialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
      <DialogTitle >KAYDEDİLDİ</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Testiniz kaydedildi.<br/>
          Bu testi ödev  olarak atayabilirsiniz.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="dialogAction">
          <button type="button" className="btn btn-primary" onClick={() => setOpenAlert(false)}>TAMAM</button>
        </div>
      </DialogActions>
    </Dialog>



    <Dialog
        open={edit}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEdit(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{testItem && testItem?.name}</DialogTitle>
      <DialogContent>
        {
          loading &&
          <div className="dialogLoading">
            <svg   width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <rect x="17.5" y="30" width="15" height="40" fill="#e15b64">
                <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="18;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="64;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
              </rect>
              <rect x="42.5" y="30" width="15" height="40" fill="#f8b26a">
                <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
              </rect>
              <rect x="67.5" y="30" width="15" height="40" fill="#abbd81">
                <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
              </rect>
            </svg>
          </div>
        }

        {
          editQuestions &&
          <>
            <div className="questionLists">
              <h6 className="unSelectedH6">Cevap anahtarı:</h6>
              {
                editQuestions.map((item, i) =>
                    <div key={i} className={"questionList " + (errorRow.indexOf(i) > -1 ? 'errorRow' : '')}>
                      <strong>{item.soruNumarasi}</strong>
                      <div className="secenekler">
                        <span className={item.dogruCevap === 'A' ? 'selectedOption' : ''} onClick={() => editSelectOption(i, "A")}>A</span>
                        <span className={item.dogruCevap === 'B' ? 'selectedOption' : ''} onClick={() => editSelectOption(i, "B")}>B</span>
                        <span className={item.dogruCevap === 'C' ? 'selectedOption' : ''} onClick={() => editSelectOption(i, "C")}>C</span>
                        <span className={item.dogruCevap === 'D' ? 'selectedOption' : ''} onClick={() => editSelectOption(i, "D")}>D</span>
                        <span className={item.sikSayisi === 5 ? (item.dogruCevap === 'E' ? 'selectedOption' : '') : 'optionE'} onClick={() => editSelectOption(i, "E")}>E</span>
                      </div>

                      <div className="duration">
                        <input type="text" value={item.soruSuresi} onChange={event => setEditQuestionTime(i, event.target.value)}/> sn
                      </div>

                      <div className="options">
                        <select value={ item.subject.id ? item.subject.id : 'selected' } onChange={ event => setEditSubject(i, event.target.value) }>
                          {
                            subjects &&
                            subjects.map(subject =>
                                <option key={subject.id} value={subject.id}>{subject.name}</option>
                            )
                          }
                        </select>
                      </div>
                    </div>
                )
              }
            </div>
          </>
        }
      </DialogContent>
      <DialogActions>
        <div className="dialogAction">

          {
            level && lesson && questionCount > 0 && testTime > 0 && testName !== '' && !onay &&
            <button type="button" className="btn btn-warning mr-2" onClick={() => setOnay(true)}> ŞIKLARI OLUŞTUR </button>
          }

          {
            editQuestions &&
            <button type="button" className="btn btn-primary mr-2" onClick={editing}>KAYDET</button>
          }

          <button type="button" className="btn btn-danger" onClick={() => setEdit(false)}>İPTAL</button>
        </div>
      </DialogActions>
    </Dialog>

  </>);
};
