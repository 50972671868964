import axios from "axios";

const URL = process.env.REACT_APP_API_URL;
export const ME_URL =  URL + "/me";

export function loginService(email, password) {
  return axios.post(URL + "/auth/local", { "identifier": email, "password": password });
}

export function registerService(data) {
  return axios.post(URL + '/auth/local/register', data);
}

export function registerTeacherService(username, userID, token) {
  const data = {
    name: username,
    isPublished: true,
    isDeleted: false,
    level: "5f8eaa9c0bbf5d2d17899f9f",
    publisher: "5f8eaa640bbf5d2d17899f9e",
    lessons: ["5f8eaae30bbf5d2d17899fa0"],
    seasons: ["5f8eaa2d0bbf5d2d17899f9d"],
    isExamPublication: false,
    created_by: userID
  }

  return axios.post(URL + '/publications', data,
  {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}

export function forgotPasswordService(email) {
  return axios.post(URL + '/users-permissions/forgot-password-url', { email });
}

export function renewPasswordService(email,code,password) {
  return axios.post(URL + '/users-permissions/reset-password', { email,code,password });
}

export function requestPassword(email) {
  return axios.post(URL + '/users-permissions/forgot-password', { email });
}

export function updateUserService(username, email, telephone, userId, token){
  return axios.put(URL + '/users' + '/' + userId,{ username, email, telephone},
  {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}

export function updateUserWithPasswordService(username, email, telephone, password, userId, token){
  return axios.put(URL + '/users' + '/' + userId,{ username, email, telephone, password }, {headers: {
    Authorization: "Bearer " + token
  }
  })
}

export function updateCompanyService(username, email, telephone, password, userId, token){
  return axios.put(URL + '/users' + '/' + userId,{ username,email,telephone,password},{headers: {
      Authorization: "Bearer " + token
    }
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
