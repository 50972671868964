import axios from "axios";

export const URL = process.env.REACT_APP_API_URL;
let user = localStorage.getItem('user');
user = JSON.parse(user);

export function getPubliserStatistics(publisherID, seasonID, token) {
    return axios.get(URL + "/publisher-statistics/" + publisherID + "/" +seasonID, {
        headers: {
            "Authorization": "Bearer "  + token
        }
    });
}

export function getTeacherStatistics(teacherid, token) {
    return axios.get(URL + "/teacher-statistics/" + teacherid, { 
        headers: {
            "Authorization": "Bearer " + token
        }
    });
}