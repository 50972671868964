import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import { useSubheader } from "../../../_metronic/layout";
import { DropdownButton, Dropdown, Spinner } from 'react-bootstrap';
import { useGeneral } from '../../../hooks/use-general';
import { useAuth } from '../../../hooks/use-auth';
import { Button, ButtonGroup, ListGroup, Form, Col, Row, Modal, Alert } from "react-bootstrap";
import { SheetJSFT } from '../../../partials/excelTypes';
import { setSubject, deleteSubject, updateSubject, setCurriculum } from './adminServices/konuServices';

export const Konu = () => {
    const classes = useStyles();
    const suhbeader = useSubheader();
    suhbeader.setTitle("Konular");

    const { seasons, levels, lessons, getAllCustomSubjects, customSubjects, isMiniLoading } = useGeneral();
    const { token } = useAuth();

    const [selectedSeason, setSelectedSeason] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [viewStatus, setViewStatus] = useState('list');
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState(false);
    const [title, setTitle] = useState(false);
    const [parentID, setParentID] = useState(null);
    const [subjectName, setSubjectName] = useState('');
    const [subjectList, setSubjectList] = useState([]);
    const [editableItem, setEditableItem] = useState({});
    const [alertShow, setAlertShow] = useState({type: 'danger', title: '', message: '', status: false});
    const [excelData, setExcelData] = useState([]);
    const [process, setProcess] = useState(false)
    const [isActive, setIsActive] = useState(true)

    useEffect(() => {
        const list = customSubjects.map(item => {
            item.status = false;
            item.title = item.name;
            if(item.parentSubjectId && customSubjects.findIndex(x => x.id === item.parentSubjectId) > -1) {
                item.title = customSubjects[customSubjects.findIndex(x => x.id === item.parentSubjectId)].name + ' > ' + item.name;
            }

            return item;
        });

        list.sort((a, b) => {
            return a.title.localeCompare(b.title);
        });
        setIsActive(true);
        setSubjectName('');
        setProcess(false);
        setSubjectList(list);

    }, [customSubjects])

    useEffect(() => {
        setIsActive(false);
    }, [selectedSeason, selectedLesson, selectedLevel]);

    const addExcel = () => {
        setAlertShow({type: 'danger', title: '', message: '', status: false});
        setSubjectName('');
        setParentID(null);

        if(!selectedSeason) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen sezonu seçiniz...'})
        }
        else if(!selectedLevel) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen seviyeyi seçiniz...'})
        }
        else if(!selectedLesson) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen dersi seçiniz...'})
        }
        else {
            setViewStatus('excel');
            getAllCustomSubjects(selectedLevel.id, selectedLesson.id, selectedSeason.id);
        }
    }

    const addSubject = () => {
        setAlertShow({type: 'danger', title: '', message: '', status: false});
        setSubjectName('');
        setParentID(null);

        if(!selectedSeason) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen sezonu seçiniz...'})
        }
        else if(!selectedLevel) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen seviyeyi seçiniz...'})
        }
        else if(!selectedLesson) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen dersi seçiniz...'})
        }
        else {
            setViewStatus('add');
        }
    }

    const getList = () => {
        setAlertShow({type: 'danger', title: '', message: '', status: false});

        if(!selectedSeason) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen sezonu seçiniz...'})
        }
        else if(!selectedLevel) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen seviyeyi seçiniz...'})
        }
        else if(!selectedLesson) {
            setAlert(true);
            setMessage({title: 'Dikkat', message: 'Lütfen dersi seçiniz...'})
        }
        else {
            setViewStatus('list');
            setTitle(selectedSeason?.name + ' > ' + selectedLevel?.name + ' > ' + selectedLesson?.name);
            getAllCustomSubjects(selectedLevel.id, selectedLesson.id, selectedSeason.id);
        }
    }

    const addSave = () => {
        if(control) { 
            const data = {
                season: selectedSeason.id,
                level: selectedLevel.id, 
                lesson: selectedLesson.id,
                name: subjectName,
                parentSubjectId: parentID
            }

            setSubject(token, data)
                .then(res => {
                    setSubjectName('');
                    setAlertShow({type: 'info', title: 'İşlem Tamam!', message: subjectName + ' isimli konu ' + selectedLesson.name + ' dersine kaydedilmiştir.', status: true});
                    getAllCustomSubjects(selectedLevel.id, selectedLesson.id, selectedSeason.id);
                })
                .catch(err => {
                    console.log(err)
                    setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: err, status: true});
                });
        }
    }

    const deleteItem = value => {
        console.log(value)
        if(value) {
            deleteSubject(token, value)
            .then(() => {
                setAlertShow({type: 'info', title: 'Konu silindi...', message: '', status: true});
                getAllCustomSubjects(selectedLevel.id, selectedLesson.id, selectedSeason.id);
            })
            .catch(err => {
                setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: err, status: true});
            });
        }
        else {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen silmek istediğiniz konuyu seçiniz.', status: true});
        }

    }
    
    const editItem = item => {
        const list = [...subjectList];
        const index = list.findIndex(x => x.id === item.id);
        list.map(x => x.status = false);
        list[index].status = true;
        setSubjectList(list);
        setSubjectName(item.name);
        setParentID(item.parentSubjectId)
        setEditableItem(item);
    }

    const update = () => {
        if(!selectedSeason) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen sezon seçiniz...', status: true});
        }
        else if(!selectedLevel) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen seviye seçiniz...', status: true});
        }
        else if(!selectedLesson) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen ders seçiniz...', status: true});
        }
        else if(!subjectName) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen konu adı giriniz...', status: true});
        }
        else if(subjectName.length < 3) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Konu adı en az 3 karakter olmalıdır...', status: true});
        }
        else {
            const data = {
                name: subjectName,
                parentSubjectId: parentID
            }

            setProcess(true);

            updateSubject(token, editableItem.id, data)
                .then(() => {
                    getAllCustomSubjects(selectedLevel.id, selectedLesson.id, selectedSeason.id);
                })
                .catch(err => {
                    setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: err, status: true});
                })
        }
    }

    const getParent = id => {
        const index = subjectList.findIndex(x => x.id === id);
        return subjectList[index]?.name;
    }

    const handleChange = event => {
        const files = event.target.files;
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
    
        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const xData = XLSX.utils.sheet_to_json(ws);

          const data = xData.map(x => {
              const data = {};
              data.status = false;
              data.name = x['konu adı'];
              if(x['üst konu adı']) {
                data.parentSubjectId = x['üst konu adı'];
              }
              
              return data;
          });
          
          setExcelData(data)
        }
        if (rABS) {
          reader.readAsBinaryString(files[0]);
        } else {
          reader.readAsArrayBuffer(files[0]);
        };

    }

    const deleteExcel = index => {
        const list = [...excelData];
        const item = list[index].name;
        list.splice(index, 1);
        const newList = [];
        list.map(x => {
            if(!x.parentSubjectId) {
                newList.push(x);
            }
            else if(x.parentSubjectId !== item){
                newList.push(x);
            }
            return x;
        });

        setExcelData(newList);
    }

    const editExcel = item => {
        const list = [...excelData];
        const index = list.findIndex(x => x.name === item.name);
        list.map(x => x.status = false);
        list[index].status = true;
        setExcelData(list);
        setSubjectName(item.name);
        setParentID(item.parentSubjectId)
    }

    const updateExcel = index => {
        const list = [...excelData];
        list.map(x => x.status = false);
        list[index].name = subjectName;
        list[index].parentSubjectId = parentID;
        setExcelData(list);
        setSubjectName('');
        setParentID(null)
    }

    const saveExcel = () => {
        if(control) {
            const list = [...excelData];
            const listParent = [];
            const listChild = [];

            list.map(x => {
                if(x.parentSubjectId) {
                    listChild.push({
                        name: x.name,
                        parentName: x.parentSubjectId
                    });
                }
                else {
                    listParent.push({
                        name: x.name
                    });
                }
            });

            const newList = [...listParent, ...listChild];

            const data = {
                season: selectedSeason.id,
                level: selectedLevel.id, 
                lesson: selectedLesson.id,
                subjects: newList
            }

            setCurriculum(token, data)
                .then(res => {
                    console.log(res)
                    setViewStatus('list');
                    setSubjectName('');
                    setAlertShow({type: 'info', title: 'İşlem Tamam!', message: subjectName + ' isimli konu ' + selectedLesson.name + ' dersine kaydedilmiştir.', status: true});
                    getAllCustomSubjects(selectedLevel.id, selectedLesson.id, selectedSeason.id);
                })
                .catch(err => {
                    console.log(err)
                    setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: err, status: true});
                });
        }
    }

    const control = () => {
        if(!selectedSeason) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen sezon seçiniz...', status: true});
            return false;
        }
        else if(!selectedLevel) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen seviye seçiniz...', status: true});
            return false;
        }
        else if(!selectedLesson) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen ders seçiniz...', status: true});
            return false;
        }
        else if(!subjectName) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Lütfen konu adı giriniz...', status: true});
            return false;
        }
        else if(subjectName.length < 3) {
            setAlertShow({type: 'danger', title: 'Lütfen Dikkat!', message: 'Konu adı en az 3 karakter olmalıdır...', status: true});
            return false;
        }
        else {
            return true;
        }
    }
 
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-custom gutter-b example example-compact">
                            <div className={classes.headerSelect}>
                                <div className={classes.inputList}>
                                    <div className={classes.grup}>
                                        <div className={classes.dropdown}>
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title="Sezon"
                                                >
                                                {
                                                    seasons &&
                                                    seasons.map(data => <Dropdown.Item key={data.id} href="#"  onClick={() => setSelectedSeason(data)}>{data?.name}</Dropdown.Item>)
                                                }
                                            </DropdownButton>
                                        </div>
                                            <div className={classes.input}>{selectedSeason?.name}</div>
                                    </div>
                                </div>
                                <div className={classes.inputList}>
                                    <div className={classes.grup}>
                                        <div className={classes.dropdown}>
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title="Seviye"
                                                >
                                                {
                                                    levels &&
                                                    levels.map(data =>  <Dropdown.Item key={data.id}  href="#"  onClick={() => setSelectedLevel(data)}>{data?.name}</Dropdown.Item>)
                                                }
                                            </DropdownButton>
                                        </div>
                                            <div className={classes.input}>{selectedLevel?.name}</div>
                                    </div>
                                </div>
                                <div className={classes.inputList}>
                                    <div className={classes.grup}>
                                        <div className={classes.dropdown}>
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title="Ders"
                                                >
                                                {
                                                    lessons &&
                                                    lessons.map(data =>  <Dropdown.Item key={data.id}  href="#"  onClick={() => setSelectedLesson(data)}>{data?.name}</Dropdown.Item>)
                                                }
                                            </DropdownButton>
                                        </div>
                                        <div className={classes.input}>{selectedLesson?.name}</div>
                                    </div>
                                </div>
                                <div className={classes.buttonContainer}>
                                    <ButtonGroup>
                                        <Button variant="primary" onClick={getList}>Listele</Button>
                                        <Button variant="primary" onClick={addSubject}>Ekle</Button>
                                        <Button variant="primary" onClick={addExcel}>Excel Ekle</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {
                viewStatus ==='excel' &&
                excelData.length === 0 &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="card-label">Excel Konu Ekle</h3>
                                        </div>
                                        <div className="card-toolbar">
                                            <div className="example-tools"/>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{paddingTop: 20}}>
                                        <label className="imageSelect" style={{alignItems: 'center', cursor: 'pointer'}}>
                                            <span className="svg-icon svg-icon-3x svg-icon-success">
                                                <svg viewBox="0 0 30 30">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <path d="M28.705,7.506l-5.461-6.333l-1.08-1.254H9.262   c-1.732,0-3.133,1.403-3.133,3.136V7.04h1.942L8.07,3.818c0.002-0.975,0.786-1.764,1.758-1.764l11.034-0.01v5.228   c0.002,1.947,1.575,3.523,3.524,3.523h3.819l-0.188,15.081c-0.003,0.97-0.79,1.753-1.759,1.761l-16.57-0.008   c-0.887,0-1.601-0.87-1.605-1.942v-1.277H6.138v1.904c0,1.912,1.282,3.468,2.856,3.468l17.831-0.004   c1.732,0,3.137-1.41,3.137-3.139V8.966L28.705,7.506" fill="#434440"/>
                                                        <path d="M20.223,25.382H0V6.068h20.223V25.382 M1.943,23.438h16.333V8.012H1.943" fill="#08743B"/>
                                                        <polyline fill="#08743B" points="15.73,20.822 12.325,20.822 10.001,17.538 7.561,20.822 4.14,20.822 8.384,15.486 4.957,10.817    8.412,10.817 10.016,13.355 11.726,10.817 15.242,10.817 11.649,15.486 15.73,20.822  "/>
                                                    </g>
                                                </svg>
                                            </span>
                                            <span style={{color: '#1BC5BD', marginLeft: 10}}>Dosya Seç</span>
                                            <input className={classes.inputExcel} type="file"  accept={SheetJSFT} onChange={handleChange}/>
                                        </label>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

            {
                viewStatus ==='excel' &&
                excelData.length > 0 &&
           
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="card-label">Excel Konu Listesi</h3>
                                        </div>
                                        <div className="card-toolbar">
                                            <div className="example-tools">
                                                <Button variant="primary" onClick={saveExcel}>Kaydet</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body listSubject">
                                        {
                                            alertShow.status &&
                                                <Alert variant={alertShow.type} onClose={() => setAlertShow({type: 'danger', title: '', message: '', status: false})} dismissible>
                                                    <Alert.Heading>{alertShow.title}</Alert.Heading>
                                                    <p style={{marginBottom: 0}}>{alertShow.message}</p>
                                                </Alert>
                                        }

                                        {
                                            <ListGroup variant="flush">
                                                {
                                                    excelData.map((item, index) =>
                                                        <ListGroup.Item key={index}>
                                                            <div className={classes.flexJust}>
                                                                <div style={{width: 'calc(100% - 110px)'}}>
                                                                    {
                                                                        !item.status &&
                                                                            <span>
                                                                                {item.parentSubjectId && item.parentSubjectId + ' > '}{item.name} 
                                                                            </span>
                                                                    }

                                                                    {
                                                                        item.status &&
                                                                            <Form>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Form.Group controlId="exampleForm.ControlInput1">
                                                                                            <Form.Label>Konu Adı</Form.Label>
                                                                                            <Form.Control onChange={event => { setSubjectName(event.target.value); setAlertShow({type: 'danger', title: '', message: '', status: false})}} value={subjectName}  type="text" placeholder="Konu adını giriniz" />
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                                                            <Form.Label>Konu Üst Başlık</Form.Label>
                                                                                            <Form.Control as="select" onChange={event => setParentID(event.target.value)}>
                                                                                                {
                                                                                                    parentID &&
                                                                                                        <option value={parentID}>{parentID}</option>
                                                                                                }
                                                                                                {
                                                                                                    !parentID &&
                                                                                                        <option value="">Yok</option>
                                                                                                }
                                                                                                {
                                                                                                    excelData &&
                                                                                                    excelData.map((item, index) => !item.parentSubjectId && item.name !== parentID && <option key={index} value={item.name}>{item.name}</option>)
                                                                                                }
                                                                                            </Form.Control>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Form>
                                                                    }
                                                                    
                                                                </div>
                                                                <span className={classes.svgButtonContainer}>
                                                                    {
                                                                        !item.status &&
                                                                            <span className={classes.svgButtonWarning + " svg-icon svg-icon-light svg-icon-x"} onClick={() => editExcel(item)}>
                                                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                        <rect x="0" y="0" width="24" height="24"/>
                                                                                        <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                                                                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                    }

                                                                    {
                                                                        item.status &&
                                                                            <span className={classes.svgButtonSuccess + " svg-icon svg-icon-light svg-icon-x"} onClick={() => updateExcel(index)}>
                                                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                        <polygon points="0 0 24 0 24 24 0 24"/>
                                                                                        <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fillRule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "/>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                    }

                                                                    <span className={classes.svgButtonDanger + " svg-icon svg-icon-light svg-icon-x"} onClick={() => deleteExcel(index)}>
                                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                                <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fillRule="nonzero"/>
                                                                                <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </ListGroup.Item>
                                                    )
                                                }
                                            </ListGroup>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

            {
                viewStatus ==='add' &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="card-label">Konu Ekle</h3>
                                        </div>
                                        <div className="card-toolbar">
                                            <div className="example-tools">
                                                <Button variant="primary" onClick={addSave}>Kaydet</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{paddingTop: 20}}>
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label>Konu Üst Başlık</Form.Label>
                                                        <Form.Control as="select" onChange={event => setParentID(event.target.value)}>
                                                            <option value="">Yok</option>
                                                            {
                                                                subjectList &&
                                                                subjectList.map(item => !item.parentSubjectId && <option key={item.id} value={item.id}>{item.name}</option>)
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Konu Adı</Form.Label>
                                                        <Form.Control onChange={event => { setSubjectName(event.target.value); setAlertShow({type: 'danger', title: '', message: '', status: false})}} value={subjectName}  type="text" placeholder="Konu adını giriniz" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                        {
                                            alertShow.status &&
                                                <Alert variant={alertShow.type} onClose={() => setAlertShow({type: 'danger', title: '', message: '', status: false})} dismissible>
                                                    <Alert.Heading>{alertShow.title}</Alert.Heading>
                                                    <p style={{marginBottom: 0}}>{alertShow.message}</p>
                                                </Alert>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

            {
                viewStatus ==='list' &&
                title &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="card-label">{title}</h3>
                                        </div>
                                        <div className="card-toolbar">
                                            <div className="example-tools"/>
                                        </div>
                                    </div>
                                    <div className="card-body listSubject">
                                        {
                                            isMiniLoading && <div>Yükleniyor</div>
                                        }
                                        
                                        {
                                            alertShow.status &&
                                                <Alert variant={alertShow.type} onClose={() => setAlertShow({type: 'danger', title: '', message: '', status: false})} dismissible>
                                                    <Alert.Heading>{alertShow.title}</Alert.Heading>
                                                    <p style={{marginBottom: 0}}>{alertShow.message}</p>
                                                </Alert>
                                        }

                                        {
                                            subjectList.length === 0 &&
                                            !isMiniLoading &&
                                            isActive &&
                                                <div><b>{ selectedSeason.name }</b> sezonunda <b>{ selectedLevel.name }</b> seviyesinde <b>{selectedLesson.name}</b> dersine ait kayıtlı konu bulunmamaktadır.</div>
                                        }

                                        {
                                            subjectList.length > 0 &&
                                                <ListGroup variant="flush">
                                                    {
                                                        subjectList.map(item =>
                                                            <ListGroup.Item key={item.id}>
                                                                <div className={classes.flexJust}>
                                                                    <div style={{width: 'calc(100% - 110px)'}}>
                                                                        {
                                                                            !item.status &&
                                                                                <span> {item.title} </span>
                                                                        }
                                                                        
                                                                        {
                                                                            item.status &&
                                                                                <Form>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                                                                <Form.Label>Konu Üst Başlık</Form.Label>
                                                                                                <Form.Control as="select" onChange={event => setParentID(event.target.value)}>
                                                                                                    {
                                                                                                        parentID &&
                                                                                                            <option value={parentID}>{getParent(parentID)}</option>
                                                                                                    }
                                                                                                    {
                                                                                                        !parentID &&
                                                                                                            <option value="">Yok</option>
                                                                                                    }
                                                                                                    {
                                                                                                        subjectList &&
                                                                                                        subjectList.map(item => !item.parentSubjectId && <option key={item.id} value={item.id}>{item.name}</option>)
                                                                                                    }
                                                                                                </Form.Control>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                                                                <Form.Label>Konu Adı</Form.Label>
                                                                                                <Form.Control onChange={event => { setSubjectName(event.target.value); setAlertShow({type: 'danger', title: '', message: '', status: false})}} value={subjectName}  type="text" placeholder="Konu adını giriniz" />
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                        }
                                                                        
                                                                    </div>
                                                                    <span className={classes.svgButtonContainer}>
                                                                        {
                                                                            !item.status &&
                                                                                <span className={classes.svgButtonWarning + " svg-icon svg-icon-light svg-icon-x"} onClick={() => editItem(item)}>
                                                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                            <rect x="0" y="0" width="24" height="24"/>
                                                                                            <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                                                                            <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>
                                                                        }

                                                                        {
                                                                            item.status &&
                                                                            process &&
                                                                            <span className={classes.svgButtonSuccess + " svg-icon svg-icon-light svg-icon-x processButton"} onClick={() => update()}>
                                                                                <Spinner animation="border" variant="light" size="sm"/>
                                                                            </span>
                                                                        }

                                                                        {
                                                                            item.status &&
                                                                            !process &&
                                                                                <span className={classes.svgButtonSuccess + " svg-icon svg-icon-light svg-icon-x"} onClick={() => update()}>
                                                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                            <polygon points="0 0 24 0 24 24 0 24"/>
                                                                                            <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fillRule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "/>
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>
                                                                        }

                                                                        <span className={classes.svgButtonDanger + " svg-icon svg-icon-light svg-icon-x"} onClick={() => deleteItem(item.id)}>
                                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                    <rect x="0" y="0" width="24" height="24"/>
                                                                                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fillRule="nonzero"/>
                                                                                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </ListGroup.Item>
                                                        )
                                                    }
                                                </ListGroup>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        
            <Modal show={alert} onHide={() => setAlert(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{message?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message?.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAlert(false)}>Tamam</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}




const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "#fff"
    },
    container: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "#fff",
        marginTop: 30,
        padding: 30
    },
    headerSelect: {
        position: 'relative',
        display: 'flex',
        padding: 20
    },
    inputList: {
        display: 'flex',
        flex: 1,
        margin: 10,
        flexDirection: 'column'
    },
    buttonContainer: {
        display: 'flex',
        margin: 10,
        overflow: 'hidden'
    },
    label: {
        marginBottom: 5
    },
    grup: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,
        border: '1px solid #ddd'
    },
    input: {
        width: '100%',
        padding: '8px 8px 0 8px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        borderRadius: 5
    },
    dropdown: {
        margin: -1
    },
    imageSelect: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    inputExcel:{
        display: 'none'
    },
    flexJust: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    svgButtonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    svgButtonSuccess: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 7,
        paddingRight: 7,
        background: '#28a745',
        marginRight: 3,
        marginLeft: 3,
        borderRadius: 5,
        cursor: 'pointer'
    },
    svgButtonWarning: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 7,
        paddingRight: 7,
        background: '#ffa800',
        marginRight: 3,
        marginLeft: 3,
        borderRadius: 5,
        cursor: 'pointer'
    },
    svgButtonDanger: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 7,
        paddingRight: 7,
        background: '#f64e60',
        marginRight: 3,
        marginLeft: 3,
        borderRadius: 5,
        cursor: 'pointer'
    }
}));