import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import { Alert, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';

import { useSubheader } from "../../../_metronic/layout";
import {getSeasons} from "../admin/adminServices/sezonServices"
import {setSeasonService} from "../admin/adminServices/sezonServices"
import {deleteSeasonService} from "../admin/adminServices/sezonServices"

export const Sezon = () => {
    const suhbeader = useSubheader();
    const token = localStorage.getItem('token');
    suhbeader.setTitle("Sezon Listeleme Ve Ekleme Ekranı");
    const classes = useStyles();
    
    const [season, setSeason] = useState([]);
    const [open, setOpen] = useState(false);
    const [seasonName, setSeasonName] = useState('');
    const [show, setShow] = useState(false);
    const [showSameSeasonError, setSameSeasonArray] = useState(false);
    const [openSilOnay, setOpenSilOnay] = useState(false);
    const [silinecekSezonId, setSilinecekSezonId] = useState('');

    function handleClickOpenSilmeOnay(sezonId) {
        setSilinecekSezonId(sezonId);
        setOpenSilOnay(true);
    }

    function handleCloseSilmeOnayAccept() {
        deleteSeason(token, silinecekSezonId);
        setOpenSilOnay(false);
    }

    function handleCloseSilmeOnayReject() {
        setSilinecekSezonId('')
        setOpenSilOnay(false);
    }

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const validateSeasonName = (seasonName) => {
        const regex = /^(19|20)[0-9]{2}-(19|2[0-9])[0-9]{2}$/;
        return regex.test(seasonName);
    }

    const handleSubmitSeason = (seasonName) => {
        if (validateSeasonName(seasonName)) {
            setSeasonService(token, seasonName).then(response => {
                handleClose();
                getAllSeasons();
            }).catch((error) => {
                console.log('error: ' + JSON.stringify(error))
                setSameSeasonArray(true);
                setSeasonName('')
            })
        } else {
            setShow(true);
            handleClose();
            getAllSeasons();
        }

    }
    const deleteSeason = (token, seasonId) => {
        deleteSeasonService(token, seasonId).then(() =>
            getAllSeasons()
        );
    }

    const handleDismiss = () => {
        setShow(false);
        setSameSeasonArray(false);
    }
    const handleShow = () => {
        setShow(true);
    }

    const getAllSeasons = () => {
        getSeasons(token).then(res => {
            let data = res.data;
            data.map(item => {
                let seasonName = item.name.split("-");
                item.name1 = seasonName[0];
                item.name2 = seasonName[1];
            })
            setSeason(res.data);
        }).catch(error => console.log('error', error));
    }
    useEffect(() => {
        getAllSeasons();
    }, [])

    return (<>
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Sezon tarihleri</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Sezon bilgisi örnekteki gibi ekleyebilirsiniz : 2020-2021
                    </DialogContentText>
                    <TextField
                        onChange={(event) => setSeasonName(event.target.value)}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Sezon"
                        type="text"
                        fullWidth
                        value={seasonName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Çıkış
                    </Button>
                    <Button onClick={() => handleSubmitSeason(seasonName)} color="primary">
                        Ekle
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        <div className="card card-custom gutter-b">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">{"Sezonlar"}</h3>
                </div>
                <div className="card-toolbar" onClick={handleClickOpen}><a
                    className="btn btn-success font-weight-bolder font-size-sm">Sezon Ekle</a>
                </div>
            </div>
            <div className="card-body" style={cardTextStyle}>
                <div className="row">

                    <div className='col-md-12'>
                        {show && <Alert variant="success" onClose={handleDismiss} dismissible>
                            <Alert.Heading>Hata!</Alert.Heading>
                            <p>
                                Eksik veya hatalı sezon girdiniz.Sezonu 2020-2021 seklindeki gibi giriniz.
                            </p>
                        </Alert>
                        }
                    </div>

                    <div className='col-md-12'>
                        {showSameSeasonError && <Alert variant="success" onClose={handleDismiss} dismissible>
                            <Alert.Heading>Hata!</Alert.Heading>
                            <p>
                                Girmiş olduğunuz sezon sistemde mevuttur.
                            </p>
                        </Alert>
                        }
                    </div>

                    {
                        season.map((item, i) =>
                        <div className="col-md-4" key={i}>
                            <div className="d-flex align-items-center mb-9 bg-light-success rounded p-5">
                                <div className="d-flex flex-column flex-grow-1 mr-2">
                                    {item.name1 === "Karışık" ? (
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <span style={cardTextStyle}
                                              className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">{item.name1}
                                        </span>
                                                <DeleteIcon style={{color: "rgb(14 123 118)", fontSize: "2.0rem"}}
                                                            onClick={() => handleClickOpenSilmeOnay(item.id)}
                                                            className={classes.icon}/>
                                            </div>
                                        ) :
                                        (
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <span style={cardTextStyle}
                                                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">{item.name1}-{item.name2}</span>
                                                <DeleteIcon style={{color: "rgb(14 123 118)", fontSize: "2.0rem"}}
                                                            onClick={() => handleClickOpenSilmeOnay(item.id)}
                                                            className={classes.icon}/>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

        <Dialog
            open={openSilOnay}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Silme Onay"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Seçili sezonu silmek istediğinize emin misiniz ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSilmeOnayReject} color="primary">
                    İptal
                </Button>
                <Button onClick={handleCloseSilmeOnayAccept} color="primary" autoFocus>
                    Evet
                </Button>
            </DialogActions>
        </Dialog>

    </>);
};

const cardTextStyle = {
    cursor: "pointer"
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    buttonDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    sendInfoButton: {
        width: '100px',
        height: '40px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));
