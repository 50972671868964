/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../_metronic/layout";

export function DashboardWidget1({ report, className, chartColor = "danger" }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  useEffect(() => {
    const element = document.getElementById("kt_tiles_widget_1_chart");

    if (!element) {
      return;
    }

    if(report) {
        const options = getChartOption(layoutProps, report?.haftaCozen, report?.gunler);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
    }

  }, [layoutProps, report]);

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-header border-0 pt-5" >
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">Haftalık Genel Durum</div>
              <div className="font-size-sm text-muted mt-2">{report?.etkilesimSayisi} Soru Çözüldü</div>
            </div>
          </div>
          <div className="card-toolbar"></div>
        </div>
        
        <div className="card-body d-flex flex-column px-0">
          <div id="kt_tiles_widget_1_chart" data-color={chartColor} style={{ height: "150px" }}/>
          
          <div className="flex-grow-1 card-spacer-x" style={{marginTop: 20}}>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <div className="d-flex align-items-center mr-2">
                <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                  <div className="symbol-label">
                    <span className="svg-icon">
                      <SVG className=" h-50" src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}/>
                    </span>
                  </div>
                </div>
                <div>
                  <span className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Bugün</span>
                  <div className="font-size-sm text-muted font-weight-bold mt-1">Gün içinde soru çözenler</div>
                </div>
              </div>
              <div className="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">
                {report?.bugunKacKisiCozdu}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-10">
              <div className="d-flex align-items-center mr-2">
                <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                  <div className="symbol-label">
                    <span className="svg-icon">
                      <SVG className=" h-50" src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}/>
                    </span>
                  </div>
                </div>
                <div>
                  <span className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Bu Ay</span>
                  <div className="font-size-sm text-muted font-weight-bold mt-1">Ay içinde soru çözenler</div>
                </div>
              </div>
              <div className="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">
              {report?.buAyKacKisiCozdu}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center mr-2">
                <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                  <div className="symbol-label">
                    <span className="svg-icon">
                      <SVG className=" h-50" src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}/>
                    </span>
                  </div>
                </div>
                <div>
                  <span className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Şimdiye Kadar</span>
                  <div className="font-size-sm text-muted font-weight-bold mt-1">Toplam soru çözenler</div>
                </div>
              </div>
              <div className="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">
                {report?.kullaniciSayisi}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function getChartOption(layoutProps, data, gunler) {
  const options = {
    series: [
      {
        name: "Soru Çözen",
        data: data,
      },
    ],
    chart: {
      type: "area",
      height: 150,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.55,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.2,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseColor],
    },
    xaxis: {
      categories: gunler,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 37,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return val + " kişi";
        },
      },
    },
    colors: [layoutProps.colorsThemeLightColor],
    markers: {
      colors: [layoutProps.colorsThemeLightColor],
      strokeColor: [layoutProps.colorsThemeBaseColor],
      strokeWidth: 3,
    },
    padding: {
      top: 0,
      bottom: 0,
    },
  };
  return options;
}
