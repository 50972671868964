import React, { useEffect } from "react";
import { usePublish } from "../../../hooks/use-publish";
import { getLevelsService } from "../../../services/publish";
import { useSeason } from "../../../hooks/use-season";
import { useAuth } from "../../../hooks/use-auth";
import { useGeneral } from "../../../hooks/use-general";

export const Levels = () => {
  const { setLoading, setLevelID } = useGeneral();
  const { publisherID } = useAuth();
  const { seasonID } = useSeason();
  const { setLevelList, levelList, view, setView } = usePublish();

  useEffect(() => {
    if(seasonID) {
      setLoading(true);
      getLevelsService(seasonID, publisherID).then(response => {
        if(response.data) {
          setLevelList(response.data);
        }
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
    }
  }, [seasonID, publisherID]);

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Yayın Grupları</h3>
          </div>
          <div className="card-toolbar">
            <div className="headerToolbar">
              <span className="headerButton" onClick={() => setView(view => !view)}>
                {
                  view ?
                    <><i className="fa fa-eye"></i><span>Tümü</span></>
                  :
                    <><i className="fa fa-eye-slash"></i><span>Sadece Yayınlar</span></>
                }
              </span>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="publishContainer">
            {
              levelList && levelList.map(item => 
                view ?
                <div key={item._id} className="col-md-2 publish" onClick={() => setLevelID(item._id)}>
                  <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7 publisher">
                    <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                          <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                        </g>
                      </svg>
                    </span>
                    <div className="text-primary font-weight-bold font-size-h4 mt-2">{item.name}</div>
                    <div className="text-primary font-weight-bold font-size-h6 mt-2" style={{opacity: .6}}><span>{item.publicationCount} </span>Yayın</div>
                  </div>
                </div>
                : item.publicationCount > 0 && 
                <div key={item._id} className="col-md-2 publish" onClick={() => setLevelID(item._id)}>
                  <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7 publisher">
                    <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                          <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                        </g>
                      </svg>
                    </span>
                    <div className="text-primary font-weight-bold font-size-h4 mt-2">{item.name}</div>
                    <div className="text-primary font-weight-bold font-size-h6 mt-2" style={{opacity: .6}}><span>{item.publicationCount} </span>Yayın</div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}
