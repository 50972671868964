import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

export function getLevels(token) {
    return axios.get(URL + '/levels', {headers: {Authorization: "Bearer " + token}});
}

export function setSeviyeService(token, seviye) {
    return axios.post(URL + '/levels', {
        name: seviye
    }, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

export function deleteSeviyeService(token, seviyeId) {
    return axios.delete(URL + '/levels/' + seviyeId, {headers: {Authorization: "Bearer " + token}})
}
