import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Button, Spinner } from "react-bootstrap";
import * as Yup from "yup";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SVG from "react-inlinesvg";

import { getPublisher, getPublishers, setUserService, setPublisherService } from "../admin/adminServices/yayinevlleriService"
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const Yayinevleri = () => {
    const suhbeader = useSubheader();
    const token = localStorage.getItem('token');
    suhbeader.setTitle("Yayınevleri");

    const [publishers, setPublishers] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [publisherID, setPublisherID] = useState('');
    const [userID, setUserID] = useState('');
    const [name, setName] = useState('');
    const [errorList, setErrorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const RegistrationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Lütfen yayınevi adını giriniz.")
            .min(3, "Yayınevi adı en az 3 karakter olmalıdır.")
            .max(50, "Yayınevi adı en fazla 50 karakter olmalıdır."),
        username: Yup.string()
            .required("Lütfen admin için kullanıcı adını giriniz.")
            .min(3, "Kullanıcı adı en az 3 karakter olmalıdır.")
            .max(50, "Kullanıcı adı en fazla 50 karakter olmalıdır."),
        email: Yup.string()
            .required("Lütfen admin için eposta adresi giriniz.")
            .email("Eposta formatı geçerli değil."),
        password: Yup.string()
            .required("Lütfen admin için şifre giriniz.")
            .min(6, "Şifre en az 6 karakter olmalıdır.")
            .max(18, "Şifre en fazla 18 karakter olmalıdır."),
    });
    
    const savePublisher = async () => {
        RegistrationSchema.validate({ name, username, email, password }, { abortEarly: false })
            .then(() => {
                setLoading(true);
                setUserService(username, email, password)
                    .then(response => {
                        setPublisherService(response.data.user.id, name, token)
                            .then(() => {
                                getPublishers();
                                setUsername('');
                                setName('');
                                setEmail('');
                                setPassword('');
                                setPublisherID('');
                            })
                            .catch((error) => {
                                console.log('error: ', error);
                            });
                    })
                    .catch((error) => {
                        console.log('error: ', error)
                    
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            })
            .catch(async err => {
                console.log(err)
                setErrorList(err.errors)
            });
    }

    const editPublisher = publisher => {
        console.log(publisher)
        getPublisher(token, publisher.id)
            .then(res => { 
                setUsername(res?.data?.admin[0]?.username);
                setName(res?.data?.name);
                setEmail(res?.data?.admin[0]?.email);
                setPassword('');
                setPublisherID(publisher.id);
                setUserID(res?.data?.admin[0]?.id);
                handleClickOpen();
            })
            .catch(error => console.log('error', error));
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    } 

    const getPublisherList = () => {
        getPublishers(token)
            .then(res => { 
                setPublishers(res.data);
                handleClose();
            })
            .catch(error => console.log('error', error));
    }

    const routeChange = item =>{ 
      history.push({
        pathname: '/yayinevidetay',
        data: item,
      });
    }

    useEffect(() => {
        getPublisherList();
    }, [])

    return (<>
        <div className="card card-custom gutter-b">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">Yayınevleri</h3>
                </div>
                <div className="card-toolbar" onClick={handleClickOpen}><a
                    className="btn btn-success font-weight-bolder font-size-sm">Yayınevi Ekle</a>
                </div>
            </div>

            <div className="card-body" style={cardTextStyle}>
                <div className="row">
                    <div className="card-body py-0">
                        <div className="table-responsive">
                            <table className="table table-head-custom table-vertical-center">
                                <thead>
                                    <tr className="text-left">
                                        <th className="pr-0">YAYINEVİ ADI</th>
                                        <th className="pr-0 text-right" style={{ minWidth: "150px" }}>İŞLEMLER</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    publishers.length > 0 && publishers.map(item =>
                                        <tr key={item.id}>

                                            <td className="pl-0">
                                                <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg" onClick={() => routeChange(item)}>{item.name}</span>
                                            </td>
                                            <td className="pr-0 text-right">
                                                <span className="btn btn-icon btn-light btn-hover-primary btn-sm" onClick={() => routeChange(item)}>
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}/>
                                                    </span>
                                                </span>
                                                <span className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3" onClick={() => editPublisher(item)}>
                                                    <span className="svg-icon svg-icon-md svg-icon-warning">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
                                                    </span>
                                                </span>
                                                <span className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                                                    </span>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Yayınevi Ekle</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            errorList.length > 0 &&
                            <span className="fade alert alert-danger show" style={{display: 'flex'}}>{ errorList[0]  }</span>
                        }
                        
                        {
                            errorList.length === 0 && "Yayınevi bilgilerini giriniz."
                        }
                    </DialogContentText>
                    <TextField onChange={(event) => setName(event.target.value)} autoFocus margin="dense" label="Yayınevi Adı" type="text" fullWidth value={name}/>
                    <TextField onChange={(event) => setUsername(event.target.value)} label="Admin Adı" type="text" fullWidth value={username}/>
                    <TextField onChange={(event) => setEmail(event.target.value)} label="Admin e-Posta" type="text" fullWidth value={email}/>
                    <TextField onChange={(event) => setPassword(event.target.value)} label="Admin Şifre" type="text" fullWidth value={password}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color="primary">İptal</Button>
                    <Button onClick={() => savePublisher()} color="primary" disabled={loading}> 
                        {
                            loading &&
                            <>
                                <Spinner animation="border" role="status" size="sm"/>
                                <span style={{width: 20, display: 'inline-block'}}/>
                            </>
                        }
                        Ekle
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </>);
};

const cardTextStyle = {
    cursor: "pointer"
};

