/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo} from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import { useAuth } from "../../../../../hooks/use-auth";
import PaymentIcon from '@material-ui/icons/Payment';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export function UserProfileDropdown() {
    const { logout, user, publisherID, teacherID } = useAuth();
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
        };
    }, [uiService]);

    return (
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile" >
                <div
                    className={"btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"}>
                    <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Merhaba,</span>
                    <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">{user?.username}</span>
                    <span className="symbol symbol-35">
                        <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{user?.username[0].toUpperCase()}</span>
                    </span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
                {
                    layoutProps.light && 
                    <>
                        <div className="d-flex align-items-center p-8 rounded-top">
                            <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                                    <span className="symbol symbol-35">
                                <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{user?.username[0].toUpperCase()}</span>
                            </span>
                            </div>
                            <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user?.username}</div>
                        </div>
                        <div className="separator separator-solid"></div>
                    </>
                }

                <div className="navi navi-spacer-x-0 pt-5">
                    <span className="navi-item px-8">
                        <div className="navi-link">
                            <div className="navi-icon mr-2"><i className="flaticon2-calendar-3 text-success"/></div>
                            <div className="navi-text">
                                <Link to="/profile" className="font-weight-bold"> Profil Bilgileri </Link>
                                <div className="text-muted"> Profil ve Şifre Ayarları </div>
                            </div>
                        </div>
                    </span>

                    {
                        publisherID &&

                        <span className="navi-item px-8">
                            <div className="navi-link">
                                <div className="navi-icon mr-2"><InfoOutlinedIcon style={{color: '#FFA800'}}></InfoOutlinedIcon></div>
                                <div className="navi-text">
                                    <Link to="/company" className="font-weight-bold">Firma Bilgileri</Link>
                                    <div className="text-muted"> Firma Bilgileri Detay Ayarları
                                    </div>
                                </div>
                            </div>
                        </span>
                    }

                    {
                        publisherID && false &&
                        <span className="navi-item px-8">
                            <div className="navi-link">
                                <div className="navi-icon mr-2"> <LocalAtmOutlinedIcon style={{color: '#f64e60'}}/> </div>
                                <div className="navi-text">
                                    <Link to="/payments" className="font-weight-bold"> Ödeme Bilgileri </Link>
                                    <div className="text-muted"> Cari hesap bilgileri </div>
                                </div>
                            </div>
                        </span>
                    }

                    {
                        publisherID && false &&
                        <>
                            <span className="navi-item px-8">
                                <div className="navi-link">
                                    <div className="navi-icon mr-2"><PaymentIcon style={{color: '#6993FF'}}/></div>
                                    <div className="navi-text">
                                        <div className="font-weight-bold"> Ödeme Yap </div>
                                        <div className="text-muted"> Ödeme yapmak için tıklayın. </div>
                                    </div>
                                </div>
                            </span>
                            <div className="navi-separator mt-3"></div>
                        </>
                    }

                    <div className="navi-footer  px-8 py-5">
                        <div onClick={logout} className="btn btn-light-primary font-weight-bold">
                            Çıkış
                        </div>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
