import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Button, Spinner } from "react-bootstrap";
import * as Yup from "yup";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SVG from "react-inlinesvg";

import { getPublisher, getPublishers, setUserService, setPublisherService } from "../admin/adminServices/yayinevlleriService"
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const YayinDetay = () => {
    const suhbeader = useSubheader();
    const token = localStorage.getItem('token');
    suhbeader.setTitle("Yayınevleri");

    const [publishers, setPublishers] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [publisherID, setPublisherID] = useState('');
    const [userID, setUserID] = useState('');
    const [name, setName] = useState('');
    const [errorList, setErrorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const RegistrationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Lütfen yayınevi adını giriniz.")
            .min(3, "Yayınevi adı en az 3 karakter olmalıdır.")
            .max(50, "Yayınevi adı en fazla 50 karakter olmalıdır."),
        username: Yup.string()
            .required("Lütfen admin için kullanıcı adını giriniz.")
            .min(3, "Kullanıcı adı en az 3 karakter olmalıdır.")
            .max(50, "Kullanıcı adı en fazla 50 karakter olmalıdır."),
        email: Yup.string()
            .required("Lütfen admin için eposta adresi giriniz.")
            .email("Eposta formatı geçerli değil."),
        password: Yup.string()
            .required("Lütfen admin için şifre giriniz.")
            .min(6, "Şifre en az 6 karakter olmalıdır.")
            .max(18, "Şifre en fazla 18 karakter olmalıdır."),
    });
    
    const savePublisher = async () => {
        RegistrationSchema.validate({ name, username, email, password }, { abortEarly: false })
            .then(() => {
                setLoading(true);
                setUserService(username, email, password)
                    .then(response => {
                        setPublisherService(response.data.user.id, name, token)
                            .then(() => {
                                getPublishers();
                                setUsername('');
                                setName('');
                                setEmail('');
                                setPassword('');
                                setPublisherID('');
                            })
                            .catch((error) => {
                                console.log('error: ', error);
                            });
                    })
                    .catch((error) => {
                        console.log('error: ', error)
                    
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            })
            .catch(async err => {
                console.log(err)
                setErrorList(err.errors)
            });
    }

    const editPublisher = publisher => {
        console.log(publisher)
        getPublisher(token, publisher.id)
            .then(res => { 
                setUsername(res?.data?.admin[0]?.username);
                setName(res?.data?.name);
                setEmail(res?.data?.admin[0]?.email);
                setPassword('');
                setPublisherID(publisher.id);
                setUserID(res?.data?.admin[0]?.id);
                handleClickOpen();
            })
            .catch(error => console.log('error', error));
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    } 

    const getPublisherList = () => {
        getPublishers(token)
            .then(res => { 
                setPublishers(res.data);
                handleClose();
            })
            .catch(error => console.log('error', error));
    }

    const routeChange = item =>{ 
      history.push({
        pathname: '/yayinevidetay',
        data: item,
      });
    }

    useEffect(() => {
        getPublisherList();
    }, [])

    return (<>
        <div className="card card-custom gutter-b">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">Yayınevleri</h3>
                </div>
                <div className="card-toolbar" onClick={handleClickOpen}><a
                    className="btn btn-success font-weight-bolder font-size-sm">Yayınevi Ekle</a>
                </div>
            </div>

            <div className="card-body" style={cardTextStyle}>
                <div className="row">
                    <div className="col-md-12">
        
        


                    </div>
                </div>
            </div>
        </div>
    </>);
};

const cardTextStyle = {
    cursor: "pointer"
};

