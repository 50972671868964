import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

export function getPublisher(token, id) {
    return axios.get(URL + '/publishers/' + id, {headers: {Authorization: "Bearer " + token}});
}

export function getPublishers(token) {
    return axios.get(URL + '/publishers', {headers: {Authorization: "Bearer " + token}});
}

export function setPublisherService(userID, name, token) {
    return axios.post(URL + '/publishers',
        {
            name,
            admin: userID
        },
        {
            headers: {
                Authorization: "Bearer " + token
            }
        })
}

export function setUserService(username, email, password) {
    return axios.post(URL + '/auth/local/register', {
        username,
        email,
        password,
        role: '607d5ffcb8a3e03dfcefddeb'
    })
}

export function getPublications(token, id) {
    return axios.get(URL + '/publications?publisher=' + id, {headers: {Authorization: "Bearer " + token}});
}


export function deleteSeasonService(token, seasonId){
    return axios.delete(URL + '/seasons/' + seasonId, {headers: {Authorization: "Bearer " + token}})
}
