import React, {useEffect, useState} from "react";
import {useSubheader} from "../../_metronic/layout";
import {Notice, KTCodeExample} from "../../_metronic/_partials/controls";
import TextField from '@material-ui/core/TextField';
import {Badge, Jumbatron, Table, ButtonToolbar, Button} from 'react-bootstrap';
import {useAuth} from "../../hooks/use-auth";
import {updateCompanyService} from "../../services/auth";

import {
    fade,
    withStyles,
    makeStyles,
    createMuiTheme
} from "@material-ui/core/styles";

export const Company = () => {
    const [isForm, setIsForm] = useState(false);

    useEffect(() => {
        console.log('Company.js sayfasına gelindi');
    }, [])

    const changeIsFormStateStatus = () => {
        setIsForm(!isForm);
        console.log('status: ' + isForm);
    }

    const handleSubmit = (username, email, telephone, password
    ?
) =>
    {
        updateCompanyService(username, email, telephone, password, "asdasd", localStorage.getItem('token')).then(response => {
            console.log('update sonrası: ' + JSON.stringify(response));
            changeIsFormStateStatus();
        })
    }

    const suhbeader = useSubheader();
    suhbeader.setTitle("Firma Bilgileri");
    const classes = useStyles();

    return (<>
        <div className="card card-custom gutter-b">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">{isForm ? "Firma Bilgileri Güncelleme" : "Firma Bilgilerim"}</h3>
                </div>
            </div>
            <div className="card-body">
                {
                    isForm && <form noValidate autoComplete="off">
                        <TextField
                            required="true"
                            type="text"
                            id="outlined-name"
                            label="Firma Adı"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"/>
                        <TextField
                            required="true"
                            type="number"
                            id="outlined-name"
                            label="Firma Telefon Numarası"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"/>
                        <TextField
                            required="true"
                            type="text"
                            id="outlined-name"
                            label="Firma Adresi"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"/>
                        <TextField
                            required="true"
                            type="email"
                            id="outlined-name"
                            label="Vergi Numarası"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"/>
                        <TextField
                            required="true"
                            type="password"
                            id="outlined-name"
                            label="Vergi Dairesi"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"/>
                        <TextField
                            required="true"
                            type="text"
                            id="outlined-name"
                            label="İl"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"/>
                        <TextField
                            required="true"
                            type="Text"
                            id="outlined-name"
                            label="İlçe"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"/>
                        <div className={classes.buttonDiv}>
                            <Button variant="primary" className={classes.button}>
                                Kaydet
                            </Button>
                        </div>
                    </form>
                }

                {
                    !isForm && <div>
                        <Table striped bordered hover size="sm">
                            <thead>
                            <tr>
                                <th>Firma Adı</th>
                                <th>Telefon</th>
                                <th>Firma Adresi</th>
                                <th>Vergi No</th>
                                <th>Vergi Dairesi</th>
                                <th>İl</th>
                                <th>İlçe</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>dsfasdaf</td>
                                <td>sdafsadfsdaf</td>
                                <td>sdfsadfsda</td>
                                <td>sdfsadfsda</td>
                                <td>sdfsadfsda</td>
                                <td>sdfsadfsda</td>
                                <td>sdfsadfsda</td>
                            </tr>
                            </tbody>
                        </Table>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button onClick={() => changeIsFormStateStatus()} variant="primary">Güncelle</Button>
                        </div>
                    </div>
                }
            </div>
        </div>

    </>);
};


const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    buttonDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    sendInfoButton: {
        width: '100px',
        height: '40px'
    }
}));
