import React, {Suspense, lazy, useEffect, useState} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import {Profile} from "./pages/Profile";
import {Publish} from "./pages/Publish";
import {Company} from "./pages/Company";
import {Payments} from "./pages/Payments";
import {Tests} from "./pages/Tests";
import {SeasonContext} from "../hooks/use-season";
import {useAuth} from "../hooks/use-auth";
import {getSeasonService} from "../services/publish";
import {Homework} from "./pages/Homework";
import {Seasons} from "./pages/admin/Seasons"
import {Konu} from "./pages/admin/Konu"
import {Sezon} from "./pages/admin/Sezon"
import {Seviye} from "./pages/admin/Seviye"
import {Ders} from "./pages/admin/Ders"
import { Yayinevleri } from "./pages/admin/Yayinevleri";
import { YayineviDetay } from "./pages/admin/YayineviDetay";
import { YayinDetay } from "./pages/admin/YayinDetay";
import { YayinEkle } from "./pages/admin/YayinEkle";

const GoogleMaterialPage = lazy(() =>
    import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
    import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
    import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
    const [seasonID, setSeasonID] = useState(null);
    const [season, setSeason] = useState(null);
    const [seasonList, setSeasonList] = useState(null);
    const {token, publisherID} = useAuth();

    useEffect(() => {
        if (token) {
            getSeasonService(token).then(response => {
                if (response.data) {
                    setSeasonList(response.data);
                    setSeasonID(response.data[0].id)
                    setSeason(response.data[0])
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }, [token]);

    const changeSeason = (value) => {
        setSeasonID(value.id)
        setSeason(value)
    }

    return (
        <SeasonContext.Provider value={{seasonID, setSeasonID, seasonList}}>
            {
                publisherID &&
                <div className="seasonContainer">
                    <div className="seasonRow">
                        <Dropdown size="sm" variant="secondary" alignRight >
                            <Dropdown.Toggle variant="success" id="dropdown-basic"> {season && season.name} </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    seasonList &&
                                    seasonList.map((item, index) =>
                                        <Dropdown.Item key={index} eventKey={index} onClick={() => changeSeason(item)}>{item.name}</Dropdown.Item>
                                    )
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            }

            <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                    {
                         <Redirect exact from="/" to="/dashboard"/>
                    }
                    {
                        /*<Redirect exact from="/" to="/yayinevleri"/>*/
                    }
                    <ContentRoute path="/yayinekle" component={YayinEkle}/>
                    <ContentRoute path="/yayinevleri" component={Yayinevleri}/>
                    <ContentRoute path="/yayinevidetay" component={YayineviDetay}/>
                    <ContentRoute path="/yayindetay" component={YayinDetay}/>
                    <ContentRoute path="/payments" component={Payments}/>
                    <ContentRoute path="/seasons" component={Seasons}/>
                    <ContentRoute path="/dashboard" component={DashboardPage}/>
                    <ContentRoute path="/konu" component={Konu}/>
                    <ContentRoute path="/sezon" component={Sezon}/>
                    <ContentRoute path="/seviye" component={Seviye}/>
                    <ContentRoute path="/ders" component={Ders}/>
                    <ContentRoute path="/profile" component={Profile}/>
                    <ContentRoute path="/Publish" component={Publish}/>
                    <ContentRoute path="/Tests" component={Tests}/>
                    <ContentRoute path="/homework" component={Homework}/>
                    <ContentRoute path="/company" component={Company}/>
                    <ContentRoute path="/builder" component={BuilderPage}/>
                    <ContentRoute path="/my-page" component={MyPage}/>
                    <Route path="/google-material" component={GoogleMaterialPage}/>
                    <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                    <Route path="/e-commerce" component={ECommercePage}/>
                    <Redirect to="error/error-v1"/>
                </Switch>
            </Suspense>
        </SeasonContext.Provider>
    );
}
