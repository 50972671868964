import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {registerService, registerTeacherService} from "../../../../services/auth";
import {useAuth} from "../../../../hooks/use-auth";

const initialValues = {
  fullname: "",
  email: "",
  username: "",
  userRole: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const { login } = useAuth();
  const { intl } = props;
  const [ loading, setLoading ] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    userRole: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
    .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      
      let roleID = '';

      if(values.userRole === 'teacher') {
        roleID = "5f8998710242b313c50a5eec";
      }
      else if(values.userRole === 'publisher') {
        roleID = "5f37c5f658c4c7e9a02cef72";
      }

      registerService({
          username: values.fullname,
          email: values.email,
          password: values.password,
          confirmed: true,
          blocked: false,
          role: roleID,
          gender: 'Unspecified',
          city: 'Unspecified',
          birthYear: '',
          consent: true,
          consentTime: new Date(),
          notificationToken: '',
          name: values.fullname,

        })
        .then(response => {
          login(response.data.jwt, response.data.user);
          
          if(values.userRole === 'teacher') {
            registerTeacherService(response.data.user.name, response.data.user.id, response.data.jwt).then().catch(error => console.log(error))
          }
          
          disableLoading();
        })
        .catch(error => {
          const message = error.response.data.message[0].messages[0].message;
          let alertMessage = '';

          setSubmitting(false);

          if(message === 'Username is already taken.') {
            alertMessage = 'Kullanıcı adı daha önce kullanılmıştır.'
          }
          else if(message === 'Email is already taken.') {
            alertMessage = 'Bu mail adresi sistemde kayıtlıdır'
          }

          if(alertMessage) {
            setStatus(alertMessage);
          }
          else {
            setStatus(message);
          }
          
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Kayıt Ol</h3>
        <p className="text-muted font-weight-bold">
          Hesabınızı oluşturmak için aşağıdaki formu doldurun.
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}>
        
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input placeholder="Adınızı ve soyadınızı girin" type="text" name="fullname"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("fullname")}`}
            {...formik.getFieldProps("fullname")} />
          {formik.touched.fullname && formik.errors.fullname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.fullname}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input placeholder="E-Posta adresinizi girin" type="email" name="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
            {...formik.getFieldProps("email")}/>
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        
        <div className="form-group fv-plugins-icon-container dubleButtonContainer">
          <label className="dubleButton">
            <input type="radio" name="userRole" {...formik.getFieldProps("userRole")}  value="publisher"/>
            <span  className="form-control form-control-solid h-auto py-5 px-6">Yayınevi</span>
          </label>

          <label className="dubleButton">
            <input type="radio" name="userRole" {...formik.getFieldProps("userRole")} value="teacher"/>
            <span  className="form-control form-control-solid h-auto py-5 px-6">Öğretmen</span>
          </label>

          {formik.touched.userRole && formik.errors.userRole ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.userRole}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input placeholder="Şifrenizin giriniz" type="password" name="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
            {...formik.getFieldProps("password")}/>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input placeholder="Şifrenizin tekrar giriniz" type="password" name="changepassword"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("changepassword")}`}
            {...formik.getFieldProps("changepassword")} />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block"> {formik.errors.changepassword} </div>
            </div>
          ) : null}
        </div>

        <div className="form-group">
          <label className="checkbox">
            <input type="checkbox" name="acceptTerms" className="m-1"
              {...formik.getFieldProps("acceptTerms")} />
            <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">Gizlilik sözleşmesini okudum, onaylıyorum </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">


        <Link to="/auth/login">
            <button type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Geri Dön</button>
          </Link>


          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.values.acceptTerms}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Kayıt Ol</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
