import React from "react";
import { useLocation} from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useAuth } from "../../../../../hooks/use-auth";

export function HeaderMenu({layoutProps}) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }
    
    const { user } = useAuth();

    return (
        <div id="kt_header_menu" className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`} {...layoutProps.headerMenuAttributes}>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {
                    user?.role?.name === "Publisher" && false &&
                    <>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                            <NavLink className="menu-link" to="/dashboard"> <span className="menu-text">Ana Sayfa</span> </NavLink>
                        </li>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/yayinlar')}`}>
                            <NavLink className="menu-link" to="/publish"><span className="menu-text">Yayınlar</span></NavLink>
                        </li>

                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/seasons')}`}>
                            <NavLink className="menu-link" to="/seasons"> <span className="menu-text">Sezonlar</span> </NavLink>
                        </li>
                    </>
                }

                {
                    user?.role?.name === "Admin" &&
                    <>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/konu')}`}>
                            <NavLink className="menu-link" to="/konu"> <span className="menu-text">Konular</span> </NavLink>
                        </li>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/sezon')}`}>
                            <NavLink className="menu-link" to="/sezon"> <span className="menu-text">Sezonlar</span> </NavLink>
                        </li>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/seviye')}`}>
                            <NavLink className="menu-link" to="/seviye"> <span className="menu-text">Seviyeler</span> </NavLink>
                        </li>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/ders')}`}>
                            <NavLink className="menu-link" to="/ders"> <span className="menu-text">Dersler</span> </NavLink>
                        </li>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/yayinevleri')}`}>
                            <NavLink className="menu-link" to="/yayinevleri"> <span className="menu-text">Yayınevleri</span> </NavLink>
                        </li>
                    </>
                }

                {
                    user?.role?.name === "Teacher" && false &&
                    <>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/tests')}`}>
                            <NavLink className="menu-link" to="/tests"> <span className="menu-text">Testler</span> </NavLink>
                        </li>
                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/homework')}`}>
                            <NavLink className="menu-link" to="/homework"> <span className="menu-text">Ödevler</span> </NavLink>
                        </li>
                    </>
            }
        </ul>
    </div>
    )
}
