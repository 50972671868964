import axios from "axios";


const URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = URL + "auth/login";
export const REGISTER_URL =  URL + "/auth/register";
export const REQUEST_PASSWORD_URL =  URL + "auth/forgot-password";

export const ME_URL =  URL + "me";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(data) {
  return axios.post(REGISTER_URL, data);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
