import React, { useEffect, useState } from "react";
import { usePublish } from "../../../hooks/use-publish";
import { getTestReportService } from "../../../services/publish";
import { useGeneral } from "../../../hooks/use-general";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const Report = () => {
    const { testID, testName, subjectName } = usePublish();
    const { setLoading } = useGeneral();
    const [reports, setReports] = useState(null)

    useEffect(() => {
      setReports(null);
      setLoading(true);
      getTestReportService(testID).then(response => {
        if(response.data) {
          response.data.sorular.map(item => {
            item.totalCount = item.correctCount + item.incorrectCount + item.skippedCount;
            item.percentA = item.A * 100 / item.totalCount;
            item.percentB = item.B * 100 / item.totalCount;
            item.percentC = item.C * 100 / item.totalCount;
            item.percentD = item.D * 100 / item.totalCount;
            if(item.E) {
              item.percentE = item.E * 100 / item.totalCount;
            }
            return item
          });

          setReports(response.data);
          setLoading(false);
        } 
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
    }, [testID])

    return (<>
    <div className={`card card-custom `}>
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">{testName}</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">{subjectName}</span>
        </h3>
        <div className="card-toolbar">
          <div className="card card-custom bg-warning gutter-b mr-5" style={{ height: 150 }} >
            <div className="card-body">
              <span className="svg-icon svg-icon-3x svg-icon-white">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Timer.svg")}/>
              </span>
              <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3"> {reports?.kisiSayisi} </div>
              <span className="font-weight-bold font-size-lg mt-1" style={{color: '#dedede'}}>Ortalama Hız</span>
            </div>
          </div>
          <div className="card card-custom bg-danger gutter-b mr-5" style={{ height: 150 }} >
            <div className="card-body">
              <span className="svg-icon svg-icon-3x svg-icon-white">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Cooking/Dish.svg")}/>
              </span>
              <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3"> {reports?.kisiSayisi} </div>
              <span className="font-weight-bold font-size-lg mt-1" style={{color: '#dedede'}}>Ortalama Net</span>
            </div>
          </div>
          <div className="card card-custom bg-primary gutter-b" style={{ height: 150 }} >
            <div className="card-body">
              <span className="svg-icon svg-icon-3x svg-icon-white">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
              </span>
              <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3"> {reports?.kisiSayisi} </div>
              <span className="font-weight-bold font-size-lg mt-1" style={{color: '#dedede'}}>Çözüm Sayısı</span>
            </div>
          </div>

        </div>
      </div>

      <div className="card-body py-0">
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr className="text-left">
                <th className="pl-0"> No </th>
                <th className="pl-0" style={{ width: 90 }}> KİŞİ </th>
                <th className="pl-0">Doğru | Yanlış | Boş</th>
                <th className="pl-0">Süre</th>
                <th className="pl-0">Şıklar</th>
              </tr>
            </thead>

            <tbody>
              {
                reports?.sorular &&
                reports.sorular.map((item, i) => 
                    
                <tr key={i}>
                  <td className="pl-0">
                    <div className="soruContainer">
                      <strong className="soruCevap">{item.number}</strong>
                    </div>
                  </td>

                  <td className="pl-0">
                    <div className="personContainer">
                      <span className="soruKisi">{item.totalCount}</span>
                      <span className="kisiText">Kişi</span>
                    </div>
                  </td>

                  <td className="pl-0">
                    <div className="cevapContainer">
                      <div className="cevapKolon">
                        <label className="svg-icon svg-icon-4x svg-icon-success">
                          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" fill="none" fillRule="evenodd">
                              <path fill="#4d4d4d" opacity="0.4" d="m9.43863,1.00945c-0.27964,0.00627 -0.55794,0.02539 -0.83336,0.05752c-3.30505,0.38575 -6.25417,2.59625 -7.4533,5.89913c-1.59883,4.40385 0.43288,9.31633 4.67137,11.29776c4.23849,1.98145 9.29959,0.37967 11.63711,-3.67926a0.73588,0.73588 0 1 0 -1.27312,-0.73132c-1.96375,3.40994 -6.18474,4.7425 -9.74281,3.07915c-3.55809,-1.66337 -5.25271,-5.76465 -3.90927,-9.46501c1.34342,-3.70034 5.26905,-5.74744 9.06089,-4.72887a0.73693,0.73693 0 0 0 0.38226,-1.42296c-0.84706,-0.22753 -1.70084,-0.32559 -2.53977,-0.30612l0,-0.00002z"/>
                              <path fill="#4d4d4d" d="m6.06904,8.29052l1.41974,-1.42024l3.88936,3.88886l6.48344,-6.48143l1.42024,1.41924l-7.90368,7.90268"/>
                            </g>
                          </svg>
                        </label>
                        <strong> {item.correctCount} </strong>
                        <span>Doğru</span>
                      </div>
                      <div className="cevapKolon">
                        <label className="svg-icon svg-icon-4x svg-icon-danger">
                          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" >
                              <path fill="#4d4d4d" opacity="0.4" d="m9.43863,1.00945c-0.27964,0.00627 -0.55794,0.02539 -0.83336,0.05752c-3.30505,0.38575 -6.25417,2.59625 -7.4533,5.89913c-1.59883,4.40385 0.43288,9.31633 4.67137,11.29776c4.23849,1.98145 9.29959,0.37967 11.63711,-3.67926a0.73588,0.73588 0 1 0 -1.27312,-0.73132c-1.96375,3.40994 -6.18474,4.7425 -9.74281,3.07915c-3.55809,-1.66337 -5.25271,-5.76465 -3.90927,-9.46501c1.34342,-3.70034 5.26905,-5.74744 9.06089,-4.72887a0.73693,0.73693 0 0 0 0.38226,-1.42296c-0.84706,-0.22753 -1.70084,-0.32559 -2.53977,-0.30612l0,-0.00002z"/>
                              <path d="m15.95491,12.87958l-3.31537,-3.31537l3.31477,-3.31537l-1.70408,-1.70529l-3.31537,3.31537l-3.31537,-3.31537l-1.70408,1.70529l3.31477,3.31537l-3.31537,3.31537l1.70529,1.70469l3.31477,-3.31537l3.31477,3.31537" fill="#4d4d4d"/>
                            </g>
                          </svg>
                        </label>
                        <strong> {item.incorrectCount} </strong>
                        <span>Yanlış</span>
                      </div>
                      <div className="cevapKolon">
                        <label className="svg-icon svg-icon-4x">
                          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" >
                              <path fill="#4d4d4d" opacity="0.4" d="m9.43863,1.00945c-0.27964,0.00627 -0.55794,0.02539 -0.83336,0.05752c-3.30505,0.38575 -6.25417,2.59625 -7.4533,5.89913c-1.59883,4.40385 0.43288,9.31633 4.67137,11.29776c4.23849,1.98145 9.29959,0.37967 11.63711,-3.67926a0.73588,0.73588 0 1 0 -1.27312,-0.73132c-1.96375,3.40994 -6.18474,4.7425 -9.74281,3.07915c-3.55809,-1.66337 -5.25271,-5.76465 -3.90927,-9.46501c1.34342,-3.70034 5.26905,-5.74744 9.06089,-4.72887a0.73693,0.73693 0 0 0 0.38226,-1.42296c-0.84706,-0.22753 -1.70084,-0.32559 -2.53977,-0.30612l0,-0.00002z"/>
                              <path d="m6.69889,8.36189l10.28107,0l0,1.88754l-10.28107,0l0,-1.88754z" fill="#4d4d4d"/>
                            </g>
                          </svg>
                        </label>
                        <strong> {item.skippedCount} </strong>
                        <span>Boş</span>
                      </div>
                    </div>
                  </td>
                  
                  <td className="pl-0">
                    <div className="sureContainer">
                      <div className="sureKolon">
                        <label className="svg-icon svg-icon-3x">
                          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" >
                              <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z" fill="#000000" opacity="0.3"/>
                              <path d="M13,5.06189375 C12.6724058,5.02104333 12.3386603,5 12,5 C11.6613397,5 11.3275942,5.02104333 11,5.06189375 L11,4 L10,4 C9.44771525,4 9,3.55228475 9,3 C9,2.44771525 9.44771525,2 10,2 L14,2 C14.5522847,2 15,2.44771525 15,3 C15,3.55228475 14.5522847,4 14,4 L13,4 L13,5.06189375 Z" fill="#000000"/>
                              <path d="M16.7099142,6.53272645 L17.5355339,5.70710678 C17.9260582,5.31658249 18.5592232,5.31658249 18.9497475,5.70710678 C19.3402718,6.09763107 19.3402718,6.73079605 18.9497475,7.12132034 L18.1671361,7.90393167 C17.7407802,7.38854954 17.251061,6.92750259 16.7099142,6.53272645 Z" fill="#000000"/>
                              <path d="M11.9630156,7.5 L12.0369844,7.5 C12.2982526,7.5 12.5154733,7.70115317 12.5355117,7.96165175 L12.9585886,13.4616518 C12.9797677,13.7369807 12.7737386,13.9773481 12.4984096,13.9985272 C12.4856504,13.9995087 12.4728582,14 12.4600614,14 L11.5399386,14 C11.2637963,14 11.0399386,13.7761424 11.0399386,13.5 C11.0399386,13.4872031 11.0404299,13.4744109 11.0414114,13.4616518 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000"/>
                            </g>
                          </svg>
                        </label>
                        <strong>{item.shortestTime}<span>sn</span></strong>
                        <span>En Hızlı</span>
                      </div>
                      <div className="sureKolon">
                        <label className="svg-icon svg-icon-3x">
                          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" >
                              <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z" fill="#000000" opacity="0.3"/>
                              <path d="M13,5.06189375 C12.6724058,5.02104333 12.3386603,5 12,5 C11.6613397,5 11.3275942,5.02104333 11,5.06189375 L11,4 L10,4 C9.44771525,4 9,3.55228475 9,3 C9,2.44771525 9.44771525,2 10,2 L14,2 C14.5522847,2 15,2.44771525 15,3 C15,3.55228475 14.5522847,4 14,4 L13,4 L13,5.06189375 Z" fill="#000000"/>
                              <path d="M16.7099142,6.53272645 L17.5355339,5.70710678 C17.9260582,5.31658249 18.5592232,5.31658249 18.9497475,5.70710678 C19.3402718,6.09763107 19.3402718,6.73079605 18.9497475,7.12132034 L18.1671361,7.90393167 C17.7407802,7.38854954 17.251061,6.92750259 16.7099142,6.53272645 Z" fill="#000000"/>
                              <path d="M11.9630156,7.5 L12.0369844,7.5 C12.2982526,7.5 12.5154733,7.70115317 12.5355117,7.96165175 L12.9585886,13.4616518 C12.9797677,13.7369807 12.7737386,13.9773481 12.4984096,13.9985272 C12.4856504,13.9995087 12.4728582,14 12.4600614,14 L11.5399386,14 C11.2637963,14 11.0399386,13.7761424 11.0399386,13.5 C11.0399386,13.4872031 11.0404299,13.4744109 11.0414114,13.4616518 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000"/>
                            </g>
                          </svg>
                        </label>
                        <strong>{item.longestTime}<span>sn</span></strong>
                        <span>En Yavaş</span>
                      </div>
                      <div className="sureKolon">
                        <label className="svg-icon svg-icon-3x">
                          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" >
                              <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z" fill="#000000" opacity="0.3"/>
                              <path d="M13,5.06189375 C12.6724058,5.02104333 12.3386603,5 12,5 C11.6613397,5 11.3275942,5.02104333 11,5.06189375 L11,4 L10,4 C9.44771525,4 9,3.55228475 9,3 C9,2.44771525 9.44771525,2 10,2 L14,2 C14.5522847,2 15,2.44771525 15,3 C15,3.55228475 14.5522847,4 14,4 L13,4 L13,5.06189375 Z" fill="#000000"/>
                              <path d="M16.7099142,6.53272645 L17.5355339,5.70710678 C17.9260582,5.31658249 18.5592232,5.31658249 18.9497475,5.70710678 C19.3402718,6.09763107 19.3402718,6.73079605 18.9497475,7.12132034 L18.1671361,7.90393167 C17.7407802,7.38854954 17.251061,6.92750259 16.7099142,6.53272645 Z" fill="#000000"/>
                              <path d="M11.9630156,7.5 L12.0369844,7.5 C12.2982526,7.5 12.5154733,7.70115317 12.5355117,7.96165175 L12.9585886,13.4616518 C12.9797677,13.7369807 12.7737386,13.9773481 12.4984096,13.9985272 C12.4856504,13.9995087 12.4728582,14 12.4600614,14 L11.5399386,14 C11.2637963,14 11.0399386,13.7761424 11.0399386,13.5 C11.0399386,13.4872031 11.0404299,13.4744109 11.0414114,13.4616518 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000"/>
                            </g>
                          </svg>
                        </label>
                        <strong>{item.averageTime}<span>sn</span></strong>
                        <span>Ortalama</span>
                      </div>
                    </div>
                  </td>
                
                  <td className="pl-0">
                    <div className="siklarContainer">
                      <div className="siklarRow">
                        <div className="siklarCevap">
                          <strong>A</strong>
                          <span>%{item.percentA}</span>
                        </div>
                        <div className="siklarProgressbar">
                          <span className={item.correctAnswer === 'A' ? 'siklarProgress dogru' : 'siklarProgress yanlis'} style={{width: item.percentA + '%'}}></span>
                        </div>
                        <div className="kisiSayisi">{item.A} Kişi</div>
                      </div>
                      <div className="siklarRow">
                        <div className="siklarCevap">
                          <strong>B</strong>
                          <span>%{item.percentB}</span>
                        </div>
                        <div className="siklarProgressbar">
                          <span className={item.correctAnswer === 'B' ? 'siklarProgress dogru' : 'siklarProgress yanlis'} style={{width: item.percentB + '%'}}></span>
                        </div>
                        <div className="kisiSayisi">{item.B} Kişi</div>
                      </div>
                      <div className="siklarRow">
                        <div className="siklarCevap">
                          <strong>C</strong>
                          <span>%{item.percentC}</span>
                        </div>
                        <div className="siklarProgressbar">
                        <span className={item.correctAnswer === 'C' ? 'siklarProgress dogru' : 'siklarProgress yanlis'} style={{width: item.percentC + '%'}}></span>
                        </div>
                        <div className="kisiSayisi">{item.C} Kişi</div>
                      </div>
                      <div className="siklarRow">
                        <div className="siklarCevap">
                          <strong>D</strong>
                          <span>%{item.percentD}</span>
                        </div>
                        <div className="siklarProgressbar">
                        <span className={item.correctAnswer === 'D' ? 'siklarProgress dogru' : 'siklarProgress yanlis'} style={{width: item.percentD + '%'}}></span>
                        </div>
                        <div className="kisiSayisi">{item.D} Kişi</div>
                      </div>
                        <div className="siklarRow">
                          <div className="siklarCevap">
                            <strong>E</strong>
                            <span>%{item.percentE}</span>
                          </div>
                          <div className="siklarProgressbar">
                            <span className={item.correctAnswer === 'E' ? 'siklarProgress dogru' : 'siklarProgress yanlis'} style={{width: item.percentE + '%'}}></span>
                          </div>
                          <div className="kisiSayisi">{item.E} Kişi</div>
                        </div>
                    </div>
                  </td>
                </tr>
                )
              }
          </tbody>
          </table>
        </div>
      </div>
    </div>
    </>);
}
