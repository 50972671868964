import axios from "axios";

export const URL = process.env.REACT_APP_API_URL;
let user = localStorage.getItem('user');
user = JSON.parse(user);

export function getLevelLessonSubjectsService(levelID, lessonID) {
    return axios.get(URL + "/level-lesson-subjects/"+ levelID + "/"+ lessonID + "/5f37c439982114cd70b9e023", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getLevelLessonsService(levelID) {
    return axios.get(URL + "/level-lessons?levels=" + levelID + "&seasons=5f37c439982114cd70b9e023", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getTeacherTestsService() {
    return axios.get(URL + '/teacherTests/' + user.id, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getLevelsLeanService() {
    return axios.get(URL + "/levels-lean", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getTeacherTest(testID) {
    return axios.get(URL + '/teacher-test/' + testID, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function postHomeworkTestService(data) {
    return axios.post(URL + "/homework-test",
        data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function updateHomeworkTestService(id, data) {
    return axios.post(URL + "/homework-test",
        data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}
