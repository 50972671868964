import axios from "axios";

export const URL = process.env.REACT_APP_API_URL;
let user = localStorage.getItem('user');
user = JSON.parse(user);

export function getSeasonService(tkn) {
    return axios.get(URL + "/seasons", { 
        headers: {
            "Authorization": "Bearer " + tkn
        }
    });
}

export function getLevelsService(seasonID, publisherID) {
    return axios.get(URL + "/publisher-levels/" + publisherID + '?seasons=' + seasonID, { 
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getLessonsService(levelID, seasonID, publisherID) {
    return axios.get(URL + "/publisher-lessons/" + publisherID + "/" + levelID + '?seasons=' + seasonID, { 
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getPublicationsService(levelID, lessonID, seasonID, publisherID) {
    return axios.get(URL + "/publications?publisher=" + publisherID + "&level=" + levelID + "&lesson=" + lessonID + "&season=" + seasonID, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getSubjectsService(levelID, lessonID, publisherID) {
    return axios.get(URL + "/subjects?publisher=" + publisherID + "&level=" + levelID + "&lesson=" + lessonID, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function postPublicationsService(data) {
    return axios.post(URL + "/publications", data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
}

export function updatePublicationsService(data, id) {
    return axios.put(URL + "/publications/" + id, data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
        });
}

export function getSubjectListService(publicationID) {
    return axios.get(URL + "/send-tests/" + publicationID, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getSubjectService(levelID, lessonID, seasonID) {
    return axios.get(URL + "/level-lesson-subjects/" + levelID + "/" + lessonID + "/" + seasonID, { 
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getPublicationSubjectsService(publicationID,) {
    return axios.get(URL + "/send-tests/" + publicationID, { 
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getLevelLessonsService(levelID) {
    return axios.get(URL + "/level-lessons?levels=" + levelID, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function getStatisticsOfPublicationService(publicationID) {
    return axios.get(URL + "/statistics-of-publication/" + publicationID, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function postTestService(data, publisherID) {
    data.publisherID = publisherID;
    return axios.post(URL + "/create-tests",
        data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function postExamService(data, publisherID) {
    data.publisherID = publisherID;
    return axios.post(URL + "/create-exams",
        data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function postTestAdminService(data) {
    return axios.post(URL + "/create-tests",
        data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function getTestReportService(testID) {
    return axios.get(URL + "/question-statistics/" + testID, { 
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
}

export function updatePublicationActiveService(data) {
    console.log(data)
    return axios.put(URL + "/publications/" + data._id,
        {isPublished: data.isPublished},
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function deletePublicationService(data) {
    return axios.delete(URL + "/publications/" + data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function updatePublicationTestService(data) {
    return axios.post(URL + "/update-tests", data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function getLearningRecordCountPublicationService(data) {
    return axios.get(URL + "/learning-records/count?publication=" + data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function deleteTestsOfPublicationService(data) {
    return axios.get(URL + "/delete-all/" + data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function deleteQuestionsOfPublicationService(data) {
    return axios.get(URL + "/delete-all-questions/" + data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}

export function deletePublicationSubjectsOfPublicationService(data) {
    return axios.get(URL + "/delete-publication-subjects/" + data,
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        });
}
