import React, {useEffect, useState} from "react";
import { DashboardWidget1 } from "./components/DashboardWidget1";
import { useAuth } from "../../hooks/use-auth";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { usePublish } from "../../hooks/use-publish";
import { useGeneral } from "../../hooks/use-general";
import { useSeason } from "../../hooks/use-season";
import { getPubliserStatistics, getTeacherStatistics } from "../../services/dashboard";
import { getLevelLessonsService } from "../../services/publish";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import XLSX from "xlsx";
import { SheetJSFT } from "../../partials/excelTypes";
import {FormControl, InputLabel, Select} from "@material-ui/core";
const dayList = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
const dayListText = ['Pazar', 'Pazartesi', 'Sali', 'Carsamba', 'Persembe', 'Cuma', 'Cumartesi'];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function DashboardPage(props) {
    const { token, publisherID, teacherID} = useAuth();
    const { setLoading, setExcelData, levels, setExamPublication, levelID, lessonID, setLevelID, setLessonID, setISBN, setPublicationCover, setPublicationName} = useGeneral();
    const { seasonID } = useSeason();
    const [ dashboardReport, setDashboardReport ] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [yayinResim, setYayinResim] = useState(null);
    const [file, setFile] = useState(null);
    const [yayinAdi, setYayinAdi] = useState('');
    const [isbn, setIsbn] = useState('');
    const [error, setError] = useState(null)
    const [excelFile, setExcelFile] = useState(null);
    const [denemeModal, setDenemeModal] = useState(false);
    const backgroundImageUrl = toAbsoluteUrl("/media/stock-600x600/img-20.jpg");
    const [lessonsList, setLessonsList] = useState([]);

    const backgroundImageUrl3 = toAbsoluteUrl("/media/svg/avatars/diego-dashboard-character.svg");
    const backgroundImageUrl2 = toAbsoluteUrl("/media/bg/homework.jpg");
   // const [levelName,setLevelName] = useState("Seçiniz");
   // const [lessonName,setLessonName] = useState("Seçiniz");

    useEffect(() => {
        setLessonID(null);
        setLevelID(null);
        const newDaylist = [];
        const newDaylistText = [];
        const cozenler = [];
        const today = new Date();
        const day = today.getDay();
        
        for(let i = day; i < 7; i++) {
            newDaylist.push(dayList[i]);
            newDaylistText.push(dayListText[i]);
        }

        for(let i = 0; i < day; i++) {
            newDaylist.push(dayList[i]);
            newDaylistText.push(dayListText[i]);
        }

        if(publisherID && token && seasonID) {
            getPubliserStatistics(publisherID, seasonID, token)
                .then(response => {
                    if(response.data) {
                        newDaylistText.map(item => {
                            cozenler.push(response.data?.haftalikRapor[item]);
                        });

                        response.data.haftaCozen = cozenler;
                        response.data.gunler = newDaylist;
                    }
                    response.data && setDashboardReport(response.data);
                }).catch(err => {
                console.log(err)
            });
        }

        else if(teacherID && token) {
            getTeacherStatistics(teacherID, token)
                .then(response => {
                    if(response.data) {
                        newDaylistText.map(item => {
                            cozenler.push(response.data?.toplamIstatistikler?.haftalikRapor[item]);
                        });
                        
                        response.data.haftaCozen = cozenler;
                        response.data.gunler = newDaylist;
                    }
                    response.data && setDashboardReport(response.data);
                }).catch(err => {
                    console.log(err)
                });
        }
    }, [publisherID, teacherID, token, seasonID]);

    const testOlustur = () => {
        props.history.push({
            pathname: '/tests',
            search: '',
            state: { status: 'newTest' }
        });
    }

    const odevOlustur = () => {
        props.history.push({
            pathname: '/homework',
            search: '',
            state: { status: 'newHomework' }
        });
    }

    const excelOpen = () => {
        setModalOpen(true);
        setDenemeModal(false);
    }

    const excelClose = () => {
        setError("");
        setModalOpen(false);
        setDenemeModal(false);
        setFile(null);
        setYayinResim(null);
    };

    const onFileChange = event => {
        const reader = new FileReader();
        const file = event.target.files[0];

        reader.onload = e => {
            setYayinResim(e.target.result)
        };

        reader.readAsDataURL(file);
        setFile(event.target.files[0]);
        setPublicationCover(event.target.files[0]);
        setError("");
    };

    const onExcelChange = event => {
        const files = event.target.files;
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        setError("");

        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const xData = XLSX.utils.sheet_to_json(ws);

            setIsbn(xData[0]["ısbn"].toString().trim());
            setISBN(xData[0]["ısbn"].toString().trim());
            setYayinAdi(xData[0]["kitap adı"].trim());

            setExcelFile(xData);
            setExcelData(xData);
        }

        if (rABS) {
            reader.readAsBinaryString(files[0]);
        } else {
            reader.readAsArrayBuffer(files[0]);
        };
    }

    const onFileUpload = bool => {
        if(bool){

            if (!file) {
                setError('Lütfen bir resim seçiniz');
            } else if (["image/jpeg", "image/jpg", "image/png", "image/gif"].indexOf(file.type) === !-1) {
                setError('Lütfen Jpeg, Png veya Gif formatında bir resim ekleyiniz');
            } else if (!excelFile) {
                setError('Lütfen geçerli bir excel dosyası ekleyiniz');
            } else if(!levelID){
                setError('Lütfen bir yayın grubu seçiniz')
            } else if(!lessonID){
                setError('Lütfen bir ders seçiniz')
            } else if (isbn.length < 13) {
                setError('Lütfen geçerli bir ISBN giriniz')
            } else {
                setError("");
                setExamPublication(false);
                setModalOpen(false);
                setPublicationName(yayinAdi.trim());
                setISBN(isbn.toString());
                props.history.push('/publish');
            }
        }
    }

    const onFileUploadOfExamPublication = bool => {
        if(bool){
            if (!file) {
                setError('Lütfen bir resim seçiniz');
            } else if (["image/jpeg", "image/jpg", "image/png", "image/gif"].indexOf(file.type) === !-1) {
                setError('Lütfen Jpeg, Png veya Gif formatında bir resim ekleyiniz');
            } else if (!excelFile) {
                setError('Lütfen geçerli bir excel dosyası ekleyiniz');
            } else if(!levelID){
                setError('Lütfen bir yayın grubu seçiniz')
            } else if(!lessonID){
                setError('Lütfen bir ders seçiniz')
            } else if (isbn.length < 13) {
                setError('Lütfen geçerli bir ISBN giriniz')
            } else if (!yayinAdi) {
                setError('Lütfen yayın adını giriniz')
            } else {
                setError("");
                setModalOpen(false);
                setPublicationName(yayinAdi.trim());
                setISBN(isbn.toString());
                setExamPublication(true);
                props.history.push('/publish');
            }
        }
    }

    const onExcelChangeOfExamPublication = event => {
        const files = event.target.files;
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        setError("");

        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            const wsnames = wb.SheetNames;
            let xDatas = []
            for(let i = 0; i < wsnames.length; i++){
                const ws = wb.Sheets[wsnames[i]];
                const xData = XLSX.utils.sheet_to_json(ws);
                xDatas.push({examName: wsnames[i], active: i=== 0 ? true : false, data:[...xData]});
            }
            setExcelFile(xDatas);
            setExcelData(xDatas);
        }

        if (rABS) {
            reader.readAsBinaryString(files[0]);
        } else {
            reader.readAsArrayBuffer(files[0]);
        };
    }

    const getLessons = levelid => {
        setLoading(true);
        getLevelLessonsService(levelid)
            .then(response => {
                if(response.data) {
                    setLessonsList(response.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(()=>setLoading(false));
    }

    return <>
        <div className="row">
            <div className="col-xl-4">
                <DashboardWidget1 report={dashboardReport} className="gutter-b card-stretch" chartColor="danger" />
            </div>
            <div className="col-xl-8">
                <div className="row">
                    <div className="col-xl-3">
                        <div className="card card-custom gutter-b" style={{ height: 150, backgroundColor: '#79d4ff' }}>
                            <div className="card-body">
                                <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                    
                                    {publisherID && <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />}
                                    {teacherID &&  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />}
                                </span>
                                <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3" >
                                    {publisherID && dashboardReport?.yayinSayisi}
                                    {teacherID && dashboardReport?.kullaniciSayisi}
                                </div>
                                <span className="text-inverse-primary font-weight-bold font-size-lg mt-1" >
                                    {teacherID && 'Kullanıcı Sayısı'}
                                    {publisherID && 'Yayın Sayısı'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9">
                        <div className="card card-custom gutter-b" style={{ height: 150 }} >
                            <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
                                <div className="mr-2">
                                    <h3 className="font-weight-bolder">Tüm İçerikler</h3>
                                    <div className="text-dark-50 font-size-lg mt-2">
                                        {
                                            teacherID && 'Testler | Ödevler | Ödev İstatistikleri'
                                        }
                                        {
                                            publisherID && 'Seviyeler | Dersler | Yayınlar | Konular | Testler'
                                        }
                                    </div>
                                </div>
                                {
                                    teacherID &&
                                    <>
                                        <div>
                                            <Link to="/tests" className="btn btn-primary font-weight-bold py-3 px-6 mr-1">Testler</Link>
                                            <Link to="/homework" className="btn btn-primary font-weight-bold py-3 px-6">Ödevler</Link>
                                        </div>
                                    </>
                                }
                                {
                                    publisherID &&
                                        <Link to="/publish" className="btn btn-primary font-weight-bold py-3 px-6">Yayınlarımız</Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <div className="card card-custom bgi-no-repeat" style={{ height: 175, marginBottom: 25,  backgroundColor: "#90dbc6",  backgroundSize: "100% auto", backgroundImage: `url("${backgroundImageUrl2}")`}}>
                            <div className="card-body d-flex align-items-center">
                                {
                                    teacherID && 
                                        <div>
                                            <h3 className="text-white font-weight-bolder line-height-lg mb-5">Hızlı ve Kolay <br/> Ödev Oluşturma</h3>
                                            <span className="btn btn-success font-weight-bold px-6 py-3" onClick={odevOlustur}>Ödev Oluştur</span>
                                        </div>
                                }
                                {
                                    publisherID &&
                                        <div>
                                            <h3 className="text-white font-weight-bolder line-height-lg mb-5">Hızlı ve Kolay <br/> Yayın Oluşturma</h3>
                                            <span className="btn btn-success font-weight-bold px-6 py-3" onClick={excelOpen}>Yayın Oluştur</span>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-6">
                                <div className="card card-custom gutter-b" style={{ height: 150, backgroundColor: '#ffaaaa' }}>
                                    <div className="card-body">
                                        <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                            
                                            {publisherID && <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />}
                                            {teacherID &&  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line2.svg")} />}
                                        </span>
                                        <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3" >
                                            {publisherID && dashboardReport?.cozulenSoruSayisi}
                                            {teacherID && dashboardReport?.toplamOdevSayisi}
                                        </div>
                                        <span className="text-inverse-primary font-weight-bold font-size-lg mt-1" >
                                            {teacherID && 'Ödev Sayısı'}
                                            {publisherID && 'Çözülen Soru'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card card-custom  gutter-b" style={{ height: 150, backgroundColor: '#67beff' }}>
                                    <div className="card-body">
                                        <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                            {publisherID &&  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />}
                                            {teacherID &&  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")} />}
                                        </span>
                                        <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3" >
                                            {publisherID && dashboardReport?.kullaniciSayisi}
                                            {teacherID && dashboardReport?.toplamTestSayisi}
                                        </div>
                                        <span className="text-inverse-primary font-weight-bold font-size-lg mt-1" >
                                            {teacherID && 'Test Sayısı'}
                                            {publisherID && 'Kullanan Kişi'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div style={{
                            height: 350,
                            display: "flex",
                            alignItems: "flex-end",
                            flexDirection: "column",
                            position: "relative",
                        }}>
                            <h3 className="text-white font-weight-bolder line-height-lg mb-5" style={{position:"absolute", right: 180, top:25}}>Deneme Yayınlarınızı<br/>Buradan<br/>Oluşturabilirsiniz</h3>
                            <div className="card-body d-flex flex-column align-items-start justify-content-start" style={{position:"absolute", right: 190, top: 230}}>
                                <span  className="btn btn-warning font-weight-bold px-6 py-3" onClick={()=>setDenemeModal(true)}>
                                    Deneme Yayını Oluştur
                                </span>
                            </div>

                            <img style={{
                                //marginBottom: -20,
                                width:420,
                                height: 350,
                                borderRadius: 10,
                            }}src={backgroundImageUrl}/>


                            {
                                teacherID &&
                                <div className="card-body d-flex flex-column align-items-start justify-content-start">
                                    <div className="p-1 flex-grow-1">
                                        <h3 className="text-white font-weight-bolder line-height-lg mb-5">
                                            Ödev oluşturmak
                                            <br />
                                            Çok kolay
                                        </h3>
                                    </div>
                                    <span  className="btn btn-warning font-weight-bold" onClick={testOlustur}>
                                            Test Oluştur
                                            <span className="svg-icon svg-icon-lg svg-icon-light">
                                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}/>
                                            </span>
                                        </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* normal yayın ekleme pop-up*/}
            <Dialog
                open={modalOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={excelClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>Excel İle Yayın Ekle</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {
                            error && <span className="fade alert alert-danger show" style={{display: 'flex'}}>{ error }</span>
                        }
                    </DialogContentText>
                    <div className="form-group row publishAddContainer">
                        <div className="col-md-6">
                            <FormControl className="formAdd">
                                <InputLabel htmlFor="age-native-simple">Yayın Grubu</InputLabel>
                                <Select
                                    native
                                    value={levelID || ''}
                                    onChange={e => {
                                        setLevelID(e.target.value);
                                        setError('');
                                        getLessons(e.target.value);
                                    }}>
                                    <option value=""/>
                                    {
                                        levels &&
                                        levels.map(item => <option key={item.id} value={item._id}>{item.name}</option>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <FormControl className="formAdd">
                                <InputLabel htmlFor="age-native-simple">Ders</InputLabel>
                                <Select
                                    native
                                    value={lessonID || ''}
                                    onChange={e => {
                                        setLessonID(e.target.value);
                                        setError('');
                                    }}>
                                    <option value=""/>
                                    {
                                        lessonsList &&
                                        lessonsList.map(item => <option key={item.id} value={item._id}>{item.name}</option>)
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-6 imgView">
                            <div className="image-input ">
                                <div className="imgTop">
                                    {
                                        yayinResim ?
                                            <img src={yayinResim}/>
                                            :
                                            <label className="imageSelect svg-icon svg-icon-5x svg-icon-success" >
                                                <svg viewBox="0 0 512 512" height="64" width="64">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <path fill="#000000" d="M457,41H55c-7.72,0-14,6.28-14,14v320.219l121.771-103.036c1.986-1.678,4.926-1.558,6.765,0.282l52.863,52.863    l80.513-63.26c2.107-1.654,5.143-1.355,6.885,0.678l33.41,38.978l39.259-39.259c0.999-0.999,2.382-1.525,3.784-1.459    c1.411,0.07,2.727,0.734,3.621,1.828L471,361.993V55C471,47.28,464.72,41,457,41z M121,181c-22.056,0-40-17.944-40-40    s17.944-40,40-40s40,17.944,40,40S143.056,181,121,181z M356,71h50c2.762,0,5,2.239,5,5s-2.238,5-5,5h-50c-2.762,0-5-2.239-5-5    S353.238,71,356,71z M401,136c0,1.32-0.53,2.6-1.47,3.54c-0.931,0.93-2.211,1.46-3.53,1.46c-1.32,0-2.61-0.53-3.54-1.46    c-0.93-0.94-1.46-2.22-1.46-3.54c0-1.32,0.53-2.61,1.46-3.54c0.93-0.93,2.22-1.46,3.54-1.46c1.319,0,2.6,0.53,3.53,1.46    C400.47,133.4,401,134.68,401,136z M322.46,72.47c0.939-0.94,2.22-1.47,3.54-1.47c1.319,0,2.6,0.53,3.54,1.47    C330.47,73.4,331,74.68,331,76c0,1.32-0.53,2.6-1.46,3.54C328.6,80.47,327.31,81,326,81c-1.311,0-2.601-0.53-3.54-1.46    C321.53,78.6,321,77.32,321,76C321,74.68,321.53,73.4,322.46,72.47z M311,106c0-2.761,2.238-5,5-5h40c2.762,0,5,2.239,5,5    s-2.238,5-5,5h-40C313.238,111,311,108.761,311,106z M341,136c0-2.761,2.238-5,5-5h20c2.762,0,5,2.239,5,5s-2.238,5-5,5h-20    C343.238,141,341,138.761,341,136z M436,171h-70c-2.762,0-5-2.239-5-5s2.238-5,5-5h70c2.762,0,5,2.239,5,5S438.762,171,436,171z"/>
                                                        <path fill="#000000" d="M349.732,309.339l20.063,23.407c1.798,2.097,1.555,5.253-0.542,7.05c-2.094,1.798-5.253,1.555-7.05-0.542l-19.563-22.824    l-23.105,23.105c-1.951,1.953-5.119,1.953-7.07,0c-1.953-1.952-1.953-5.118,0-7.07l23.649-23.649l-30.793-35.924l-75.801,59.559    l70.015,70.015c1.953,1.952,1.953,5.118,0,7.07C298.56,410.512,297.279,411,296,411s-2.56-0.488-3.535-1.465l-70.864-70.863    l-52.512,41.26c-0.916,0.72-2.005,1.068-3.086,1.068c-1.482,0-2.949-0.655-3.935-1.911c-1.706-2.171-1.329-5.314,0.842-7.021    l51.569-40.519l-48.762-48.761L41,388.319V457c0,7.72,6.28,14,14,14h402c7.72,0,14-6.28,14-14v-79.215l-85.371-104.343    L349.732,309.339z"/>
                                                        <path fill="#000000" d="M121,111c-16.542,0-30,13.458-30,30s13.458,30,30,30s30-13.458,30-30S137.542,111,121,111z"/>
                                                    </g>
                                                </svg>

                                                <span>Resim Seç</span>
                                                <input type="file" onChange={onFileChange} accept=".png, .jpg, .jpeg" encType="multipart/form-data"/>
                                            </label>
                                    }
                                </div>
                                {
                                    yayinResim &&
                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow imgClose" onClick={() => { setFile(null); setYayinResim(null);}}>
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                }
                            </div>
                        </div>

                        <div className="col-md-6 imgViewAdmin">
                            <div className="image-input" style={excelFile && {background: '#209e3d'}}>
                                <div className="imgTop">
                                    {
                                        excelFile ?
                                            <div>
                                                <img src="/media/excel.png"/>
                                                <span style={{color:'#fff'}}>{excelFile[0] && excelFile[0]["kitap adı"]}<br/>{excelFile[0] && excelFile[0]["ısbn"]}</span>
                                            </div>
                                            :
                                            <label className="imageSelect svg-icon svg-icon-5x svg-icon-success" >
                                                <svg viewBox="0 0 30 30">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <path d="M28.705,7.506l-5.461-6.333l-1.08-1.254H9.262   c-1.732,0-3.133,1.403-3.133,3.136V7.04h1.942L8.07,3.818c0.002-0.975,0.786-1.764,1.758-1.764l11.034-0.01v5.228   c0.002,1.947,1.575,3.523,3.524,3.523h3.819l-0.188,15.081c-0.003,0.97-0.79,1.753-1.759,1.761l-16.57-0.008   c-0.887,0-1.601-0.87-1.605-1.942v-1.277H6.138v1.904c0,1.912,1.282,3.468,2.856,3.468l17.831-0.004   c1.732,0,3.137-1.41,3.137-3.139V8.966L28.705,7.506" fill="#434440" />
                                                        <path d="M20.223,25.382H0V6.068h20.223V25.382 M1.943,23.438h16.333V8.012H1.943" fill="#08743B" />
                                                        <polyline fill="#08743B" points="15.73,20.822 12.325,20.822 10.001,17.538 7.561,20.822 4.14,20.822 8.384,15.486 4.957,10.817    8.412,10.817 10.016,13.355 11.726,10.817 15.242,10.817 11.649,15.486 15.73,20.822  " />
                                                    </g>
                                                </svg>

                                                <span>Excel Dosyası Seç</span>
                                                <input type="file" onChange={onExcelChange}  accept={SheetJSFT} encType="multipart/form-data"/>
                                            </label>
                                    }
                                </div>
                                {
                                    excelFile &&
                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow imgClose"  onClick={()=>setExcelFile(null)}>
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                }
                            </div>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions style={{ justifyContent: "space-between"}}>
                    <span className="headerButton mr-4" style={{ padding: 5, alignSelf: "flex-start"}}>
                        <span className="svg-icon svg-icon-2x svg-icon-success" style={{ margin: 0 }}>
                        <svg viewBox="0 0 30 30">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <path fill="#434440" d="m26.72499,8.27149l-4.9773,-5.77206l-0.98434,-1.14293l-11.75922,0c-1.57859,0 -2.8555,1.27873 -2.8555,2.85823l0,3.63203l1.76999,0l-0.00091,-2.93662c0.00182,-0.88864 0.71638,-1.60776 1.60229,-1.60776l10.05668,-0.00911l0,4.76494c0.00182,1.77455 1.4355,3.21095 3.21187,3.21095l3.48074,0l-0.17135,13.74522c-0.00273,0.88408 -0.72003,1.59773 -1.6032,1.60502l-15.10233,-0.00729c-0.80844,0 -1.45919,-0.79294 -1.46284,-1.76999l0,-1.16389l-1.77272,0l0,1.73536c0,1.74265 1.16845,3.16083 2.60303,3.16083l16.25164,-0.00365c1.57859,0 2.85914,-1.28511 2.85914,-2.86097l0,-16.10764l-1.14566,-1.33068" />
                            <path fill="#08743B" d="m13.68177,22.05013l-13.18177,0l0,-12.58927l13.18177,0l0,12.58927m-11.91528,-1.26714l10.64619,0l0,-10.05499l-10.64619,0" />
                            <polyline points="10.753141403198242,19.07782554626465 8.533692359924316,19.07782554626465 7.01885986328125,16.937246322631836 5.428417682647705,19.07782554626465 3.198538303375244,19.07782554626465 5.964865684509277,15.599708557128906 3.73107647895813,12.556357383728027 5.983116626739502,12.556357383728027 7.028637409210205,14.210678100585938 8.143250465393066,12.556357383728027 10.435053825378418,12.556357383728027 8.093060493469238,15.599708557128906 10.753141403198242,19.07782554626465 " fill="#08743B" />
                            <path transform="rotate(-180 20.01045799255371,19.192615509033203)" d="m15.76046,19.17799l4.25,-6.07875l4.25,6.07875l-2.125,0l0,6.108l-4.25,0l0,-6.108l-2.125,0z" fill="#000" />
                            </g>
                        </svg>
                        </span>
                        <span className="indirButton"><a href="/excel/excelileeklesablon.xlsx" download>Excel Şablon İndir</a></span>
                    </span>
                    <div>
                        <span className="btn btn-danger font-weight-bolder font-size-sm" onClick={() => {excelClose();}}>İptal</span>
                        <span className="btn btn-success font-weight-bolder font-size-sm ml-5" onClick={() => {onFileUpload(true);}}>Oluştur</span>
                    </div>
                </DialogActions>
            </Dialog>

            {/* excel yayın ekleme pop-up*/}
            <Dialog
                open={denemeModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={excelClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>Excel İle Deneme Yayını Ekle</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {
                            error && <span className="fade alert alert-danger show" style={{display: 'flex'}}>{ error }</span>
                        }
                    </DialogContentText>
                    <div className="form-group row publishAddContainer">
                        <div className="col-md-6">
                            <FormControl className="formAdd">
                                <InputLabel htmlFor="age-native-simple">Yayın Grubu</InputLabel>
                                <Select
                                    native
                                    value={levelID || ''}
                                    onChange={e => {
                                        setLevelID(e.target.value);
                                        setError('');
                                        getLessons(e.target.value);
                                    }}>
                                    <option value=""/>
                                    {
                                        levels &&
                                        levels.map(item => <option key={item.id} value={item._id}>{item.name}</option>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <FormControl className="formAdd">
                                <InputLabel htmlFor="age-native-simple">Ders</InputLabel>
                                <Select
                                    native
                                    value={lessonID || ''}
                                    onChange={e => {
                                        setLessonID(e.target.value);
                                        setError('');
                                    }}>
                                    <option value=""/>
                                    {
                                        lessonsList &&
                                        lessonsList.map(item => <option key={item.id} value={item._id}>{item.name}</option>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <div className="MuiFormControl-root formAdd imgBottom">
                                <div className="inputContainer">
                                    <label>Yayın Adı: </label>
                                    <input type="text" value={yayinAdi} placeholder="Yayın adını giriniz..." onChange={event => setYayinAdi(event.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="MuiFormControl-root formAdd imgBottom" >
                                <div className="inputContainer">
                                    <label>Yayın ISBN: </label>
                                    <input type="text" value={isbn} placeholder={isbn ? isbn : "Yayına ait ISBN giriniz..."} onChange={event => setIsbn(event.target.value.trim())}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 imgView">
                            <div className="image-input ">
                                <div className="imgTop">
                                    {
                                        yayinResim ?
                                            <img src={yayinResim}/>
                                            :
                                            <label className="imageSelect svg-icon svg-icon-5x svg-icon-success" >
                                                <svg viewBox="0 0 512 512" height="64" width="64">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <path fill="#000000" d="M457,41H55c-7.72,0-14,6.28-14,14v320.219l121.771-103.036c1.986-1.678,4.926-1.558,6.765,0.282l52.863,52.863    l80.513-63.26c2.107-1.654,5.143-1.355,6.885,0.678l33.41,38.978l39.259-39.259c0.999-0.999,2.382-1.525,3.784-1.459    c1.411,0.07,2.727,0.734,3.621,1.828L471,361.993V55C471,47.28,464.72,41,457,41z M121,181c-22.056,0-40-17.944-40-40    s17.944-40,40-40s40,17.944,40,40S143.056,181,121,181z M356,71h50c2.762,0,5,2.239,5,5s-2.238,5-5,5h-50c-2.762,0-5-2.239-5-5    S353.238,71,356,71z M401,136c0,1.32-0.53,2.6-1.47,3.54c-0.931,0.93-2.211,1.46-3.53,1.46c-1.32,0-2.61-0.53-3.54-1.46    c-0.93-0.94-1.46-2.22-1.46-3.54c0-1.32,0.53-2.61,1.46-3.54c0.93-0.93,2.22-1.46,3.54-1.46c1.319,0,2.6,0.53,3.53,1.46    C400.47,133.4,401,134.68,401,136z M322.46,72.47c0.939-0.94,2.22-1.47,3.54-1.47c1.319,0,2.6,0.53,3.54,1.47    C330.47,73.4,331,74.68,331,76c0,1.32-0.53,2.6-1.46,3.54C328.6,80.47,327.31,81,326,81c-1.311,0-2.601-0.53-3.54-1.46    C321.53,78.6,321,77.32,321,76C321,74.68,321.53,73.4,322.46,72.47z M311,106c0-2.761,2.238-5,5-5h40c2.762,0,5,2.239,5,5    s-2.238,5-5,5h-40C313.238,111,311,108.761,311,106z M341,136c0-2.761,2.238-5,5-5h20c2.762,0,5,2.239,5,5s-2.238,5-5,5h-20    C343.238,141,341,138.761,341,136z M436,171h-70c-2.762,0-5-2.239-5-5s2.238-5,5-5h70c2.762,0,5,2.239,5,5S438.762,171,436,171z"/>
                                                        <path fill="#000000" d="M349.732,309.339l20.063,23.407c1.798,2.097,1.555,5.253-0.542,7.05c-2.094,1.798-5.253,1.555-7.05-0.542l-19.563-22.824    l-23.105,23.105c-1.951,1.953-5.119,1.953-7.07,0c-1.953-1.952-1.953-5.118,0-7.07l23.649-23.649l-30.793-35.924l-75.801,59.559    l70.015,70.015c1.953,1.952,1.953,5.118,0,7.07C298.56,410.512,297.279,411,296,411s-2.56-0.488-3.535-1.465l-70.864-70.863    l-52.512,41.26c-0.916,0.72-2.005,1.068-3.086,1.068c-1.482,0-2.949-0.655-3.935-1.911c-1.706-2.171-1.329-5.314,0.842-7.021    l51.569-40.519l-48.762-48.761L41,388.319V457c0,7.72,6.28,14,14,14h402c7.72,0,14-6.28,14-14v-79.215l-85.371-104.343    L349.732,309.339z"/>
                                                        <path fill="#000000" d="M121,111c-16.542,0-30,13.458-30,30s13.458,30,30,30s30-13.458,30-30S137.542,111,121,111z"/>
                                                    </g>
                                                </svg>

                                                <span>Resim Seç</span>
                                                <input type="file" onChange={onFileChange} accept=".png, .jpg, .jpeg" encType="multipart/form-data"/>
                                            </label>
                                    }
                                </div>
                                {
                                    yayinResim &&
                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow imgClose" onClick={() => { setFile(null); setYayinResim(null);}}>
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                }
                            </div>
                        </div>

                        <div className="col-md-6 imgViewAdmin">
                            <div className="image-input" style={excelFile && {background: '#209e3d'}}>
                                <div className="imgTop">
                                    {
                                        excelFile ?
                                            <div>
                                                <img src="/media/excel.png"/>
                                                <span style={{color:'#fff'}}>{excelFile[0] && excelFile[0]["kitap adı"]}<br/>{excelFile[0] && excelFile[0]["ısbn"]}</span>
                                            </div>
                                            :
                                            <label className="imageSelect svg-icon svg-icon-5x svg-icon-success" >
                                                <svg viewBox="0 0 30 30">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <path d="M28.705,7.506l-5.461-6.333l-1.08-1.254H9.262   c-1.732,0-3.133,1.403-3.133,3.136V7.04h1.942L8.07,3.818c0.002-0.975,0.786-1.764,1.758-1.764l11.034-0.01v5.228   c0.002,1.947,1.575,3.523,3.524,3.523h3.819l-0.188,15.081c-0.003,0.97-0.79,1.753-1.759,1.761l-16.57-0.008   c-0.887,0-1.601-0.87-1.605-1.942v-1.277H6.138v1.904c0,1.912,1.282,3.468,2.856,3.468l17.831-0.004   c1.732,0,3.137-1.41,3.137-3.139V8.966L28.705,7.506" fill="#434440" />
                                                        <path d="M20.223,25.382H0V6.068h20.223V25.382 M1.943,23.438h16.333V8.012H1.943" fill="#08743B" />
                                                        <polyline fill="#08743B" points="15.73,20.822 12.325,20.822 10.001,17.538 7.561,20.822 4.14,20.822 8.384,15.486 4.957,10.817    8.412,10.817 10.016,13.355 11.726,10.817 15.242,10.817 11.649,15.486 15.73,20.822  " />
                                                    </g>
                                                </svg>

                                                <span>Excel Dosyası Seç</span>
                                                <input type="file" onChange={onExcelChangeOfExamPublication}  accept={SheetJSFT} encType="multipart/form-data"/>
                                            </label>
                                    }
                                </div>
                                {
                                    excelFile &&
                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow imgClose"  onClick={()=>setExcelFile(null)}>
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                }
                            </div>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions style={{ justifyContent: "space-between"}}>
                    <span className="headerButton mr-4" style={{ padding: 5, alignSelf: "flex-start"}}>
                        <span className="svg-icon svg-icon-2x svg-icon-success" style={{ margin: 0 }}>
                        <svg viewBox="0 0 30 30">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <path fill="#434440" d="m26.72499,8.27149l-4.9773,-5.77206l-0.98434,-1.14293l-11.75922,0c-1.57859,0 -2.8555,1.27873 -2.8555,2.85823l0,3.63203l1.76999,0l-0.00091,-2.93662c0.00182,-0.88864 0.71638,-1.60776 1.60229,-1.60776l10.05668,-0.00911l0,4.76494c0.00182,1.77455 1.4355,3.21095 3.21187,3.21095l3.48074,0l-0.17135,13.74522c-0.00273,0.88408 -0.72003,1.59773 -1.6032,1.60502l-15.10233,-0.00729c-0.80844,0 -1.45919,-0.79294 -1.46284,-1.76999l0,-1.16389l-1.77272,0l0,1.73536c0,1.74265 1.16845,3.16083 2.60303,3.16083l16.25164,-0.00365c1.57859,0 2.85914,-1.28511 2.85914,-2.86097l0,-16.10764l-1.14566,-1.33068" />
                            <path fill="#08743B" d="m13.68177,22.05013l-13.18177,0l0,-12.58927l13.18177,0l0,12.58927m-11.91528,-1.26714l10.64619,0l0,-10.05499l-10.64619,0" />
                            <polyline points="10.753141403198242,19.07782554626465 8.533692359924316,19.07782554626465 7.01885986328125,16.937246322631836 5.428417682647705,19.07782554626465 3.198538303375244,19.07782554626465 5.964865684509277,15.599708557128906 3.73107647895813,12.556357383728027 5.983116626739502,12.556357383728027 7.028637409210205,14.210678100585938 8.143250465393066,12.556357383728027 10.435053825378418,12.556357383728027 8.093060493469238,15.599708557128906 10.753141403198242,19.07782554626465 " fill="#08743B" />
                            <path transform="rotate(-180 20.01045799255371,19.192615509033203)" d="m15.76046,19.17799l4.25,-6.07875l4.25,6.07875l-2.125,0l0,6.108l-4.25,0l0,-6.108l-2.125,0z" fill="#000" />
                            </g>
                        </svg>
                        </span>
                        <span className="indirButton"><a href="/excel/exceliledenemeeklesablon.xlsx" download>Excel Şablon İndir</a></span>
                    </span>
                    <div>
                        <span className="btn btn-danger font-weight-bolder font-size-sm" onClick={() => {excelClose();}}>İptal</span>
                        <span className="btn btn-success font-weight-bolder font-size-sm ml-5" onClick={() => {onFileUploadOfExamPublication(true);}}>Oluştur</span>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
        
    </>;
}
