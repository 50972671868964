import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import XLSX from 'xlsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SVG from "react-inlinesvg";

import { getPublications, getPublishers, setUserService, setPublisherService } from "../admin/adminServices/yayinevlleriService"
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FormControl, InputLabel, Select, Switch } from "@material-ui/core";

import { SheetJSFT } from "../../../partials/excelTypes";
import { useSeason } from "../../../hooks/use-season";
import { useGeneral } from "../../../hooks/use-general";


export const YayineviDetay = (props) => {
    const suhbeader = useSubheader();
    const token = localStorage.getItem('token');
    suhbeader.setTitle("Yayınevi Detay");
    const history = useHistory();
    const { seasonList } = useSeason();
    const { levels, lessons } = useGeneral();

    const [publications, setPublications] = useState([]);
    const [publishImage, setPublishImage] = useState(null)
    const [excelOpen, setExcelOpen] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [excelFile, setExcelFile] = useState(null);
    const [season, setSeason] = useState(seasonList[0]);
    const [level, setLevel] = useState(levels[0]);
    const [lesson, setLesson] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        getPublicationList();
    }, [])
  
    const getPublicationList = () => {
        getPublications(token, props?.location?.data?.id)
            .then(res => setPublications(res.data))
            .catch(error => console.log('error', error));
    }

    const back = () =>{ 
        history.push({pathname: '/yayinevleri'});
    }

    const removeImage = () => {
        setPublishImage(null); 
        setImageFile(null);
        setExcelFile(null);
    }

    const closeExcel = () => {
        setPublishImage(null); 
        setImageFile(null);
        setExcelFile(null);
        setExcelOpen(false);
    }
    const removeExcel = () => {
        setExcelFile(null);
    }

    const onFileChange = event => {
        const reader = new FileReader();
        const file = event.target.files[0];
        
        reader.onload = e => {
            setPublishImage(e.target.result)
        };
        
        reader.readAsDataURL(file);
        setImageFile(event.target.files[0]); 
    }; 

    const onExcelChange = event => {
        const files = event.target.files;
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
    
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const xData = XLSX.utils.sheet_to_json(ws);
            const bookLessons = [];

            let xDataTransfer = {};

            xData.map((item, i)  => {
                if(item.ders && bookLessons.indexOf(item.ders) === -1) {
                    item.ders = (item.ders).replace(/İ/g, 'i');
                    item.ders = item.ders.replace(/İ/g, 'ı');
                    const ders = item.ders.toLowerCase();
                    const index = lessons.findIndex(lssn => (lssn.name).toLowerCase() === ders);
                    bookLessons.push(lessons[index])
                    item.ders = lessons[index];
                    xDataTransfer = item.ders;
                }
                else {
                    item.ders = xDataTransfer;
                }
            });

            setLesson(bookLessons);
            setExcelFile(xData);
        }

        if (rABS) {
          reader.readAsBinaryString(files[0]);
        } else {
          reader.readAsArrayBuffer(files[0]);
        };
    } 

    const createPublish = () => {
        let lessonControl = 0;
        if(!excelFile) {
            setError('Lütfen geçerli bir excel dosyası yükleyiniz.');
        }
        else if(lesson) {
            lesson.map(item => {
                if(!item?.id) {
                    lessonControl++;
                }
            })

            if(!season) {
                setError('Lütfen sezon bilgisini seçiniz.');
            }
            else if(!level) {
                setError('Lütfen seviye bilgisini seçiniz.');
            }
            else if(lessonControl > 0) {
                setError('Excel dosyanızda sistemde kayıtlı olmayan bir ders var veya ders ismi hatalı.');
            }
            else if(!publishImage) {
                setError('Lütfen kitabınız için bir resim yükleyiniz.');
            }
            else {
                history.push({pathname: '/yayinekle', imageFile, excelFile, level, season, lesson, publisher: props?.location?.data?.id});
            }
        }
        else {
            setError('Excel dosyanızda sistemde kayıtlı olmayan bir ders var veya ders ismi hatalı.');
        }
    }

    return (<>
        <div className="card card-custom gutter-b">
            <div className="card-header">
                <div className="card-title">
                    <span className="btn btn-icon btn-light btn-hover-primary btn-sm" onClick={back}>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")} />
                        </span>
                    </span>
                    <h3 className="card-label" style={{marginLeft:20}}>{props?.location?.data?.name}</h3>
                </div>
                
                <div className="card-toolbar">
                    <a className="btn btn-success font-weight-bolder font-size-sm"  onClick={() => setExcelOpen(true)}>Excel İle Yayın Ekle</a>
                </div>
            </div>

            <div className="card-body" style={cardTextStyle}>
                <div className="row">
                    <div className="card-body py-0">
                        <div className="table-responsive">
                            <div className="publishContainer">
                                {
                                    publications &&
                                    publications.map(item => 
                                        <div key={item._id} className="col-md-2">
                                            <div className="publishItem">
                                                <div className="publishHeader">
                                                    <span>
                                                        <Switch checked={item.isPublished} liste={item} onChange={() => console.log('aaaaa')}
                                                            value="checkedB" color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />
                                                    </span>
                                                    <div className="publishHeaderButtons">
                                                        <span className="headerButton success">
                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                                                        </span>
                                                        <span className="headerButton danger">
                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Trash.svg")} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="publish">
                                                    <img className="publishImage" src={item?.cover?.url} />
                                                    <div className="publishDesc"><span>{item.name}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Dialog open={excelOpen} onClose={closeExcel} aria-labelledby="form-dialog-title">
            <DialogTitle>Yayın Ekle</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {
                        error && <span className="fade alert alert-danger show" style={{display: 'flex'}}>{ error }</span>
                    }
                </DialogContentText>    
                <div className="form-group row publishAddContainer">
                    <div className="col-md-6">
                        <FormControl className="formAdd">
                            <InputLabel htmlFor="age-native-simple">Sezon</InputLabel>
                            <Select
                                native
                                value={season}
                                onChange={e => setSeason(e.target.value)}>
                                {
                                    seasonList &&
                                    seasonList.map(item => <option key={item.id} value={item}>{item.name}</option>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-6">
                        <FormControl className="formAdd">
                            <InputLabel htmlFor="age-native-simple">Seviye</InputLabel>
                            <Select
                                native
                                value={level}
                                onChange={e => setLevel(e.target.value)}>
                                {
                                    levels &&
                                    levels.map(item => <option key={item.id} value={item}>{item.name}</option>)
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div className="col-md-6 imgView">
                        <div className="image-input ">
                            <div className="imgTop">
                                {
                                    imageFile ? <img src={publishImage}/>
                                    :
                                    <label className="imageSelect svg-icon svg-icon-5x svg-icon-success" >
                                        <svg viewBox="0 0 512 512" height="64" width="64">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <path fill="#000000" d="M457,41H55c-7.72,0-14,6.28-14,14v320.219l121.771-103.036c1.986-1.678,4.926-1.558,6.765,0.282l52.863,52.863    l80.513-63.26c2.107-1.654,5.143-1.355,6.885,0.678l33.41,38.978l39.259-39.259c0.999-0.999,2.382-1.525,3.784-1.459    c1.411,0.07,2.727,0.734,3.621,1.828L471,361.993V55C471,47.28,464.72,41,457,41z M121,181c-22.056,0-40-17.944-40-40    s17.944-40,40-40s40,17.944,40,40S143.056,181,121,181z M356,71h50c2.762,0,5,2.239,5,5s-2.238,5-5,5h-50c-2.762,0-5-2.239-5-5    S353.238,71,356,71z M401,136c0,1.32-0.53,2.6-1.47,3.54c-0.931,0.93-2.211,1.46-3.53,1.46c-1.32,0-2.61-0.53-3.54-1.46    c-0.93-0.94-1.46-2.22-1.46-3.54c0-1.32,0.53-2.61,1.46-3.54c0.93-0.93,2.22-1.46,3.54-1.46c1.319,0,2.6,0.53,3.53,1.46    C400.47,133.4,401,134.68,401,136z M322.46,72.47c0.939-0.94,2.22-1.47,3.54-1.47c1.319,0,2.6,0.53,3.54,1.47    C330.47,73.4,331,74.68,331,76c0,1.32-0.53,2.6-1.46,3.54C328.6,80.47,327.31,81,326,81c-1.311,0-2.601-0.53-3.54-1.46    C321.53,78.6,321,77.32,321,76C321,74.68,321.53,73.4,322.46,72.47z M311,106c0-2.761,2.238-5,5-5h40c2.762,0,5,2.239,5,5    s-2.238,5-5,5h-40C313.238,111,311,108.761,311,106z M341,136c0-2.761,2.238-5,5-5h20c2.762,0,5,2.239,5,5s-2.238,5-5,5h-20    C343.238,141,341,138.761,341,136z M436,171h-70c-2.762,0-5-2.239-5-5s2.238-5,5-5h70c2.762,0,5,2.239,5,5S438.762,171,436,171z"/>
                                                <path fill="#000000" d="M349.732,309.339l20.063,23.407c1.798,2.097,1.555,5.253-0.542,7.05c-2.094,1.798-5.253,1.555-7.05-0.542l-19.563-22.824    l-23.105,23.105c-1.951,1.953-5.119,1.953-7.07,0c-1.953-1.952-1.953-5.118,0-7.07l23.649-23.649l-30.793-35.924l-75.801,59.559    l70.015,70.015c1.953,1.952,1.953,5.118,0,7.07C298.56,410.512,297.279,411,296,411s-2.56-0.488-3.535-1.465l-70.864-70.863    l-52.512,41.26c-0.916,0.72-2.005,1.068-3.086,1.068c-1.482,0-2.949-0.655-3.935-1.911c-1.706-2.171-1.329-5.314,0.842-7.021    l51.569-40.519l-48.762-48.761L41,388.319V457c0,7.72,6.28,14,14,14h402c7.72,0,14-6.28,14-14v-79.215l-85.371-104.343    L349.732,309.339z"/>
                                                <path fill="#000000" d="M121,111c-16.542,0-30,13.458-30,30s13.458,30,30,30s30-13.458,30-30S137.542,111,121,111z"/>
                                            </g>
                                        </svg>

                                        <span>Resim Seç</span>
                                        <input type="file" onChange={onFileChange} accept=".png, .jpg, .jpeg" encType="multipart/form-data"/>
                                    </label>
                                }
                            </div>
                            {
                                imageFile &&
                                <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow imgClose" onClick={removeImage}>
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                            }
                        </div>
                    </div>

                    <div className="col-md-6 imgViewAdmin">
                        <div className="image-input" style={excelFile && {background: '#209e3d'}}>
                            <div className="imgTop">
                                {
                                    excelFile ?
                                    <div>
                                        <img src="/media/excel.png"/>
                                        <span style={{color:'#fff'}}>{excelFile[0] && excelFile[0]["kitap adı"]}<br/>{excelFile[0] && excelFile[0]["ısbn"]}</span>
                                    </div>
                                    :
                                    <label className="imageSelect svg-icon svg-icon-5x svg-icon-success" >
                                       <svg viewBox="0 0 30 30">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <path d="M28.705,7.506l-5.461-6.333l-1.08-1.254H9.262   c-1.732,0-3.133,1.403-3.133,3.136V7.04h1.942L8.07,3.818c0.002-0.975,0.786-1.764,1.758-1.764l11.034-0.01v5.228   c0.002,1.947,1.575,3.523,3.524,3.523h3.819l-0.188,15.081c-0.003,0.97-0.79,1.753-1.759,1.761l-16.57-0.008   c-0.887,0-1.601-0.87-1.605-1.942v-1.277H6.138v1.904c0,1.912,1.282,3.468,2.856,3.468l17.831-0.004   c1.732,0,3.137-1.41,3.137-3.139V8.966L28.705,7.506" fill="#434440" />
                                                <path d="M20.223,25.382H0V6.068h20.223V25.382 M1.943,23.438h16.333V8.012H1.943" fill="#08743B" />
                                                <polyline fill="#08743B" points="15.73,20.822 12.325,20.822 10.001,17.538 7.561,20.822 4.14,20.822 8.384,15.486 4.957,10.817    8.412,10.817 10.016,13.355 11.726,10.817 15.242,10.817 11.649,15.486 15.73,20.822  " />
                                            </g>
                                        </svg>

                                        <span>Excel Dosyası Seç</span>
                                        <input type="file" onChange={onExcelChange}  accept={SheetJSFT} encType="multipart/form-data"/>
                                    </label>
                                }
                            </div>
                            {
                                excelFile &&
                                <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow imgClose"  onClick={removeExcel}>
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                <span className="btn btn-danger font-weight-bolder font-size-sm" onClick={closeExcel}>İptal</span>
                <span className="btn btn-success font-weight-bolder font-size-sm ml-5" onClick={createPublish}>Oluştur</span>
            </DialogActions>
        </Dialog>

    </>);
};

const cardTextStyle = {
    cursor: "pointer"
};

