import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

export function getLessons(token) {
    return axios.get(URL + '/lessons', {headers: {Authorization: "Bearer " + token}});
}

export function setLessonService(token, lessonName) {
    return axios.post(URL + '/lessons', {
        name: lessonName
    }, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

export function deleteLessonService(token, dersId) {
    return axios.delete(URL + '/lessons/' + dersId, {headers: {Authorization: "Bearer " + token}})
}
