import React from "react";

export const LoadingScreen = () => {
    return (
        <>
            <div className="loadingPage">
                <svg className="splash-spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"/>
                </svg>
            </div>
        </>
    );
}