import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

export function getSeasons(token) {
    return axios.get(URL + '/seasons', {headers: {Authorization: "Bearer " + token}});
}

export function setSeasonService(token, season) {
    return axios.post(URL + '/seasons', {
        name: season
    }, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

export function deleteSeasonService(token, seasonId){
    return axios.delete(URL + '/seasons/' + seasonId, {headers: {Authorization: "Bearer " + token}})
}
