import { Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";

function Logout(props) {
    const removeUserAndTokenFromLocalStorage = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    useEffect(() => {
      removeUserAndTokenFromLocalStorage();
    },[]);

    return (
        <div>
            <Redirect to="/auth/login" />
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Logout));
