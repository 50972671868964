import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useAuth } from "../../hooks/use-auth";
import axios from "axios";

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { Row } from "./components/Row";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

export const Homework = (props) => {
    const URL = process.env.REACT_APP_API_URL;
    const { token, user } = useAuth();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addHomework, setAddHomework] = useState(false);
    const [allHomework, setAllHomework] = useState(null);
    const [updateTest, setUpdateTest] = useState(false);

    const [homeworkList, setHomeworkList] = useState(null);

    const [myTests, setMyTests] = useState(null);
    const [myTestItem, setMyTestItem] = useState(null);
    const [myTestList, setMyTestList] = useState(null);

    const [homeworkName, setHomeworkName] = useState('');
    const [deadline, setDeadline] = useState('');
    const [testCenter, setTestCenter] = useState(null);
    const [message, setMessage] = useState(null);
    const [homeworkSelected, setHomeworkSelected] = useState(null);

    const [levels, setLevels] = useState(null);
    const [level, setLevel] = useState(null);
    const [lessons, setLessons] = useState(null);
    const [lesson, setLesson] = useState(null);
    const [publishers, setPublishers] = useState(null);
    const [publisher, setPublisher] = useState(null);
    const [publication, setPublication] = useState(null); 
    const [publicationDetails, setPublicationDetails] = useState(null);
    const [publicationTests, setPublicationTests] = useState(null);
    const [homeworkResult, setHomeworkResult] = useState(null);
    const [defaultDate, setDefaultDate] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        if(props.location?.state?.status === 'newHomework') {
            addHomeworkAdd()
        }
      }, []);

    useEffect(() => {
        getHomework();
    }, [user, token]);

    // getMyTestList - getLevelList
    useEffect(() => {
        if(testCenter === 'me') {
            getMyTestList();
        } else if(testCenter === 'publisher') {
            getLevelList();
        }
    }, [testCenter]);

    // getLessonList
    useEffect(() => {
        if(level) {
            getLessonList();
        }
    }, [level]);

    // ödev ekle
    useEffect(() => {
        if(addHomework) {
            setHomeworkResult(null);
        }
    }, [addHomework]);

    // getPublicationList
    useEffect(() => {
        if(lesson) {
            getPublicationList();
        }
    }, [lesson]);

    // getPublicationList
    useEffect(() => {
        if(publication) {
            getPublication();
        }
    }, [publication]);

    const homeworkListClear = index => {
        const list = [...homeworkList];
        list.splice(index, 1);
        setHomeworkList(list);
    }

    const homeworkView = () => {
        setHomeworkResult(null);
        setAddHomework(false);
    }

    const dialogClose = () => {
        setOpenDialog(false);
    }

    const addHomeworkAdd = () => {
        const date = new Date();

        setDefaultDate(date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes());
        setAddHomework(true);
        setHomeworkResult(null);
        setHomeworkList(null);
    }

    const tarihFormat = (value) => {
        const tarihSaat = value.split('T');
        const tarih = tarihSaat[0].split('-');
        const saat = tarihSaat[1].split(':');

        return 'Teslim Tarihi: ' + tarih[2] + '.' + tarih[1] + '.' + tarih[0] + ' ' + saat[0] + ':' + saat[1];
    }

    // Öğretmenin kendi yüklediği testleri listeler
    const getMyTestList = () => {
        setLoading(true);
        axios.get(URL + '/teacherTests/' + user.id, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                if(response.data) {
                    setLoading(false);
                    setMessage(null);
                    response.data.map(item => {
                        let testCount = 0;
                        item.lesson.levels.map(x => {
                            if(x.tests) {
                                testCount += x.tests.length;
                            }
                        });

                        item.lesson.testCount = testCount;
                    });

                    setMyTests(response.data);
                }
                else {
                    setMessage('Sistemde kayıtlı testiniz bulunmamaktadır. Önce test ekranından test oluşturmalısınız.');
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    // seviyeye bağlı dersleri getirir
    const getLessonList = () => {
        setLoading(true);
        axios.get(URL + "/level-lessons?levels=" + level._id + "&seasons=5f37c439982114cd70b9e023", {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                setLoading(false);

                if(response.data) {
                    setLessons(response.data);
                    setMessage(null);
                }
                else {
                    setMessage('Sistemde kayıtlı ' + level.name + ' seviyesine ait ders yoktur');
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    const getLevelList = () => {
        setLoading(true);

        axios.get(URL + "/levels-lean", {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                setLoading(false);

                if(response.data) {
                    setLevels(response.data);
                    setMessage(null);
                }
                else {
                    setMessage('Sistemde yüklü seviye bulunmamaktadır...')
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    const getPublicationList = () => {
        setLoading(true);
        axios.get(URL + "/publications?level=" + level._id + "&lessons=" + lesson._id, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                setLoading(false);
                if(response.data.length > 0) {
                    setMessage(null);
                    const list = [];
                    response.data.map(item => {
                        const index = list.findIndex(x => x._id === item.publisher._id);

                        if(index === -1) {
                            item.publisher.publication = [];
                            list.push(item.publisher)
                        }
                    });

                    response.data.map(item => {
                        const index = list.findIndex(x => x._id === item.publisher._id);
                        let publicationList = [...list[index].publication];
                        publicationList.push(item);
                        list[index].publication = publicationList;
                    });

                    setPublishers(list);
                }
                else {
                    setMessage(level.name + ' ' + lesson.lesson + ' dersine ait herhangi bir içerik bulunmamaktadır.');
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    const getHomework = () => {
        setLoading(true);

        axios.get(URL + '/teacher-homeworks/' + user.id, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                if(response.data) {
                    setLoading(false);
                    if(response.data) {
                        let list = [...response.data.homeworks];
                        list.reverse();
                        setAllHomework(list);
                    }
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    const addTestCenter = item => {
        if(testCenter !== item) {
            setTestCenter(item);
            setMyTests(null);
        }

        setMyTestItem(null);
        setMyTestList(null);
        setLessons(null);
        setPublishers(null);
        setLesson(null);
        setLevel(null);
        setPublisher(null);
        setPublication(null);
        setPublicationDetails(null);
        setPublicationDetails(null)
        setPublicationTests(null);
        setMessage(null);
    }

    const clearAddHomework = () => {
        setMyTests(null);
        setMyTestItem(null);
        setMyTestList(null);
        setLessons(null);
        setPublishers(null);
        setLesson(null);
        setLevel(null);
        setPublisher(null);
        setPublication(null);
        setPublicationDetails(null);
        setPublicationDetails(null)
        setPublicationTests(null);
        setTestCenter(null);
        setHomeworkList(null);
        setMessage(null);
        setAddHomework(false);
    }

    const addHomeworkList = item => {

        if(testCenter === 'me') {
            item.lesson = myTestItem.lesson.name;
            item.level = myTestList.name;
            item._id = item.id;
            item.center = 'Testlerim';
        }
        else if(testCenter === 'publisher') {
            item.lesson = lesson.name;
            item.level = level.name;
            item.id = item._id;
            item.center = publisher.name;
            item.publication = publication.name;
            item.publicationTests = publicationTests.name;
        }

        if(homeworkList) {
            const list = [...homeworkList];
            const index = list.findIndex( x =>  x.id === item.id)

            if(index === -1) {
                list.push(item);
                setHomeworkList(list);
            }
            else {
                window.alert('Bu test ödev listesinizde bulunmaktadır.')
            }
        }
        else {
            setHomeworkList([item]);
        }

        setMyTests(null);
        setMyTestItem(null);
        setMyTestList(null);
        setTestCenter(null);
        setLessons(null);
        setLevels(null);
        setPublishers(null);
        setLesson(null);
        setLevel(null);
        setPublisher(null);
        setPublication(null);
        setPublicationDetails(null);
        setPublicationTests(null);
        setMessage(null);
    }

    const getPublication = () => {
        setLoading(true);
        axios.get(URL + "/publication-tests/" + publication._id, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(response => {
                setLoading(false);
                if(response.data.length > 0) {
                    setPublicationDetails(response.data);
                    setMessage(null)
                }
                else {
                    setMessage(publication.publisher.name + ' ' + publication.name + ' kitabına ait herhangi bir veri bulunmamaktadır.')
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    const save = () => {
        setLoading(true);
        const list = [];
        homeworkList.map(item => {
            list.push(item.id);
        });

        if(homeworkName) {
            axios.post(URL + '/create-homework',
                {
                    name: homeworkName,
                    created_by: user.id,
                    tests: list,
                    deadline: deadline + ':00.000Z'
                },
                {
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                })
                .then(response => {
                    if (response.data) {
                        setLoading(false);
                        setOpenDialog(false);
                        clearAddHomework();
                        getHomework();
                    }
                })
                .catch(err => {
                    console.log(err)
                });
        } else {
            window.alert('olmadı')
        }
    }

    const update = () => {
        setUpdateTest(false);
        setLoading(true);
        const list = [];
        homeworkList.map(item => {
            list.push(item.id);
        });

        if(homeworkName) {
            axios.put(URL + '/homework-items/' + homeworkSelected.id,
                {
                    Name: homeworkName,
                    tests: list,
                    deadline: deadline + ':00.000Z'
                },
                {
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                })
                .then(response => {
                    setLoading(false);
                    setOpenDialog(false);
                    setMyTests(null);
                    setMyTestItem(null);
                    setMyTestList(null);
                    setLessons(null);
                    setPublishers(null);
                    setLesson(null);
                    setLevel(null);
                    setPublisher(null);
                    setPublication(null);
                    setPublicationDetails(null);
                    setPublicationDetails(null)
                    setPublicationTests(null);
                    setTestCenter(null);
                    setMessage(null);
                    setAddHomework(false);

                    if(homeworkList.length > homeworkSelected?.tests.length){
                        const liste = homeworkSelected;

                        homeworkList.map((x,i) => {
                            if(homeworkSelected?.tests.length < (i + 1)) {
                                liste.tests.push({
                                    lesson: {name: x.lesson},
                                    level: {name: x.level},
                                    name: x.name,
                                    publication: {name: publication},
                                    publisher: {name: publisher},
                                    id: x.id,
                                    _id: x.id
                                })
                            }
                        });

                        setHomeworkSelected(liste);
                    }
                    else {
                        const liste = [];

                        homeworkList.map(x => {
                            liste.push({
                                lesson: {name: x.lesson},
                                level: {name: x.level},
                                name: x.name,
                                publication: {name: publication},
                                publisher: {name: publisher},
                                id: x.id,
                                _id: x.id
                            })
                        });
                    
                        const odev = homeworkSelected;
                        odev.tests = liste;

                        setHomeworkSelected(odev);
                    }

                    homeworkDetail(homeworkSelected)
                })
                .catch(err => {
                    console.log(err)
                });
        } else {
            window.alert('olmadı')
        }
    }

    const homeworkDetail = item => {
        setHomeworkSelected(item);
        const testList = [];

        item.tests.map(x => {
            testList.push({
                id: x._id,
                _id: x._id,
                lesson: x.lesson.name,
                level: x.level.name,
                name: x.name,
                center: x?.publisher?.name === 'Öğretmen Yayınevi' ? 'Testlerim' : x?.publisher?.name,
                publication: x?.publisher?.name === 'Öğretmen Yayınevi' ? null : x?.publication?.name,
                publicationTests:  x?.publicationTests?.name
            });
        });

        setHomeworkList(testList);
        getHomeworkDetails(item);
    }

    const getHomeworkDetails = item => {
        axios.get(URL + '/homework-result/' + item._id,
            {
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
            .then(response => {
                if(response.data.message) {
                    // console.log(response.data)
                }
                else if(response.data) {
                    setHomeworkResult(response.data);
                }
            })
            .catch(err => {
                console.log(err.data)
            });
    }
    
    const openUpdateDialog = () => {
        const date = homeworkSelected.deadline?.split(':00.')[0];
        setHomeworkName(homeworkSelected.Name);
        setDeadline(date);
        setDefaultDate(date);
        setOpenDialog(true);
    }

    return (
        <>
        {
            loading &&
            <div className="teachLoading">
                <svg   width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <rect x="17.5" y="30" width="15" height="40" fill="#e15b64">
                        <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="18;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                        <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="64;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                    </rect>
                    <rect x="42.5" y="30" width="15" height="40" fill="#f8b26a">
                        <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="21;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                        <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                    </rect>
                    <rect x="67.5" y="30" width="15" height="40" fill="#abbd81">
                        <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="21;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                        <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                    </rect>
                </svg>
            </div>
        }

        <div className="row">
            <div className="col-md-12">
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">
                                <span className="spanLink">Ödevler </span>
                            </h3>
                        </div>

                        <div className="card-toolbar">
                            <div className="headerToolbar">
                                {
                                    (addHomework || homeworkResult) &&
                                    <span className="headerButton" onClick={homeworkView}>
                                        <span className="svg-icon svg-icon-danger svg-icon-2x">
                                            <svg  width="24px" height="24px" viewBox="0 0 24 24">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                                                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                                                    <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
                                                    <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
                                                    <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
                                                    <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
                                                    <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
                                                    <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
                                                </g>
                                            </svg>
                                        </span>
                                        <span>Ödev Listesi</span>
                                    </span>
                                }

                                <span className="headerButton" onClick={() => addHomeworkAdd()}>
                                    <span className="svg-icon svg-icon-danger svg-icon-2x">
                                        <svg  width="24px" height="24px" viewBox="0 0 24 24">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect fill="#000" x="4" y="11" width="16" height="2" rx="1"/>
                                                <rect fill="#000" transform="translate(12, 12) rotate(-270) translate(-12, -12)" opacity="0.3" x="4" y="11" width="16" height="2" rx="1"/>
                                            </g>
                                        </svg>
                                    </span>
                                    <span>Ödev Ekle</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="publishContainer homework">
                            {
                                addHomework &&
                                <div className="row" style={{width: '100%'}}>
                                    {
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className={homeworkList ? "col-md-12" : "col-md-4"}>
                                                    <div className="card card-custom bg-primary gutter-b"
                                                         style={{minHeight: 150}}>
                                                        <div className="row">
                                                            <div className={homeworkList ? "col-md-4" : "col-md-12"}>
                                                                <div className="card-body testEkle">
                                                                    <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                                                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                                                                            <g stroke="none" strokeWidth="1" fill="none"
                                                                               fillRule="evenodd">
                                                                                <polygon points="0 0 24 0 24 24 0 24"/>
                                                                                <path fill="#000000" opacity="0.3"
                                                                                      d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                                                                                <path fill="#000000" opacity="0.3"
                                                                                      d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                                                                                <path fill="#000000"
                                                                                      d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span className="testNumber"> Ödev Oluştur </span>
                                                                    <div
                                                                        className="text-inverse-primary mt-7 buttonList">
                                                                        <button className="btn btn-light"
                                                                                onClick={() => addTestCenter('me')}>Testlerimden
                                                                        </button>
                                                                        <button className="btn btn-light"
                                                                                onClick={() => addTestCenter('publisher')}>Kitaplardan
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={homeworkList ? "col-md-8" : "noneItem"}>
                                                                <div className="homeworkListTitle">Test Listesi</div>
                                                                {
                                                                    homeworkList &&
                                                                    homeworkList.map((item, n) =>
                                                                        <div className="homeworkList" key={n}>
                                                                            <span className="svg-icon svg-icon-light" onClick={() => homeworkListClear(n)}>
                                                                                <svg width="24px" height="24px"
                                                                                     viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                        <rect x="0" y="0" width="24" height="24"/>
                                                                                        <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fillRule="nonzero"/>
                                                                                        <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                            {item?.center} {'>'} {item?.level} {'>'} {item?.lesson} {'>'} {item?.publication && item?.publication + ' >'}  {item?.publicationTests && item?.publicationTests + ' >'} {item?.name}
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    homeworkList &&
                                                                    <div className="saveButtonContainer">
                                                                        <button className="btn btn-warning" onClick={() => setOpenDialog(true)}>Kaydet</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        message &&
                                        <div className="col-md-12">
                                            <div className="card card-custom bg-danger gutter-b">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="card-body">
                                                            <span className="svg-icon svg-icon-x svg-icon-white ml-n2">
                                                                <svg width="24px" height="24px" viewBox="0 0 24 24">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"/>
                                                                        <path d="M11.5,3 L11.5,3 C16.1944204,3 20,6.80557963 20,11.5 L20,12 L3,12 L3,11.5 C3,6.80557963 6.80557963,3 11.5,3 Z" fill="#000000"/>
                                                                        <path d="M11,12 L13,12 L13,18.75 C13,20.5449254 11.5449254,22 9.75,22 C7.95507456,22 6.5,20.5449254 6.5,18.75 L6.5,18 L8.5,18 L8.5,18.75 C8.5,19.4403559 9.05964406,20 9.75,20 C10.4403559,20 11,19.4403559 11,18.75 L11,12 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span className="messageWhite">{message}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        testCenter === 'me' &&
                                        <div className="col-md-12">
                                            {
                                                // Testlerim
                                                myTests &&
                                                <div className="row">
                                                    <div className="col-md-12"></div>
                                                    {
                                                        myTests?.map(item =>
                                                            <div key={item.lesson.id}
                                                                 className={"col-md-12 " + (myTestItem ? 'noneItem' : '')}>
                                                                <Row name={item.lesson.name} color="danger"
                                                                     desc={item.lesson.testCount + " Test"}
                                                                     click={() => setMyTestItem(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        myTestItem &&
                                                        myTestItem.lesson.levels?.map(item =>
                                                            <div key={item.id}
                                                                 className={"col-md-12 " + (myTestList ? 'noneItem' : '')}>
                                                                <Row name={item.name + " > " + myTestItem.lesson.name}
                                                                     color="info" desc={item.tests.length + " Test"}
                                                                     click={() => setMyTestList(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        myTestList &&
                                                        myTestList.tests.map(item =>
                                                            <div key={item.id} className={"col-md-12 "}>
                                                                <Row
                                                                    name={myTestList.name + " > " + myTestItem.lesson.name + " > " + item.name}
                                                                    color="primary"
                                                                    desc={item.soruSayisi + " Soru | " + item.testSuresi + " sn"}
                                                                    click={() => addHomeworkList(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        testCenter === 'publisher' &&
                                        <div className="col-md-12">
                                            <div className="row">
                                                {
                                                    levels &&
                                                    levels.map(item =>
                                                        <div key={item._id}
                                                             className={"col-md-12 " + (level ? 'noneItem' : 'iBlockItem')}>
                                                            <Row name={item.name} color="primary"
                                                                 click={() => setLevel(item)}/>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    lessons &&
                                                    lessons.map(item =>
                                                        <div key={item._id}
                                                             className={"col-md-12 " + (lesson ? 'noneItem' : 'iBlockItem')}>
                                                            <Row name={level.name + " > " + item.name} color="danger"
                                                                 click={() => setLesson(item)}/>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    publishers &&
                                                    publishers.map(item =>
                                                        <div key={item._id} className={"col-md-12 " + (publisher ? 'noneItem' : 'iBlockItem')}>
                                                            <Row
                                                                name={level.name + " > " + lesson.name + " > " + item.name}
                                                                color="success" click={() => setPublisher(item)}/>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    publisher &&
                                                    publisher.publication.map(item =>
                                                        <div key={item._id} className={"col-md-12 " + (publication ? 'noneItem' : 'iBlockItem')}>
                                                            <Row
                                                                name={level.name + " > " + lesson.name + " > " + publisher.name + " > " + item.name}
                                                                color="dark" click={() => setPublication(item)}/>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    publicationDetails &&
                                                    publicationDetails.map(item =>
                                                        <div key={item._id} className={"col-md-12 " + (publicationTests ? 'noneItem' : 'iBlockItem')}>
                                                            <Row
                                                                name={level.name + " > " + lesson.name + " > " + publisher.name + " > " + publication.name + " > " + item.name}
                                                                color="warning"
                                                                click={() => setPublicationTests(item)}/>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    publicationTests &&
                                                    publicationTests?.tests.map(item =>
                                                        <div key={item._id} className={"col-md-12 "}>
                                                            <Row
                                                                name={level.name + " > " + lesson.name + " > " + publisher.name + " > " + publication.name + " > " + publicationTests.name + " > " + item.name}
                                                                color="primary"
                                                                desc={item.soruSayisi + " Soru | " + item.testSuresi + " sn"}
                                                                click={() => addHomeworkList(item)}/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {
                                homeworkResult &&
                                <div style={{width:'100%'}}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card card-custom bg-primary gutter-b"
                                                 style={{minHeight: 150}}>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="card-body testEkle">
                                                            <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                                                <svg width="24px" height="24px" viewBox="0 0 24 24">
                                                                    <g stroke="none" strokeWidth="1" fill="none"
                                                                       fillRule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24"/>
                                                                        <path fill="#000000" opacity="0.3"
                                                                              d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                                                                        <path fill="#000000" opacity="0.3"
                                                                              d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                                                                        <path fill="#000000"
                                                                              d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span className="testTitle"> {homeworkSelected.Name} <br/> <span className="testCode">{homeworkSelected.Code}</span></span>
                                                            <div className="text-inverse-primary mt-7 buttonList">
                                                                {
                                                                    updateTest &&
                                                                    <>
                                                                        <button className="btn btn-light" onClick={() => addTestCenter('me')}>Testlerimden</button>
                                                                        <button className="btn btn-light" onClick={() => addTestCenter('publisher')}>Kitaplardan</button>
                                                                    </>
                                                                }
                                                                {
                                                                    !updateTest &&
                                                                    <>
                                                                        <button className="btn btn-light" onClick={() => setUpdateTest(true)}>Ödeve Test Ekle</button>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="homeworkListTitle">Test Listesi </div>
                                                        {
                                                            homeworkList &&
                                                            homeworkList.map((item, n) =>
                                                                <div className="homeworkList" key={item._id}>
                                                                    <span className="svg-icon svg-icon-light" onClick={() => homeworkListClear(n)}>
                                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                                <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fillRule="nonzero"/>
                                                                                <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    {item?.center} {'>'} {item?.level} {'>'} {item?.lesson} {'>'} {item?.publication && item?.publication + ' >'}  {item?.publicationTests && item?.publicationTests + ' >'} {item?.name}
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            homeworkList.length !==  homeworkSelected?.tests.length &&
                                                            <div className="saveButtonContainer">
                                                                <button className="btn btn-warning" onClick={() => openUpdateDialog()}>Kaydet</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        {
                                            testCenter === 'me' &&
                                            <div className="col-md-12">
                                                {
                                                    // Testlerim
                                                    myTests &&
                                                    <div className="row">
                                                        <div className="col-md-12"></div>
                                                        {
                                                            myTests?.map(item =>
                                                                <div key={item.lesson.id}
                                                                    className={"col-md-12 " + (myTestItem ? 'noneItem' : '')}>
                                                                    <Row name={item.lesson.name} color="danger"
                                                                        desc={item.lesson.testCount + " Test"}
                                                                        click={() => setMyTestItem(item)}/>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            myTestItem &&
                                                            myTestItem.lesson.levels?.map(item =>
                                                                <div key={item.id}
                                                                    className={"col-md-12 " + (myTestList ? 'noneItem' : '')}>
                                                                    <Row name={item.name + " > " + myTestItem.lesson.name}
                                                                        color="info" desc={item.tests.length + " Test"}
                                                                        click={() => setMyTestList(item)}/>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            myTestList &&
                                                            myTestList.tests.map(item =>
                                                                <div key={item.id} className={"col-md-12 "}>
                                                                    <Row
                                                                        name={myTestList.name + " > " + myTestItem.lesson.name + " > " + item.name}
                                                                        color="primary"
                                                                        desc={item.soruSayisi + " Soru | " + item.testSuresi + " sn"}
                                                                        click={() => addHomeworkList(item)}/>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                }
                                                <div style={{marginBottom: 50}}></div>
                                            </div>
                                        }

                                        {
                                            testCenter === 'publisher' &&
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {
                                                        levels &&
                                                        levels.map(item =>
                                                            <div key={item._id}
                                                                className={"col-md-12 " + (level ? 'noneItem' : 'iBlockItem')}>
                                                                <Row name={item.name} color="primary"
                                                                    click={() => setLevel(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        lessons &&
                                                        lessons.map(item =>
                                                            <div key={item._id}
                                                                className={"col-md-12 " + (lesson ? 'noneItem' : 'iBlockItem')}>
                                                                <Row name={level.name + " > " + item.name} color="danger"
                                                                    click={() => setLesson(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        publishers &&
                                                        publishers.map(item =>
                                                            <div key={item._id} className={"col-md-12 " + (publisher ? 'noneItem' : 'iBlockItem')}>
                                                                <Row
                                                                    name={level.name + " > " + lesson.name + " > " + item.name}
                                                                    color="success" click={() => setPublisher(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        publisher &&
                                                        publisher.publication.map(item =>
                                                            <div key={item._id} className={"col-md-12 " + (publication ? 'noneItem' : 'iBlockItem')}>
                                                                <Row
                                                                    name={level.name + " > " + lesson.name + " > " + publisher.name + " > " + item.name}
                                                                    color="dark" click={() => setPublication(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        publicationDetails &&
                                                        publicationDetails.map(item =>
                                                            <div key={item._id} className={"col-md-12 " + (publicationTests ? 'noneItem' : 'iBlockItem')}>
                                                                <Row
                                                                    name={level.name + " > " + lesson.name + " > " + publisher.name + " > " + publication.name + " > " + item.name}
                                                                    color="warning"
                                                                    click={() => setPublicationTests(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        publicationTests &&
                                                        publicationTests?.tests.map(item =>
                                                            <div key={item._id} className={"col-md-12 "}>
                                                                <Row
                                                                    name={level.name + " > " + lesson.name + " > " + publisher.name + " > " + publication.name + " > " + publicationTests.name + " > " + item.name}
                                                                    color="primary"
                                                                    desc={item.soruSayisi + " Soru | " + item.testSuresi + " sn"}
                                                                    click={() => addHomeworkList(item)}/>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div style={{marginBottom: 50}}></div>
                                            </div>
                                        }

                                        <div className="col-md-4">
                                            <div className="card card-custom bg-warning gutter-b" style={{height: 150}}>
                                                <div className="card-body">
                                                    <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                                      <svg width="24px" height="24px" viewBox="0 0 24 24">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                          <rect x="0" y="0" width="24" height="24"/>
                                                          <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                                                          <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"/>
                                                        </g>
                                                      </svg>
                                                    </span>
                                                    <div className="text-inverse-warning font-weight-bolder font-size-h2 mt-3">{(homeworkResult.averageSuccessRate).toFixed(2)} net</div>
                                                    <div className="text-inverse-warning font-weight-bold font-size-lg mt-1">Ödev Başarı Oranı</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="card card-custom bg-warning gutter-b" style={{height: 150}}>
                                                <div className="card-body">
                                                    <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                                      <svg width="24px" height="24px" viewBox="0 0 24 24">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                          <rect x="0" y="0" width="24" height="24"/>
                                                          <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                                                          <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"/>
                                                        </g>
                                                      </svg>
                                                    </span>
                                                    <div className="text-inverse-warning font-weight-bolder font-size-h2 mt-3">{(homeworkResult.averageTimeRate).toFixed(0)} sn</div>
                                                    <div className="text-inverse-warning font-weight-bold font-size-lg mt-1">Ortalama Ödev Tamamlama Zamanı</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="card card-custom bg-warning gutter-b" style={{height: 150}}>
                                                <div className="card-body">
                                                    <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                                      <svg width="24px" height="24px" viewBox="0 0 24 24">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                          <rect x="0" y="0" width="24" height="24"/>
                                                          <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                                                          <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"/>
                                                        </g>
                                                      </svg>
                                                    </span>
                                                    <div className="text-inverse-warning font-weight-bolder font-size-h2 mt-3">{(homeworkResult.questionCount).toFixed(0)} Soru</div>
                                                    <div className="text-inverse-warning font-weight-bold font-size-lg mt-1">Ödevdeki Toplam Soru Sayısı</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h3 className="titles">Ödevi Yapanlar</h3>
                                    {
                                    homeworkResult.records ?
                                        <Accordion defaultActiveKey="0">
                                        {
                                            homeworkResult.records.map((item, i) =>
                                                <Card key={i}>
                                                    <Accordion.Toggle as={Card.Header} eventKey={'' + i}>Sınıf: {item.class === 'Unspecified' ? 'Belirtilmemiş' : item.class}</Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={'' + i}>
                                                        <Card.Body>
                                                            <div className="homeworkResult">
                                                                <div className="homeworkResultContainer">
                                                                    <div className="homeworkResultHeader">
                                                                        <strong>Öğrenci Adı</strong>
                                                                        <span>Doğru</span>
                                                                        <span>Yanlış</span>
                                                                        <span>Boş</span>
                                                                        <span>Toplam Süre</span>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    item.users.map((student, index) =>
                                                                            <Accordion key={index} defaultActiveKey="test0">
                                                                                <Card>
                                                                                    <Accordion.Toggle as={Card.Header} eventKey={'test' + index}>
                                                                                        
                                                                                        <div className="homeworkResultContainer" >
                                                                                            <div className="homeworkResultRow">
                                                                                                <strong>{student.userName}</strong>
                                                                                                <span>{student.totalResult.correct}</span>
                                                                                                <span>{student.totalResult.incorrect}</span>
                                                                                                <span>{student.totalResult.skipped}</span>
                                                                                                <span>{student.totalResult.spentTime} sn</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Accordion.Toggle>
                                                                                    {
                                                                                        student.results.length > 1 &&
                                                                                        <Accordion.Collapse eventKey={'test' + index}>
                                                                                            <Card.Body>
                                                                                            {
                                                                                            student.results.map((test, j) =>
                                                                                                <div key={j} className="homeworkResultContainer" >
                                                                                                    <div className="homeworkResultRow">
                                                                                                        <strong>{test.test.name}</strong>
                                                                                                        <span>{test.correct}</span>
                                                                                                        <span>{test.incorrect}</span>
                                                                                                        <span>{test.skipped}</span>
                                                                                                        <span>{test.spentTime} sn</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                            </Card.Body>
                                                                                        </Accordion.Collapse>
                                                                                    }
                                                                                </Card>
                                                                            </Accordion>
                                                                    )
                                                                }
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        }
                                        </Accordion>
                                        :
                                        <div>Ödevi yapan öğrenci yoktur...</div>
                                    }
                                </div>
                            }
                            
                            {
                                // Ödev Listesi
                                !homeworkResult &&
                                !addHomework &&
                                allHomework &&
                                allHomework.map((item, i) =>
                                    <div key={i} className="col-md-3" onClick={() => homeworkDetail(item)}>
                                        <div  className="d-flex align-items-center bg-light-danger rounded p-5 pointer mb-8" >
                                            <span className="svg-icon svg-icon-danger mr-5 svg-icon-lg">
                                              <svg width="24px" height="24px" viewBox="0 0 24 24" >
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                  <polygon points="0 0 24 0 24 24 0 24"/>
                                                  <path
                                                      d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                                      fill="#000000" opacity="0.3"/>
                                                  <path
                                                      d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                                      fill="#000000" opacity="0.3"/>
                                                  <path
                                                      d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                                      fill="#000000"/>
                                                </g>
                                              </svg>
                                            </span>
                                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                                <span className="font-weight-bold text-dark-75 text-hover-primary font-size-sm mb-1">{item.Name}</span>
                                                <span className="font-weight-bold text-dark-75 text-hover-primary font-size-sm mb-1 text-muted ">{tarihFormat(item.deadline)}</span>
                                                <span className="text-muted font-weight-bold" style={{justifyContent: "space-between", display: "flex"}}>
                                                    <span>{item.Code}</span>
                                                    <span>{item.tests.length} Test</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={dialogClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle >Ödev Adı</DialogTitle>
            <DialogContent>
                <div className="publishAddContainer">
                    <div className="col-md-12 imgView">
                        <div className="imgBottom mb-5" style={{width: '100%'}}>
                            <div className="inputContainer">
                                <label>Lütfen bir ödev adı giriniz: </label><br/>
                                <input type="text" value={homeworkName} placeholder="Ödevin adı..." onChange={event => setHomeworkName(event.target.value)}/>
                            </div>
                            <div className="inputContainer" style={{textAlign: 'left', paddingTop: 30}}>
                                {
                                    defaultDate &&

                                    <TextField
                                        id="datetime-local"
                                        label="Ödev son teslim tarihini giriniz"
                                        type="datetime-local"
                                        defaultValue={defaultDate}
                                        className={classes.textField}
                                        onChange={(event) => setDeadline(event.target.value)}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />


                                }
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogClose} color="primary">İPTAL</Button>
                {
                    homeworkSelected ?
                        <Button onClick={update} color="primary">KAYDET</Button>
                        :
                        <Button onClick={save} color="primary">KAYDET</Button>
                }
                
            </DialogActions>
        </Dialog>
    </>);
};
