import axios from "axios"
const URL = process.env.REACT_APP_API_URL;

export function getSubjects(token){
    return axios.get(URL + '/all-lesson-level-subjects', {headers: {Authorization: "Bearer " + token }});
}
export function getCustomSubjects(token, level, lesson, season){
    return axios.get(URL + '/level-lesson-subjects/' + level + '/' + lesson + '/' + season, {headers: {Authorization: "Bearer " + token }});
}

export function setSubject(token, data) {
    return axios.post(URL + '/subjects', data, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

export function setCurriculum(token, data) {
    return axios.post(URL + '/import-curriculum', data, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

export function updateSubject(token, id, data) {
    return axios.put(URL + '/subjects/' + id, data, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

export function deleteSubject(token, id) {
    return axios.delete(URL + '/subjects/' + id, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}


