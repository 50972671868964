import React, {useEffect, useState} from "react";
import {PublishContext} from "../../hooks/use-publish";
import {useSeason} from "../../hooks/use-season";
import {useGeneral} from "../../hooks/use-general";
import {Levels} from "./components/Level";
import {Lesson} from "./components/Lesson";
import {Subject} from "./components/Subject";
import {Test} from "./components/Test";
import {Report} from "./components/Report";
import {Publication} from "./components/Publication";

import {getLessonsService, getPublicationsService, updatePublicationActiveService} from "../../services/publish";
import Switch from "@material-ui/core/Switch";

export const Publish = (props) => {
    const {setSeasonID} = useSeason();
    const {setExamPublication, examPublication, setExcelData, setLoading, levels, lessons, setLevelID, levelID, lessonID, setLessonID, publicationID, setPublicationID, setLevelName, setISBN, setLessonName, setPublicationName, levelName, lessonName, publicationName} = useGeneral();
    // konu Belirleme
    const [subjectID, setSubjectID] = useState(null);
    const [subjectList, setSubjectList] = useState([]);
    const [subjectName, setSubjectName] = useState(null);

    // test Belirleme
    const [testList, setTestList] = useState(null);
    const [testID, setTestID] = useState(null);
    const [testName, setTestName] = useState(null);

    const [levelList, setLevelList] = useState([]);
    const [lessonList, setLessonList] = useState([]);
    const [publicationList, setPublicationList] = useState([]);
    const [view, setView] = useState(true);
    const [publication, setPublication] = useState({});
    const editSessionId = value => {
        setSeasonID(value)
    }

    useEffect(() => {
        if (props.location?.publication) {
            const publicationData = props.location.publication;
            let publicationPropsID = null;

            if (publicationData.seasons) {
                editSessionId(publicationData.seasons[0]._id);
            }

            if (publicationData.level) {
                setLevelID(publicationData.level._id);

                setLessonList(null);
                setLoading(true);
                getLessonsService(publicationData.level._id, publicationData.seasons[0]._id, publicationData.publisher._id).then(response => {
                    if (response.data) {
                        setLessonList(response.data);
                    }
                    setLoading(false);
                }).catch(err => {
                    console.log(err);
                    setLoading(false);
                });
            }

            if (publicationData.lessons) {
                if (publicationData.lessons.lenght > 1) {
                    setLessonID('602230e9dafb3f632a2b1011');
                    publicationPropsID = '602230e9dafb3f632a2b1011';
                } else {
                    setLessonID(publicationData.lessons[0]._id);
                    publicationPropsID = publicationData.lessons[0]._id;
                }
                setLoading(true);
                getPublicationsService(publicationData.level._id, publicationPropsID, publicationData.seasons[0]._id, publicationData.publisher._id).then(response => {
                    response.data && setPublicationList(response.data);
                    setLoading(false);
                }).catch(err => {
                    console.log(err);
                    setLoading(false);
                });
            }

            if (publicationData.id) {
                setPublicationID(publicationData._id)
            }
        }
    }, [props])

    // Yayın Grubu adını buluyoruz
    useEffect(() => {
        if (levelList || levelID) {
            const index = levels.findIndex(item => item._id === levelID);
            setLevelName(levels[index]?.name)
        }
    }, [levelList, levelID]);

    // Ders Adını buluyoruz
    useEffect(() => {
        if (lessonList || lessonID) {
            const index = lessons.findIndex(item => item._id === lessonID);
            setLessonName(lessons[index]?.name)
        }
    }, [lessonList, lessonID]);

    // Yayın adını buluyoruz
    useEffect(() => {
        if (publicationList && publicationID) {
            const index = publicationList.findIndex(item => item._id === publicationID);
            setPublicationName(publicationList[index]?.name);
            setPublication(publicationList[index]);
        }
    }, [publicationList, publicationID]);

    // Konu adını buluyoruz
    useEffect(() => {
        if (subjectList && subjectID) {
            const index = subjectList.findIndex(item => item.subjectID === subjectID);
            setSubjectName(subjectList[index]?.subjectName);
            if(examPublication){

            }
            else{
                setTestList(subjectList[index].testler);
            }
        }
    }, [subjectList, subjectID]);

    // Test adını buluyoruz
    useEffect(() => {
        if (testList && testID) {
            const index = testList.findIndex(item => item.testID === testID);
            setTestName(testList[index]?.testAdi);
        }
    }, [testList, testID]);

    const handleChange = () => {
        updatePublicationActiveService({_id: publication._id, isPublished: !publication.isPublished}).then(response => {
            response.data && setPublication(response.data);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    return (<>
        <div className={"row " + (!levelID && "closePublish")}>
            <div className="col-md-12">
                <div role="alert" className="alert alert-white alert-shadow gutter-b">
                    <div className="row">
                        <div className="col-md-10">
                            <div style={{display: 'flex'}}>
                                <div className="alert-icon alert-icon-top">
                            <span className="svg-icon svg-icon-2x svg-icon-primary">
                                <svg width="24px" height="24px" viewBox="0 0 24 24">
                                    <g id="Stockholm-icons-/-Home-/-Library" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24"/>
                                        <path
                                            d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
                                            id="Combined-Shape" fill="#000000"/>
                                        <rect id="Rectangle-Copy-2" fill="#000000" opacity="0.3"
                                              transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) "
                                              x="16.3255682" y="2.94551858" width="3" height="18" rx="1"/>
                                    </g>
                                </svg>
                            </span>
                                </div>
                                <div className="alert-text">
                                    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
                                        <li className="breadcrumb-item cursorPointer" onClick={() => {
                                            setLevelID(null);
                                            setLessonID(null);
                                            setPublicationID(null);
                                            setSubjectID(null);
                                            setTestID(null);
                                            setExcelData(null);
                                            setExamPublication(false);
                                            setPublicationName(null);
                                            console.log("yayınlarım")
                                        }}>
                                            Yayınlarım
                                        </li>
                                        {
                                            levelID &&
                                            <li className="breadcrumb-item cursorPointer" onClick={() => {
                                                setLessonID(null);
                                                setPublicationID(null);
                                                setSubjectID(null);
                                                setTestID(null);
                                                setExcelData(null);
                                                setExamPublication(false);
                                                setPublicationName(null);
                                                console.log("level")
                                            }}>
                                                <span className="text-muted">{levelName}</span>
                                            </li>
                                        }
                                        {
                                            lessonID &&
                                            <li className="breadcrumb-item cursorPointer" onClick={() => {
                                                setPublicationID(null);
                                                setPublicationName(null);
                                                setSubjectID(null);
                                                setTestID(null);
                                                setExcelData(null);
                                                setExamPublication(false);
                                                console.log("lesson")

                                            }}>
                                                <span className="text-muted">{lessonName}</span>
                                            </li>
                                        }
                                        {
                                            (publicationID || publicationName) &&
                                            <li className="breadcrumb-item cursorPointer" onClick={() => {
                                                setSubjectID(null);
                                                setTestID(null);
                                            }}>
                                                <span className="text-muted">{publicationName}</span>
                                            </li>
                                        }
                                        {
                                            subjectID &&
                                            <li className="breadcrumb-item cursorPointer" onClick={() => {
                                                setTestID(null);
                                            }}>
                                                <span className="text-muted">{subjectName}</span>
                                            </li>
                                        }
                                        {
                                            testID &&
                                            <li className="breadcrumb-item cursorPointer">
                                                <span className="text-muted">{testName}</span>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-2">
                            {
                                publicationID &&
                                    <div className="leftControl">
                                        {
                                            publication?.isPublished ?
                                                <>
                                                    Yayından Kaldır
                                                </>
                                                :
                                                <>
                                                    Yayına Al
                                                </>
                                        }
                                        <span>
                                        <Switch
                                            checked={publication?.isPublished}
                                            onChange={handleChange}
                                            value="checkedB"
                                            color="primary"
                                            liste={publication}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </span>
                                    </div>
                            }
                        </div>

                    </div>



                </div>
            </div>


        </div>

        <div className="row">
            <div className="col-md-12">

                <PublishContext.Provider value={{
                    levelList, setLevelList,
                    lessonList, setLessonList,
                    publicationList, setPublicationList,
                    subjectID, setSubjectID, subjectList, setSubjectList, subjectName, setSubjectName,
                    testList, setTestList, testID, setTestID, setTestName, testName,
                    view, setView
                }}>
                    {
                        !levelID && <Levels/>
                    }

                    {
                        (levelID && !lessonID) && <Lesson/>
                    }

                    {
                        (lessonID && !(publicationID || publicationName)) && <Publication/>
                    }

                    {
                        ((publicationID || publicationName) && !subjectID) && <Subject/>
                    }

                    {
                        (subjectID && !testID) && <Test/>
                    }

                    {
                        (testID) && <Report/> 
                    }
                </PublishContext.Provider>
            </div>
        </div>

    </>);
};
