import React, { useEffect, useState } from "react";
import { usePublish } from "../../../hooks/use-publish";
import { useGeneral } from "../../../hooks/use-general";
import { useSeason } from "../../../hooks/use-season";
import { useAuth } from "../../../hooks/use-auth";
import Modal from '@material-ui/core/Modal';
import { getStatisticsOfPublicationService, postExamService, postTestService, getPublicationSubjectsService, getSubjectService, getSubjectListService, updatePublicationTestService, postPublicationsService } from "../../../services/publish";
import XLSX from 'xlsx';
import { SheetJSFT } from '../../../partials/excelTypes';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const Subject = () => {
  const { seasonID } = useSeason();
  const { publisherID } = useAuth();
  const { setSubjectID, subjectList, setSubjectList, lessonList } = usePublish();
  const { setLoading, excelData, setExcelData, publicationID, levelID, lessonID, publicationName, ISBN, lessons, publicationCover, setPublicationID, examPublication} = useGeneral();
  const [modelStatus, setModalStatus] = useState(false);
  const [editModelStatus, setEditModalStatus] = useState(false);
  const [data, setData] = useState( []);
  const [error, setError] = useState('');
  const [dersListe, setDersListe] = useState([]);
  const [alert, setAlert] = useState(false);
  const [fileStatus, setFileStatus] = useState(false);
  const [file, setFile] = useState(false);
  const [loadingLevel, setLoadingLevel] = useState(0);
  const [dataStatus, setDataStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [columnCount, setColumnCount] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [message, setMessage] = useState('');
  const [dekSubjectList, setDekSubjectList] = useState([]);
  const [editPublicationStatus, setEditPublicationStatus] = useState(false);
  const [transporter, setTransporter] = useState(null);
  const [newExcel, setNewExcel] = useState([])

  useEffect(() => {
     let subData;
     getSubjectService(levelID, lessonID, seasonID).then(response => {
      setLoading(false);
      subData = response.data;

      subData.map(x => {
        const index = subData.findIndex(sub => sub.id === x.parentSubjectId);
        if(index > -1) {
          x.newName = subData[index].name + ' > ' + x.name;
        }
        else {
          x.newName = x.name
        }

      });

      subData.sort(function (a, b) {
        return a.newName.localeCompare(b.newName);
      });
      response.data && setDekSubjectList(subData);
      getExcelList(subData);
    }).catch(err => {
      console.log(err)
      setLoading(false);
    });
  }, [excelData]);

  const similarity = (val1, val2) => {
    val1 = val1 && val1.trim().replace(/İ/g, 'i').replace(/I/g, 'ı').toLowerCase();
    val2 = val2 && val2.trim().replace(/İ/g, 'i').replace(/I/g, 'ı').toLowerCase();

    let ratio = 0;

    if(val1 == val2) {
      ratio  = 1;
    }
    else if(val1 && val2){
      const valList1 = [];
      const valList2 = [];

      const valSptlit1 = val1.split(' ');
      valSptlit1.map(item => {
        const itemSplit = item.split('-');
        itemSplit.map(x => valList1.push(x));
      });

      const valSptlit2 = val2.split(' ');
      valSptlit2.map(item => {
        const itemSplit = item.split('-');
        itemSplit.map(x => valList2.push(x));
      });

      let count1 = 0;
      let count2 = 0;

      valList1.map(x => {
        const index = valList2.indexOf(x);

        if(index > -1) {
          count1++;
        }
      });

      valList2.map(x => {
        const index = valList1.indexOf(x);

        if(index > -1) {
          count2++;
        }
      });

      ratio = ((count1 / valList1.length) + (count2 / valList2.length)) / 2;

      if((valList1.length < valList2.length * 0.2 && valList1.length >= valList2.length) || (valList2.length < valList1.length * 0.2 && valList2.length >= valList1.length) ) {
        ratio += 0.1;
      }

    }

    return ratio > 0.7;

  }

  const getExcelList = async (subjects) => {
    if(excelData?.length && !examPublication) {
      setLoading(true);

      setFileStatus(true);
      setDataStatus(true);
      setEditStatus(false);

      let count = 0;
      const excelDt = [];

      excelData.map(x => {
        if (Object.keys(x).length - 3 > count) {
          count = Object.keys(x).length - 3;
        }
      });

      excelData.map( x => x.dersID = lessonID );

      // tekrar eden konu adlarında yenileme
      let isimEkKontrol = 1;
      let prevName = '';
      const isimTekrarKontrol = [];
      let changedName = '';
      let nameStatus = false;

      excelData.map((x,i) => {
        let konuAdi = x["konu adı"].trim();

        if(konuAdi !== prevName) {
          prevName = konuAdi;
          nameStatus = false;
          if(isimTekrarKontrol.indexOf(konuAdi) > -1) {
            isimEkKontrol++;
            konuAdi = konuAdi + ' ' +  isimEkKontrol;
            changedName = konuAdi;
            nameStatus = true;
            isimTekrarKontrol.push(konuAdi);
          }

          isimTekrarKontrol.push(konuAdi);
        }

        if(nameStatus) {
          x["konu adı"] = changedName;
        }
        else {
          x["konu adı"] = konuAdi;
        }
      });

      setLoadingLevel(2);
      let xDataTransfer = {};

      excelData.map((item, i) => {
        const konuAdiKontrol = item["konu adı"].trim().replace(/İ/g, 'i').replace(/I/g, 'ı').toLowerCase();
        const index = excelDt.findIndex(x => x.konuAdi.trim().replace(/İ/g, 'i').replace(/I/g, 'ı').toLowerCase() === konuAdiKontrol);

        if (i > 0 && !item["ders adı"]) {
          item["ders adı"] = xDataTransfer["ders adı"]
        }
        else {
          xDataTransfer = item;
        }
        if (index === -1) {
          const indexSubject = subjects.findIndex(x => similarity(x.name, konuAdiKontrol));

          indexSubject > -1 ?
              excelDt.push({
                konuAdi: item["konu adı"].trim(),
                dersAdi: item["ders adı"]?.trim(),
                dersID: "",
                konuDek: subjects[indexSubject].name,
                konuDekID: subjects[indexSubject].id,
                testler: []
              }) :

              excelDt.push({
                konuAdi: item["konu adı"].trim(),
                dersAdi: item["ders adı"]?.trim(),
                dersID: "",
                konuDek: '',
                konuDekID: '',
                testler: []
              })
        }
      });

      excelDt &&
      excelDt.map(item => {
        const index = dersListe.findIndex(x => x.name.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === item.dersAdi.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase());

        if (index > -1) {
          item.dersID = dersListe[index]._id;
          item.dersAdi = dersListe[index].name.trim();
        }
        else {
          item.dersAdi = lessons[lessons.findIndex(x => x._id.toString() === lessonID.toString())].name;
          item.dersID = lessonID;
        }
      })

      const list = [];
      for (let i = 1; i <= count + 4; i++) {
        list.push(i);
      }

      excelData.map(item => {
        const cevapList = [];
        list.map(x => {
          item[x] &&
          cevapList.push(item[x]);
        });


        if (cevapList.length < list.length) {
          const fark = list.length - cevapList.length;
          for (let n = 0; n < fark; n++) {
            cevapList.push('');
          }
        }

        const index = excelDt.findIndex(x => x.konuAdi.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === item["konu adı"].trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase());

        index > -1 ?
            excelDt[index].testler.push({
              testAdi: item["test adı"].trim(),
              sure: isNaN(item["soru süresi"]) ? "" :item["soru süresi"],
              cevaplar: cevapList
            }) :
            excelDt[index].testler.push({
              testAdi: item["test adı"].trim(),
              sure: isNaN(item["soru süresi"]) ? "" :item["soru süresi"],
              cevaplar: cevapList
            })
      });

      setColumnCount(list);
      setData(excelDt)
      setExcelData([])
    }

    if(excelData?.length && examPublication) {

      let indexOfActive = 0;
      excelData.map((excel,i) =>{
        if(excel.active)
          indexOfActive = i
      })
      setLoading(true);

      if(!editStatus) {
        setFileStatus(true);
        setDataStatus(true);
        setEditStatus(false);
      }
      const excelDt = [];

      for(let i=0; i<excelData.length; i++){
        excelData[i].data.map( x => x.dersID = lessonID );

        setLoadingLevel(2);
        let xDataTransfer = {};
        let exceld = [];
        excelData[i].data.map((item, i) => {

          const konuAdiKontrol = item["konu adı"] ? item["konu adı"].trim().replace(/İ/g, 'i').replace(/I/g, 'ı').toLowerCase() : item.konuAdi ? item.konuAdi.trim().replace(/İ/g, 'i').replace(/I/g, 'ı').toLowerCase() : item.konu.konuAdi.trim().replace(/İ/g, 'i').replace(/I/g, 'ı').toLowerCase()

          if (i > 0 && (!item["ders adı"]) && !editStatus) {
            item["ders adı"] = xDataTransfer["ders adı"]
          }
          else {
            xDataTransfer = item;
          }

          const indexSubject = subjects.findIndex(x => similarity(x.name, konuAdiKontrol));

          if(!editStatus){
            if((item.konuDek && item.konuDekID) || (item.konu?.konuAdi)){
              exceld.push({
                konuAdi: item["konu adı"] ? item["konu adı"].trim() : item.konuAdi ? item.konuAdi : item.konu.konuAdi,
                dersAdi: item["ders adı"] ? item["ders adı"].trim() : item.dersAdi ? item.dersAdi : item.ders.dersAdi,
                dersID: "",
                konuDek: item.konuDek,
                konuDekID: item.konuDekID,
                soruNumarasi: item["soru numarası"] ? isNaN(item["soru numarası"]) ? "" :item["soru numarası"] : item.soruNumarasi,
                soruSuresi:  item["soru süresi (sn)"] ? isNaN(item["soru süresi (sn)"]) ? "" :item["soru süresi (sn)"] : item.soruSuresi,
                cevap: item["cevap"] ? item.cevap.soruCevap ? item.cevap.soruCevap.trim() : item.cevap.trim() : item["cevap"].trim()
              })
            }
            else {
              indexSubject > -1 ?
                  exceld.push({
                    konuAdi: item["konu adı"] ? item["konu adı"].trim() : item.konuAdi ? item.konuAdi : item.konu.konuAdi,
                    dersAdi: item["ders adı"] ? item["ders adı"].trim() : item.dersAdi ? item.dersAdi : item.ders.dersAdi,
                    dersID: "",
                    konuDek: subjects[indexSubject].name,
                    konuDekID: subjects[indexSubject].id,
                    soruNumarasi: item["soru numarası"] ? isNaN(item["soru numarası"]) ? "" : item["soru numarası"] : item.soruNumarasi,
                    soruSuresi: item["soru süresi (sn)"] ? isNaN(item["soru süresi (sn)"]) ? "" : item["soru süresi (sn)"] : item.soruSuresi,
                    cevap: item["cevap"] ? item.cevap.soruCevap ? item.cevap.soruCevap.trim() : item.cevap.trim() : item["cevap"].trim()
                  })
                  :
                  exceld.push({
                    konuAdi: item["konu adı"] ? item["konu adı"].trim() : item.konuAdi,
                    dersAdi: item["ders adı"] ? item["ders adı"].trim() : item.dersAdi,
                    dersID: "",
                    konuDek: '',
                    konuDekID: '',
                    soruNumarasi: item["soru numarası"] ? isNaN(item["soru numarası"]) ? "" : item["soru numarası"] : item.soruNumarasi,
                    soruSuresi: item["soru süresi (sn)"] ? isNaN(item["soru süresi (sn)"]) ? "" : item["soru süresi (sn)"] : item.soruSuresi,
                    cevap: item["cevap"] ? (item.cevap.soruCevap ? item.cevap.soruCevap.trim() : item.cevap.trim() ) : item["cevap"].trim()
                  })
            }
          }
          else{
              exceld.push({
                konu: item.konu,
                ders:item.ders,
                konuDek: item.konuDek,
                soruNumarasi: item.soruNumarasi,
                sure:  item.sure,
                cevap: item.cevap,
                soruID: item.soruID
              })
          }

        });

        exceld &&
        exceld.map(item => {
          const index = dersListe.findIndex(x => x.name.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === (item.ders ? item.ders.dersAdi.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() : item.dersAdi.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase()));

          if (index > -1) {
            if(item.ders){
              item.ders.dersID = dersListe[index]._id;
              item.ders.dersAdi = dersListe[index].name.trim();
            }
            else{
              item.dersID = dersListe[index]._id;
              item.dersAdi = dersListe[index].name.trim();
            }
          }
          else {
            if(item.ders){
              item.ders.dersAdi = lessons[lessons.findIndex(x => x._id.toString() === lessonID.toString())].name;
              item.ders.dersID = lessonID;
            }
            else{
              item.dersAdi = lessons[lessons.findIndex(x => x._id.toString() === lessonID.toString())].name;
              item.dersID = lessonID;
            }
          }
        })

        excelDt.push({...excelData[i], data:exceld});
      }
      setLoading(false);
      setNewExcel(excelDt);
      setData(excelDt[indexOfActive].data);
    }

    if(!excelData || excelData.length <= 0){
      setLoading(true);
      getStatisticsOfPublicationService(publicationID).then(response => {
        let list = [];
        if(response.data.length){
          for(let i = 0; i < response.data.length; i++){
            if(examPublication){
              list.push({
                subjectName: response.data[i].publication_subject[0].name,
                subjectID: response.data[i].publication_subject[0]._id,
                questionCount: response.data[i].questions,
              })
            }
            else{
              list.push({
                subjectName: response.data[i].publication_subject[0].name,
                subjectID: response.data[i].publication_subject[0]._id,
                questionCount: response.data[i].questions.length,
                testCount: response.data[i].tests.length
              })
            }
          }
        }
        setSubjectList(list);
      })
          .catch((err)=>{
            console.log(err)})
          .finally(()=>setLoading(false));
      setFileStatus(false);
      setDataStatus(false);
      setEditStatus(false);
    }
  }

  const getSubject = (pID) => {
    setLoading(true);
    let pubID = pID ? pID : publicationID;
    setEditStatus(false);
    setDataStatus(false);
    getStatisticsOfPublicationService(pubID).then(response => {
        let list = [];
        if(response.data.length){
          for(let i = 0; i < response.data.length; i++){
            if(examPublication){
              list.push({
                subjectName: response.data[i].publication_subject[0].name,
                subjectID: response.data[i].publication_subject[0]._id,
                questionCount: response.data[i].questions,
              })
            }
            else{
              list.push({
                subjectName: response.data[i].publication_subject[0].name,
                subjectID: response.data[i].publication_subject[0]._id,
                questionCount: response.data[i].questions.length,
                testCount: response.data[i].tests.length
              })
            }
          }
        }
      setSubjectList(list);
    })
        .catch(err => {
          console.log(err);
        })
        .finally(()=>setLoading(false));
  }

  const handleOpen = () => {
    setModalStatus(true);
  };

  const handleClose = () => {
    setModalStatus(false);
  };

  const editModelOpen = (index, i, j, text) => {
    setEditModalStatus(true);
    setSelectedItem({ index, i, j, text });
  };

  const editModelClose = () => {
    setEditModalStatus(false);
  };

  const editPublicationClose = () => {
    setEditPublicationStatus(false);
  };

  const editPublicationOpen = (index, i, j, text) => {
    setEditPublicationStatus(true);
    setSelectedItem({ index, i, j, text });
  };

  const publicationChange = event => {
    let liste = [...data];
    if(publicationID){
      if (selectedItem.text === "cevaplar") {
        let cevap = (event.target.value).toUpperCase();
        if (['A', 'B', 'C', 'D', 'E', 'F', 'T', ''].indexOf(cevap) > -1) {
          liste[selectedItem.index].testler[selectedItem.i].cevaplar[selectedItem.j].cevap = cevap;
          liste[selectedItem.index].testler[selectedItem.i].cevaplar[selectedItem.j].status = true;
        }
      }
      else if (selectedItem.text === "testAdi") {
        liste[selectedItem.index].testler[selectedItem.i].testAdi = event.target.value.trim();
        liste[selectedItem.index].testler[selectedItem.i].status = true;
      }
      else if (selectedItem.text === "sure") {
        liste[selectedItem.index].testler[selectedItem.i].sure = event.target.value;
        liste[selectedItem.index].testler[selectedItem.i].sureStatus = true;
      }
      else if (selectedItem.text === "konuAdi") {

        liste[selectedItem.index].konu.konuAdi = event.target.value.trim();
        liste[selectedItem.index].konu.status = true;

      }
      else if (selectedItem.text === "konuDek") {
        const index = dekSubjectList.findIndex(x => x.id === event.target.value);
        liste[selectedItem.index].konuDek.konuDekAdi = dekSubjectList[index].name
        liste[selectedItem.index].konuDek.konuDekID = event.target.value;
        liste[selectedItem.index].konuDek.status = true;
      }
      else if (selectedItem.text === "dersAdi") {
        const index = dersListe.findIndex(x => x.name === event.target.value);

        if (liste[selectedItem.index].ders.dersID !== dersListe[index]._id) {
          liste[selectedItem.index].ders.dersID = dersListe[index]._id;
          liste[selectedItem.index].ders.dersAdi = event.target.value.trim();
          liste[selectedItem.index].konuDek.konuDekAdi = null
          liste[selectedItem.index].konuDek.konuDekID = null
          liste[selectedItem.index].ders.status = true;
        }
      }
      setData(liste);
    }
    else{
      if (selectedItem.text === "cevaplar") {
        let cevap = (event.target.value).toUpperCase();
        if (['A', 'B', 'C', 'D', 'E', 'F', 'T', ''].indexOf(cevap) > -1) {
          liste[selectedItem.index].testler[selectedItem.i].cevaplar[selectedItem.j].cevap = cevap;
          liste[selectedItem.index].testler[selectedItem.i].cevaplar[selectedItem.j].status = true;
        }
      }
      else if (selectedItem.text === "testAdi") {
        liste[selectedItem.index].testler[selectedItem.i].testAdi = event.target.value.trim();
        liste[selectedItem.index].testler[selectedItem.i].status = true;
      }
      else if (selectedItem.text === "sure") {
        liste[selectedItem.index].soruSuresi = event.target.value;
      }
      else if (selectedItem.text === "konuAdi") {
        liste[selectedItem.index].konuAdi = event.target.value.trim();
      }
      else if (selectedItem.text === "konuDek") {
        const index = dekSubjectList.findIndex(x => x.id === event.target.value);
        liste[selectedItem.index].konuDekAdi = dekSubjectList[index].name
        liste[selectedItem.index].konuDekID = event.target.value;
      }
      else if (selectedItem.text === "dersAdi") {
        const index = dersListe.findIndex(x => x.name === event.target.value);

        if (liste[selectedItem.index].dersID !== dersListe[index]._id) {
          liste[selectedItem.index].dersID = dersListe[index]._id;
          liste[selectedItem.index].dersAdi = event.target.value.trim();
          liste[selectedItem.index].konuDekAdi = null
          liste[selectedItem.index].konuDekID = null
        }
      }
      setData(liste);
    }
  };

  const fileProcess = () => {
    setDataStatus(true);
    setEditStatus(false);
    setModalStatus(false);
  }

  const cancelModal = () => {
    setModalStatus(false);
    setFile(null);
    setData([]);
    setFileStatus(false);
    setLoadingLevel(0);
    setError('');
  }

  const handleChange = event => {
    setLoadingLevel(1);
    setError('');
    const files = event.target.files;
    const fileName = event.target.files[0].name;
    setFile(files[0])
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const xData = XLSX.utils.sheet_to_json(ws);
     
      setFileStatus(true);

      let count = 0;
      const excelData = [];

      xData.map(x => {
        if (Object.keys(x).length - 3 > count) {
          count = Object.keys(x).length - 3;
        }
      });

      // tekrar eden konu adlarında yenileme
      let isimEkKontrol = 1;
      let prevName = '';
      const isimTekrarKontrol = [];
      let changedName = '';
      let nameStatus = false;
      xData.map((x,i) => {
        let konuAdi = x["konu adı"].trim();

        if(konuAdi !== prevName) {
          prevName = konuAdi;
          nameStatus = false;
          if(isimTekrarKontrol.indexOf(konuAdi) > -1) {
            isimEkKontrol++;
            konuAdi = konuAdi + ' ' +  isimEkKontrol;
            changedName = konuAdi;
            nameStatus = true;
            isimTekrarKontrol.push(konuAdi);
          }

          isimTekrarKontrol.push(konuAdi);
        }

        if(nameStatus) {
          x["konu adı"] = changedName;
        }
        else {
          x["konu adı"] = konuAdi;
        }
      });
      const strings = fileName.split(".");
      if(strings[0].toString() === ISBN) {
        setLoadingLevel(2);
        let xDataTransfer = {};
        xData.map((item, i) => {
          const index = excelData.findIndex(x => x.konuAdi.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === item["konu adı"].trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase());
  
          if (i > 0 && !item["ders adı"]) {
            item["ders adı"] = xDataTransfer["ders adı"];
          }
          else {
            xDataTransfer = item;
          }

          if (index === -1) {
            const indexSubject = dekSubjectList.findIndex(x => similarity(x.name, item["konu adı"]));

            indexSubject > -1 ?
              excelData.push({
                konuAdi: item["konu adı"].trim(),
                dersAdi: item["ders adı"]?.trim(),
                dersID: "",
                konuDek: dekSubjectList[indexSubject].name,
                konuDekID: dekSubjectList[indexSubject].id,
                testler: []
              }) :
  
              excelData.push({
                konuAdi: item["konu adı"].trim(),
                dersAdi: item["ders adı"]?.trim(),
                dersID: "",
                konuDek: '',
                konuDekID: '',
                testler: []
              })
          }
        });
  
        excelData &&
          excelData.map(item => {
            const index = dersListe.findIndex(x => x.name.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === item.dersAdi?.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase());
  
            if (index > -1) {
              item.dersID = dersListe[index]._id;
              item.dersAdi = dersListe[index].name;
            }
            else {
              item.dersAdi = lessonList[lessonList.findIndex(x => x._id.toString() === lessonID.toString())].name;
              item.dersID = lessonID;
            }
          })
  
        const list = [];
        for (let i = 1; i <= count + 4; i++) {
          list.push(i);
        }
  
        xData.map(item => {
          const cevapList = [];
          list.map(x => {
            item[x] &&
              cevapList.push(item[x]);
          });
  
  
          if (cevapList.length < list.length) {
            const fark = list.length - cevapList.length;
            for (let n = 0; n < fark; n++) {
              cevapList.push('');
            }
          }
  
          const index = excelData.findIndex(x => x.konuAdi.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === item["konu adı"].trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase());
          index > -1 ?
            excelData[index].testler.push({
              testAdi: item["test adı"].trim(),
              sure: isNaN(item["soru süresi"]) ? "" :item["soru süresi"],
              cevaplar: cevapList
            }) :
            excelData[index].testler.push({
              testAdi: item["test adı"].trim(),
              sure: isNaN(item["soru süresi"]) ? "" :item["soru süresi"],
              cevaplar: cevapList
            })
        });
  
        setColumnCount(list);
        setData(excelData);
        setExcelData([]);
      }
      else {
        setFileStatus(false);
        setLoadingLevel(0);
        setError('Yüklemeye çalıştığınız kitap ile içerisinde bulunduğunuz kitap aynı kitap değil. Lütfen kontrol ederek yeniden deneyiniz.');
      }
    }
    if (rABS) {
      reader.readAsBinaryString(files[0]);
    } else {
      reader.readAsArrayBuffer(files[0]);
    };
  }

  const modalChange = event => {
    let liste = [...data];
    if(examPublication) {
      if (selectedItem.text === "sure") {
        if(liste[selectedItem.index].sure.soruSuresi){
          liste[selectedItem.index].sure.soruSuresi = event.target.value;
          liste[selectedItem.index].sure.status = true
        }
        else
          liste[selectedItem.index]['sure'] = event.target.value
      } else if (selectedItem.text === "konuAdi") {
        if(liste[selectedItem.index].konu.konuAdi){
          liste[selectedItem.index].konu.konuAdi = event.target.value;
          liste[selectedItem.index].konu.status = true
        }
        else
          liste[selectedItem.index]['konuAdi'] = event.target.value.trim()
      } else if (selectedItem.text === "konuDek") {
        const index = dekSubjectList.findIndex(x => x.id === event.target.value);
        setTransporter({
          index: selectedItem.index,
          value: event.target.value,
          name: dekSubjectList[index].name,
          id: liste[selectedItem.index]['konuDekID'] ? liste[selectedItem.index]['konuDekID'] : liste[selectedItem.index].konuDek.konuDekID
        })
      } else if (selectedItem.text === "dersAdi") {
        const index = dersListe.findIndex(x => x.name.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === event.target.value.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase());
        if(liste[selectedItem.index].ders){
          liste[selectedItem.index].ders.dersID = dersListe[index]._id;
          liste[selectedItem.index].ders.dersAdi = event.target.value.trim();
          liste[selectedItem.index].ders.status = true;
        }
        else{
          liste[selectedItem.index]['dersID'] = dersListe[index]._id;
          liste[selectedItem.index]['dersAdi'] = event.target.value.trim();
        }
      }
    }
    else {
      if (selectedItem.text === "cevaplar") {
        let cevap = (event.target.value).toUpperCase();
        if (['A', 'B', 'C', 'D', 'E', 'F', 'T', ''].indexOf(cevap) > -1) {
          liste[selectedItem.index].testler[selectedItem.i].cevaplar[selectedItem.j] = cevap;
        }
      } else if (selectedItem.text === "testAdi") {
        liste[selectedItem.index].testler[selectedItem.i]['testAdi'] = event.target.value.trim()
      } else if (selectedItem.text === "sure") {
        liste[selectedItem.index].testler[selectedItem.i]['sure'] = event.target.value
      } else if (selectedItem.text === "konuAdi") {
        liste[selectedItem.index]['konuAdi'] = event.target.value.trim()
      } else if (selectedItem.text === "konuDek") {
        const index = dekSubjectList.findIndex(x => x.id === event.target.value);
        setTransporter({
          index: selectedItem.index,
          value: event.target.value,
          name: dekSubjectList[index].name,
          id: liste[selectedItem.index]['konuDekID']
        })
      } else if (selectedItem.text === "dersAdi") {
        const index = dersListe.findIndex(x => x.name.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase() === event.target.value.trim().replace(/İ/g, 'i').replace(/İ/g, 'ı').toLowerCase());
        liste[selectedItem.index]['dersID'] = dersListe[index]._id
        liste[selectedItem.index]['dersAdi'] = event.target.value.trim()
      }
    }
    setData(liste);
  };

  const okay = () => {
    let liste = [...data];
    if(selectedItem.text === "konuDek"){
      if(liste[transporter.index].konuDek.konuDekAdi){
        liste[transporter.index].konuDek.konuDekAdi = transporter.name
        liste[transporter.index].konuDek.konuDekID =transporter.value;
        liste[transporter.index].konuDek.status = true;
      }
      else{
        liste[transporter.index]['konuDek'] = transporter.name
        liste[transporter.index]['konuDekID'] =transporter.value;
      }
    }
    setData(liste);
    setTransporter(null);
    editModelClose();
  }

  const alertOpen = () => {
    setAlert(true);
  };

  const alertClose = () => {
    setAlert(false);
  };

  //exam için bakılmalı
  const save = () => {
    let count = 0;
    if(examPublication){
      newExcel.map((excel)=>{
        excel.data.map(item => {
          if (!item.konuDekID) {
            count++;
          }
          else if (!item.konuAdi) {
            count++;
          }
          else if (!item.dersAdi) {
            count++;
          }
          else if(!item.soruNumarasi) {
            count++;
          }
          else if(!item.soruSuresi) {
            count++;
          }
          else if(!item.cevap) {
            count++;
          }
        });
      })
    }
    else {
      data.map(item => {
        if (!item.konuDekID) {
          count++;
        }
        else if (!item.konuAdi) {
          count++;
        }
        else if (!item.dersAdi) {
          count++;
        }

        item.testler.map(test => {
          if (!test.testAdi) {
            count++;
          }
          else if (!test.sure) {
            count++;
          }

          if (!test.cevaplar[0]) {
            count++;
          }

          test.cevaplar.map((cevap, sira) => {
            if (!cevap) {

              if (test.cevaplar.length >= sira + 1) {
                for (let m = sira + 1; m < test.cevaplar.length; m++) {
                  if (test.cevaplar[m]) {
                    count++;
                  }
                }
              }
            }
          });
        });
        if (count === 0) {
          item.testler.map(test => {
            const cevapListesi = [];
            test.cevaplar.map(cevap => {
              if (cevap) {
                cevapListesi.push(cevap)
              }
            });
            test.cevaplar = cevapListesi;
          });
        }
      });
    }
    if (count > 0) {
      setMessage('Lütfen eksiksiz doldurun. Kırmızı ile gösterilen alanlara tıklayarak verilerini girebilirsiniz...')
      alertOpen();
    }
    else {
      setLoading(true);
      const formData = new FormData();
      let postData = JSON.stringify({
        name: publicationName,
        ISBN: ISBN.toString(),
        season: {_id: seasonID},
        isPublished: false,
        level: {_id: levelID},
        lesson: {_id: lessonID},
        publisher: {_id: publisherID}
      });

      formData.append('files.cover', publicationCover, publicationCover.name);
      formData.append('data', postData);
      setLoading(true);

      postPublicationsService(formData)
          .then((res) => {
            setModalStatus(false);
            setPublicationID(res.data._id);
            const list = {
              publicationID: res.data._id,
              seasonID,
              levelID,
              list: data
            }
            postTestService(list, publisherID).then(response => {
              setDataStatus(false);
              setEditStatus(false);
              setEditPublicationStatus(false);
              setMessage('Kitaba ait testler sisteme kaydedilmiştir.')
              alertOpen();
              getSubject();
              setLoading(false);
            })
                .catch(error => {
                  console.log(error);
                  setLoading(false);
                })
          })
          .catch(err => {
            setError('Bir hata oluştu. Lütfen tekrar deneyiniz');
          })
          .finally(()=>setLoading(false));

    }
  }

  const getSubjectList = () => {
    setLoading(true);
    getSubjectListService(publicationID)
      .then(response => {
        setEditStatus(true);
        setDataStatus(false);
        if(examPublication){
          setExcelData(response.data.list);
          setData(response.data.list[0].data);
        }
        else {
          let count = 0;

          response.data.list.map(item => {
            item.testler.map(test => {
              if (count < test.cevaplar.length) {
                count = test.cevaplar.length;
              }
            });
          });

          const list = [];

          let lastCount = count + 4;

          if (count < 28) {
            lastCount = 28;
          }

          for (let i = 1; i <= lastCount; i++) {
            list.push(i);
          }

          response.data.list.map(item => {
            item.testler.map(test => {
              let testCount = test.cevaplar.length;
              let testListesi = test.cevaplar;

              if (testCount < lastCount) {
                const fark = lastCount - testCount;

                for (let m = 0; m < fark; m++) {
                  testListesi.push({
                    id: '',
                    status: false,
                    cevap: ''
                  })
                }

                test.cevaplar = testListesi;
              }
            });
          });

          setColumnCount(list)
          setData(response.data.list);
        }
      })
      .catch(error =>
        console.log(error)
      )
        .finally(()=>{setLoading(false)});
  }

  const edit = () => {
    let count = 0;
    if(examPublication){
      if(editStatus){
        newExcel.map((excel)=>{
          excel.data.map(item => {
            if (!item.konuDek.konuDekID) {
              count++;
            }
            else if (!item.konu.konuAdi) {
              count++;
            }
            else if (!item.ders.dersAdi) {
              count++;
            }
            else if(!item.soruNumarasi) {
              count++;
            }
            else if(!item.sure.soruSuresi) {
              count++;
            }
            else if(!item.cevap.soruCevap) {
              count++;
            }
          });
        })
      }
      else{
        newExcel.map((excel)=>{
          excel.data.map(item => {
            if (!item.konuDekID) {
              count++;
            }
            else if (!item.konuAdi) {
              count++;
            }
            else if (!item.dersAdi) {
              count++;
            }
            else if(!item.soruNumarasi) {
              count++;
            }
            else if(!item.soruSuresi) {
              count++;
            }
            else if(!item.cevap) {
              count++;
            }
          });
        })
      }
    }
    else {
      data.map(item => {
        if (!item.konuDek.konuDekID) {
          count++;
        } else if (!item.konu.konuAdi) {
          count++;
        } else if (!item.ders.dersAdi) {
          count++;
        }

        item.testler.map(test => {
          if (!test.testAdi) {
            count++;
          } else if (!test.sure) {
            count++;
          }

          test.cevaplar.map((x, sira) => {
            if (!x.cevap) {
              if (test.cevaplar.length >= sira + 1) {
                for (let m = sira + 1; m < test.cevaplar.length; m++) {
                  if (test.cevaplar[m].cevap) {
                    count++;
                  }
                }
              }
            }
          });
        });
      });
    }

    if (count > 0) {
      setMessage('Lütfen eksiksiz doldurun. Kırmızı ile gösterilen alanlara tıklayarak verilerini girebilirsiniz...')
      alertOpen();
    }
    else {
      if(publicationID){
        if(!examPublication) {
          const list = {
            publicationID,
            seasonID,
            levelID,
            list: newExcel
          }
          setLoading(true);
          updatePublicationTestService(list).then(response => {
            setLoading(false);
            setDataStatus(false);
            setEditStatus(false);
            setEditPublicationStatus(false);
            setMessage('Kitaba ait testler sisteme kaydedilmiştir.')
            alertOpen();
            getSubject();
          }).catch(error => {
            setLoading(false);
          })
        }
        else{
          const list = {
            publicationID,
            seasonID,
            levelID,
            list: newExcel
          }
          setLoading(true);
          updatePublicationTestService(list).then(response => {
            setLoading(false);
            setDataStatus(false);
            setEditStatus(false);
            setEditPublicationStatus(false);
            setMessage('Kitaba ait sorular sisteme kaydedilmiştir.')
            alertOpen();
            getSubject();
          }).catch(error => {
            setLoading(false);
          })
        }
      }
      else {
        if(examPublication){
          setLoading(true);
          const formData = new FormData();
          let postData = JSON.stringify({
            name: publicationName,
            ISBN: ISBN.toString(),
            season: {_id: seasonID},
            isPublished: false,
            isExamPublication: true,
            level: {_id: levelID},
            lesson: {_id: lessonID},
            publisher: {_id: publisherID}
          });

          formData.append('files.cover', publicationCover, publicationCover.name);
          formData.append('data', postData);

          postPublicationsService(formData)
              .then((res) => {
                setModalStatus(false);
                setPublicationID(res.data._id);
                const list = {
                  publicationID: res.data._id,
                  seasonID,
                  levelID,
                  list: newExcel
                }
                postExamService(list, publisherID).then(response => {
                  setDataStatus(false);
                  setEditStatus(false);
                  setEditPublicationStatus(false);
                  setMessage('Kitaba ait denemeler sisteme kaydedilmiştir.')
                  alertOpen();
                  getSubject(res.data._id);
                })
                    .catch(error => {
                      console.log(error);
                    })
              })
              .catch(err => {
                setError('Bir hata oluştu. Lütfen tekrar deneyiniz');
              })
              .finally(()=>setLoading(false));
        }
        else{
          setLoading(true);
          const formData = new FormData();
          let postData = JSON.stringify({
            name: publicationName,
            ISBN: ISBN.toString(),
            season: {_id: seasonID},
            isPublished: false,
            level: {_id: levelID},
            lesson: {_id: lessonID},
            publisher: {_id: publisherID}
          });

          formData.append('files.cover', publicationCover, publicationCover.name);
          formData.append('data', postData);

          postPublicationsService(formData)
              .then((res) => {
                setModalStatus(false);
                setPublicationID(res.data._id);
                const list = {
                  publicationID: res.data._id,
                  seasonID,
                  levelID,
                  list: data
                }
                postTestService(list, publisherID).then(response => {
                  setDataStatus(false);
                  setEditStatus(false);
                  setEditPublicationStatus(false);
                  setMessage('Kitaba ait testler sisteme kaydedilmiştir.')
                  alertOpen();
                  getSubject();
                  setLoading(false);
                })
                    .catch(error => {
                      console.log(error);
                      setLoading(false);
                    })
              })
              .catch(err => {
                setError('Bir hata oluştu. Lütfen tekrar deneyiniz');
              })
              .finally(()=>setLoading(false));

        }
      }
    }
  }

  const anserChange = (value, index, i, j) => {
    let liste = [...data];
    let length = value.length;
    let cevap = value.toUpperCase();

    if (length > 1) {
      cevap = cevap.substring(1, 2);
    }

    if (['A', 'B', 'C', 'D', 'E', 'F', 'T', ''].indexOf(cevap) > -1) {
      liste[index].testler[i].cevaplar[j] = cevap;
      setData(liste);
    }
  }

  const timeChange = (e, index, i) => {
    const value = e.target.value;
    if(examPublication){
      if(!isNaN(value)) {
        let liste = [...data];
        if(liste[index].soruSuresi || liste[index].soruSuresi === "") {
          liste[index].soruSuresi = value;
        }
        else if(liste[index].sure.soruSuresi || liste[index].sure.soruSuresi === ""){
          liste[index].sure.soruSuresi = value;
          liste[index].sure.status = true;
        }
        setData(liste);
      }
    }
    else{
      if(!isNaN(value)) {
        let liste = [...data];
        liste[index].testler[i].sure = value;
        setData(liste);
    } }
  }

  const subjectChangeEdit = (value, index, i, j) => {
    let liste = [...data];
    if(examPublication){
      if(liste[index].konu?.konuAdi || liste[index].konu?.konuAdi === ""){
        liste[index].konu.konuAdi = value.trim();
        liste[index].konu.status = true;
      }
      else{
        liste[index].konuAdi = value.trim();
      }
      setData(liste);
    }
  }


  const answerChangeEdit = (value, index, i, j) => {
    let liste = [...data];
    let length = value.length;
    let cevap = value.toUpperCase();

    if (length > 1) {
      cevap = cevap.substring(1, 2);
    }

    if (['A', 'B', 'C', 'D', 'E', 'F', 'T', ''].indexOf(cevap) > -1) {
      if (examPublication) {
        if (editStatus){
          liste[index].cevap.soruCevap = cevap[0] ? cevap[0] : "";
          liste[index].cevap.status = true;
        } else {
          liste[index].cevap = cevap[0];
        }
        setData(liste);
      }
      else {
        liste[index].testler[i].cevaplar[j].cevap = cevap;
        liste[index].testler[i].cevaplar[j].status = true;
        setData(liste);
      }
    }

  }

  const addSubjectRow = () => {
    if(examPublication){
      if(publicationID){
        const list = [...data];
        list.push({
          soruNumarasi: data[data.length - 1].soruNumarasi + 1,
          sure: {
            status: false,
            soruSuresi: ""
          },
          cevap: {
            status: false,
            soruCevap: ""
          },
          dersAdi: data[data.length - 1].dersAdi,
          dersID: lessonID,
          konuDek: {
            status: false,
            konuDekAdi: "",
            konuDekID: ""
          },
          konu: {
            status: false,
            konuAdi: "",
            konuID: ""
          }
        })
        setData(list);
      }
      else {
        const list = [...data];
        list.push({
          konuAdi: "",
          dersAdi: data[data.length - 1].dersAdi,
          dersID: lessonID,
          konuDek: '',
          konuDekID: '',
          soruNumarasi: data[data.length - 1].soruNumarasi + 1,
          soruSuresi: "",
          cevap: "",
        })
        setData(list);
      }
    }
    else{
      const list = [...data];
      const testList = [];
      columnCount.map(item => {
        testList.push({
          cevap: '',
          status: false
        });
      });

      list.push({
        ders: {
          dersID: null,
          dersAdi: null,
          status: false
        },
        konu: {
          konuAdi: '',
          status: false
        },
        konuDek: {
          konuDekAdi: '',
          konuDekID: '',
          status: false
        },
        testler: [
          {
            cevaplar: testList,
            status: false,
            sure: '',
            sureStatus: null,
            testAdi: "",
            testID: ""
          }
        ]
      });

      setData(list);
    }

  }

  const addAnswer = () => {
    const list = [...data];

    list.map(konu => {
      konu.testler.map(test => {
        test.cevaplar.push({
          cevap: '',
          status: false
        });
      });
    });

    setData(list);
    const addColumn = columnCount[columnCount.length - 1] + 1;
    const column = [...columnCount, addColumn]
    setColumnCount(column);

  }

  const addTest = (index) => {
    const list = [...data];

    const testList = [];
    columnCount.map(item => {
      testList.push({
        cevap: '',
        status: false
      });
    });

    list[index].testler.push({
      cevaplar: testList,
      status: false,
      sure: "",
      sureStatus: null,
      testAdi: "",
      testID: ""
    });

    setData(list);
  }

  const setActiveSheet = (index) => {
    let e = [...newExcel];
    e.map((excel)=>{
      if(excel.active){
        excel.active = false
        excel.data = data;
      }
    })
    e[index].active = true;
    setExcelData(e);
  }

  return (<>
    {
      examPublication ?
          <>
            {
              editStatus ?
                  <div className="examBreadCrumb">
                    <div className={"card card-custom gutter-b "}>
                      <div className="card-header">
                        <div className="card-title">
                          <h3 className="card-label">{publicationName} Kitabının Cevap Anahtarı</h3>
                        </div>
                        <div className="card-toolbar">
                          <div className="headerToolbar">
                            <span className="btn font-weight-bolder font-size-sm btn-success" onClick={edit}> Kaydet  </span>
                          </div>
                        </div>
                      </div>
                      <div className="headerBreadCrumb">
                        {
                          excelData &&
                          excelData.map((item, index) =>
                              <div className="sheet" style={{fontWeight: item.active && "bolder", color: !item.active && "gray"}}
                                   onClick={() => setActiveSheet(index)}>
                                {item.examName}
                              </div>
                          )
                        }
                      </div>
                      <div className="card-body">

                        <div className="excelContainer">
                          <div className="excelTable">
                            <div className="excelHeader" >
                              {
                                lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" &&
                                <strong className="testAdi">Ders Adı</strong>

                              }
                              <strong className="konuAdi">Kitaptaki Konu Adı</strong>
                              <strong className="konuAdi">Müfredat Konu Adı</strong>
                              <strong className="soruNumarasi">Soru Numarası</strong>
                              <strong className="testSure">Süre(sn)</strong>
                              <strong className="cevap">Cevap</strong>

                            </div>
                            <div className="excelBody" >
                              {
                                data &&
                                data.map((item, index) =>
                                    <div key={index} className="excelRow">
                                      {
                                        lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" &&
                                        <span className={"testAdi" + (item.ders.dersAdi ? '' : ' hata')} onClick={() => { editModelOpen(index, 'i', 'j', 'dersAdi'); }}>{item.ders.dersAdi}</span>
                                      }
                                      <input className={"konuAdi" + (item.konu?.konuAdi ? '' : ' hata')} value={item.konu.konuAdi} onChange={e => subjectChangeEdit(e.target.value, index, 'i', 'j')} />
                                      <span className={"konuAdi" + (item.konuDek.konuDekAdi? '' : ' hata')} onClick={() => { editModelOpen(index, 'i', 'j', 'konuDek'); }}>{item.konuDek.konuDekAdi}</span>
                                      <span className={"soruNumarasi" + (item.soruNumarasi ? '' : ' hata')}>{item.soruNumarasi}</span>
                                      <input className={"testSure" + (item.sure.soruSuresi ? '' : ' hata')} value={item.sure.soruSuresi} onChange={e => {timeChange(e, index, 'i')}} />
                                      <input className={"cevap" + (item.cevap?.soruCevap ? '' : ' hata')} value={item.cevap?.soruCevap?.toUpperCase()} onChange={e => answerChangeEdit(e.target.value, index, 'i', 'j')} />
                                    </div>
                                )
                              }

                              <div className="newRowContainer">
                                <button className="newRowButton" onClick={addSubjectRow}><i className="fa fa-plus" /> Soru Ekle</button>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  dataStatus ?
                      <div className="examBreadCrumb">
                        <div className="headerBreadCrumb">
                          {
                            excelData.map((item, index) =>
                                <div className="sheet" style={{backgroundColor: item.active && "blue"}}
                                     onClick={() => setActiveSheet(index)}>
                                  {item.examName}
                                </div>
                            )
                          }
                        </div>
                        <div className={"card card-custom gutter-b "}>
                          <div className="card-header">
                            <div className="card-title">
                              <h3 className="card-label">{publicationName} Kitabının Cevap Anahtarı</h3>
                            </div>
                            <div className="card-toolbar">
                              <div className="headerToolbar">
                                <span className="btn font-weight-bolder font-size-sm btn-success" onClick={edit}> Kaydet  </span>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">

                            <div className="excelContainer">
                              <div className="excelTable">
                                <div className="excelHeader" >
                                  {
                                    lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" &&
                                    <strong className="testAdi">Ders Adı</strong>

                                  }
                                  <strong className="konuAdi">Kitaptaki Konu Adı</strong>
                                  <strong className="konuAdi">Müfredat Konu Adı</strong>
                                  <strong className="soruNumarasi">Soru Numarası</strong>
                                  <strong className="testSure">Süre(sn)</strong>
                                  <strong className="cevap">Cevap</strong>

                                </div>
                                <div className="excelBody" >
                                  {
                                    data &&
                                    data.map((item, index) =>
                                        <div key={index} className="excelRow">
                                          {
                                            lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" &&
                                            <span className={"testAdi" + (item.dersAdi ? '' : ' hata')} onClick={() => { editModelOpen(index, 'i', 'j', 'dersAdi'); }}>{item.dersAdi}</span>
                                          }
                                          <input className={"konuAdi" + (item?.konuAdi ? '' : ' hata')} value={item.konuAdi} onChange={e => subjectChangeEdit(e.target.value, index, 'i', 'j')} />
                                          <span className={"konuAdi" + (item?.konuDek || item.konuDek.konuDekAdi? '' : ' hata')} onClick={() => { editModelOpen(index, 'i', 'j', 'konuDek'); }}>{item.konuDek}</span>
                                          <span className={"soruNumarasi" + (item?.soruNumarasi ? '' : ' hata')}>{item.soruNumarasi}</span>
                                          {
                                            item.soruSuresi ?
                                                <input className={"testSure"} value={item.soruSuresi} onChange={e => {timeChange(e, index, 'i')}} />
                                                :
                                                item.sure?.soruSuresi ?
                                                  <input className={"testSure"} value={item.sure.soruSuresi} onChange={e => {timeChange(e, index, 'i')}} />
                                                  :
                                                  <input className="testSure hata" value={item.soruSuresi} onChange={e => {timeChange(e, index, 'i')}} />
                                          }
                                          {
                                            item.cevap?.soruCevap ?
                                                <input className={"cevap"} value={item.cevap.soruCevap} onChange={e => answerChangeEdit(e.target.value, index, 'i', 'j')} />
                                                :
                                                item.cevap ?
                                                    <input className={"cevap"} value={item.cevap} onChange={e => answerChangeEdit(e.target.value, index, 'i', 'j')} />
                                                    :
                                                    <input className={"cevap hata"} value={item.cevap} onChange={e => answerChangeEdit(e.target.value, index, 'i', 'j')} />
                                          }
                                        </div>
                                    )
                                  }

                                  <div className="newRowContainer">
                                    <button className="newRowButton" onClick={addSubjectRow}><i className="fa fa-plus" /> Soru Ekle</button>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      :
                      <>
                        <div className={"card card-custom gutter-b "}>
                          <div className="card-header">
                            <div className="card-title">
                              <h3 className="card-label">{publicationName} Konular</h3>
                            </div>
                            <div className="card-toolbar">
                              <div className="headerToolbar">
                              <span className="headerButton mr-4" style={{ padding: 5 }}>
                                <span className="svg-icon svg-icon-2x svg-icon-success" style={{ margin: 0 }}>
                                  <svg viewBox="0 0 30 30">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <path fill="#434440" d="m26.72499,8.27149l-4.9773,-5.77206l-0.98434,-1.14293l-11.75922,0c-1.57859,0 -2.8555,1.27873 -2.8555,2.85823l0,3.63203l1.76999,0l-0.00091,-2.93662c0.00182,-0.88864 0.71638,-1.60776 1.60229,-1.60776l10.05668,-0.00911l0,4.76494c0.00182,1.77455 1.4355,3.21095 3.21187,3.21095l3.48074,0l-0.17135,13.74522c-0.00273,0.88408 -0.72003,1.59773 -1.6032,1.60502l-15.10233,-0.00729c-0.80844,0 -1.45919,-0.79294 -1.46284,-1.76999l0,-1.16389l-1.77272,0l0,1.73536c0,1.74265 1.16845,3.16083 2.60303,3.16083l16.25164,-0.00365c1.57859,0 2.85914,-1.28511 2.85914,-2.86097l0,-16.10764l-1.14566,-1.33068" />
                                      <path fill="#08743B" d="m13.68177,22.05013l-13.18177,0l0,-12.58927l13.18177,0l0,12.58927m-11.91528,-1.26714l10.64619,0l0,-10.05499l-10.64619,0" />
                                      <polyline points="10.753141403198242,19.07782554626465 8.533692359924316,19.07782554626465 7.01885986328125,16.937246322631836 5.428417682647705,19.07782554626465 3.198538303375244,19.07782554626465 5.964865684509277,15.599708557128906 3.73107647895813,12.556357383728027 5.983116626739502,12.556357383728027 7.028637409210205,14.210678100585938 8.143250465393066,12.556357383728027 10.435053825378418,12.556357383728027 8.093060493469238,15.599708557128906 10.753141403198242,19.07782554626465 " fill="#08743B" />
                                      <path transform="rotate(-180 20.01045799255371,19.192615509033203)" d="m15.76046,19.17799l4.25,-6.07875l4.25,6.07875l-2.125,0l0,6.108l-4.25,0l0,-6.108l-2.125,0z" fill="#000" />
                                    </g>
                                  </svg>
                                </span>
                                <span className="indirButton"><a href={ lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" ? "/excel/karisikderssablon.xlsx" :"/excel/sablon.xlsx" }  download>Şablon İndir</a></span>
                              </span>

                                <span className="headerButton" onClick={handleOpen} style={{ padding: 5 }}>
                                <span className="svg-icon svg-icon-2x svg-icon-success" style={{ margin: 0 }}>
                                  <svg viewBox="0 0 30 30">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <path fill="#434440" d="m26.72499,8.27149l-4.9773,-5.77206l-0.98434,-1.14293l-11.75922,0c-1.57859,0 -2.8555,1.27873 -2.8555,2.85823l0,3.63203l1.76999,0l-0.00091,-2.93662c0.00182,-0.88864 0.71638,-1.60776 1.60229,-1.60776l10.05668,-0.00911l0,4.76494c0.00182,1.77455 1.4355,3.21095 3.21187,3.21095l3.48074,0l-0.17135,13.74522c-0.00273,0.88408 -0.72003,1.59773 -1.6032,1.60502l-15.10233,-0.00729c-0.80844,0 -1.45919,-0.79294 -1.46284,-1.76999l0,-1.16389l-1.77272,0l0,1.73536c0,1.74265 1.16845,3.16083 2.60303,3.16083l16.25164,-0.00365c1.57859,0 2.85914,-1.28511 2.85914,-2.86097l0,-16.10764l-1.14566,-1.33068" />
                                      <path fill="#08743B" d="m13.68177,22.05013l-13.18177,0l0,-12.58927l13.18177,0l0,12.58927m-11.91528,-1.26714l10.64619,0l0,-10.05499l-10.64619,0" />
                                      <polyline points="10.753141403198242,19.07782554626465 8.533692359924316,19.07782554626465 7.01885986328125,16.937246322631836 5.428417682647705,19.07782554626465 3.198538303375244,19.07782554626465 5.964865684509277,15.599708557128906 3.73107647895813,12.556357383728027 5.983116626739502,12.556357383728027 7.028637409210205,14.210678100585938 8.143250465393066,12.556357383728027 10.435053825378418,12.556357383728027 8.093060493469238,15.599708557128906 10.753141403198242,19.07782554626465 " fill="#08743B" />
                                      <path d="m15.76046,19.17799l4.25,-6.07875l4.25,6.07875l-2.125,0l0,6.108l-4.25,0l0,-6.108l-2.125,0z" fill="#000" />
                                    </g>
                                  </svg>
                                </span>
                                <span style={{ marginLeft: 2 }}>Excel Aktarım</span>
                              </span>

                                <span className="headerButton" onClick={getSubjectList} style={{ padding: 5 }}>
                                <span className="svg-icon svg-icon-2x svg-icon-success" style={{ margin: 0 }}>
                                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                                </span>
                                <span style={{ marginLeft: 2 }}>Düzenle</span>
                              </span>
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            <div className="tab-content">
                              <div className="table-responsive">
                                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                  <thead>
                                  <tr className="text-left text-uppercase">
                                    <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Konu Adı</span></th>
                                    <th style={{ minWidth: "100px" }}>Soru Sayısı</th>
                                    <th style={{ minWidth: "80px" }} />
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {
                                    subjectList &&
                                    subjectList.map(item =>
                                        <tr key={item.subjectID} className="tableTR">
                                          <td>
                                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.subjectName}</span>
                                          </td>
                                          <td>
                                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.questionCount}  <span className="tableSpan">Soru</span></span>
                                          </td>
                                          <td className="pr-0 text-right">
                                                <span className="btn btn-light-success font-weight-bolder font-size-sm btn-sm" onClick={() => setSubjectID(item.subjectID)} >
                                                  Detaylı İncele
                                                </span>
                                          </td>
                                        </tr>
                                    )
                                  }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
            }
          </>
          :
          <>
            {
              editStatus ?
                  <div className={"card card-custom gutter-b "}>
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">{publicationName} Kitabının Cevap Anahtarı</h3>
                      </div>
                      <div className="card-toolbar">
                        <div className="headerToolbar">
                          <span className="btn font-weight-bolder font-size-sm btn-success" onClick={edit}> Kaydet  </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">

                      <div className="excelContainer">
                        <div className="excelTable">
                          <div className="excelHeader" >
                            {
                              lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" &&
                              <strong className="testAdi">Ders Adı</strong>
                            }
                            <strong className="konuAdi">Kitaptaki Konu Adı</strong>
                            <strong className="konuAdi">Müfredat Konu Adı</strong>
                            <strong className="testAdi">Test Adı</strong>
                            <strong className="testSure">Süre</strong>
                            {
                              columnCount?.map(x => <strong className="testCevap" key={x}>{x}</strong>)
                            }
                            <strong className="testCevap"><i className='fa fa-plus' style={{ color: '#0180b5' }} /></strong>
                          </div>
                          <div className="excelBody" >
                            {
                              data &&
                              data.map((item, index) =>
                                  <div key={index} className="excelRow">
                                    {
                                      lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" &&
                                      <span className={"testAdi" + (!item.ders?.dersAdi ? ' hata' : '')} onClick={() => { editPublicationOpen(index, 'i', 'j', 'dersAdi'); }}>{item.ders?.dersAdi}</span>
                                    }
                                    <span className={"konuAdi" + (!item.konu?.konuAdi ? ' hata' : '')} onClick={() => { editPublicationOpen(index, 'i', 'j', 'konuAdi'); }}>{item.konu?.konuAdi}</span>
                                    <span className={"konuAdi" + (!item.konuDek?.konuDekID ? ' hata' : '')} onClick={() => { editPublicationOpen(index, 'i', 'j', 'konuDek'); }}>{item.konuDek?.konuDekAdi}</span>
                                    <div style={{ position: 'relative' }}>
                                      {
                                        item.testler.map((test, i) =>
                                            <div className="excelTest" key={index + " - " + i}>
                                              <span className={"testAdi" + (!test.testAdi ? ' hata' : '')} onClick={() => { editPublicationOpen(index, i, 'j', 'testAdi'); }}>{test.testAdi}</span>
                                              <span className={"testSure" + (!test.sure ? ' hata' : '')} onClick={() => { editPublicationOpen(index, i, 'j', 'sure'); }}>{test.sure}</span>
                                              {
                                                test.cevaplar.map((x, j) =>
                                                    <input className={"testCevap" + (!x.cevap ? ' hata' : '')} key={index + " - " + i + " - " + j} value={x.cevap} onChange={e => answerChangeEdit(e.target.value, index, i, j)} />
                                                )
                                              }
                                              <span className="testCevap" onClick={addAnswer}><i className='fa fa-plus' /></span>
                                            </div>
                                        )}

                                      <div className="testAdd" onClick={() => addTest(index)}><em className='testAddText'>Test Ekle</em> <i className='fa fa-plus testPlus' /></div>
                                    </div>
                                  </div>
                              )
                            }

                            <div className="newRowContainer">
                              <button className="newRowButton" onClick={addSubjectRow}><i className="fa fa-plus" /> Konu Ekle</button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  dataStatus ?
                      <div className={"card card-custom gutter-b "}>
                        <div className="card-header">
                          <div className="card-title">
                            <h3 className="card-label">{publicationName} Kitabının Cevap Anahtarı</h3>
                          </div>
                          <div className="card-toolbar">
                            <div className="headerToolbar">
                  <span className="btn font-weight-bolder font-size-sm btn-success" onClick={save}>
                    Kaydet
              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="excelContainer">
                            <div className="excelTable">
                              <div className="excelHeader">
                                {
                                  lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" &&
                                    <strong className="testAdi">Ders Adı</strong>
                                }
                                <strong className="konuAdi">Kitaptaki Konu Adı</strong>
                                <strong className="konuAdi">Müfredat Konu Adı</strong>
                                <div style={{display: 'flex'}}> <div className="excelTest">
                                  <strong className="testAdi">Test Adı</strong>
                                  <strong className="testSure">Süre</strong>
                                  {
                                    columnCount.map(x =>
                                        <strong className="testCevap" key={x}>{x}</strong>
                                    )
                                  }
                                </div></div>
                              </div>
                              <div className="excelBody">
                                {
                                  data.map((item, index) =>
                                      <div key={index} className="excelRow">
                                        {
                                          lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" &&
                                          <span className={"testAdi" + (!item.dersAdi ? ' hata' : '')} onClick={() => {editModelOpen(index, 'i', 'j', 'dersAdi');}}>{item.dersAdi}</span>
                                        }
                                        <span className={"konuAdi" + (!item.konuAdi ? ' hata' : '')} onClick={() => { editModelOpen(index, 'i', 'j', 'konuAdi'); }}>{item.konuAdi}</span>
                                        <span className={"konuAdi" + (!item.konuDekID ? ' hata' : '')} onClick={() => { editModelOpen(index, 'i', 'j', 'konuDek'); }}>{item.konuDek}</span>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                          {
                                            item.testler.map((test, i) =>
                                                <div className="excelTest" key={index + " - " + i}>
                                                  <span className={"testAdi" + (!test.testAdi ? ' hata' : '')} onClick={() => { editModelOpen(index, i, 'j', 'testAdi'); }}>{test.testAdi}</span>
                                                  <input className={"testSure" + (!test.sure ? ' hata' : '')}
                                                         value={test.sure}
                                                         onChange={e => timeChange(e.target.value, index, i)} />


                                                  {
                                                    // <span className={"testSure" + (!test.sure ? ' hata' : '')} onClick={() => { editModelOpen(index, i, 'j', 'sure'); }}>{test.sure}</span>
                                                  }

                                                  {
                                                    test.cevaplar.map((x, j) =>
                                                        <input className={"testCevap" + (!x ? ' hata' : '')}
                                                               key={index + " - " + i + " - " + j}
                                                               value={x}
                                                               onChange={e => anserChange(e.target.value, index, i, j)} />
                                                    )
                                                  }
                                                </div>
                                            )}
                                        </div>
                                      </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className={"card card-custom gutter-b "}>
                        <div className="card-header">
                          <div className="card-title">
                            <h3 className="card-label">{publicationName} Konular</h3>
                          </div>
                          <div className="card-toolbar">
                            <div className="headerToolbar">
                              <span className="headerButton mr-4" style={{ padding: 5 }}>
                                <span className="svg-icon svg-icon-2x svg-icon-success" style={{ margin: 0 }}>
                                  <svg viewBox="0 0 30 30">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <path fill="#434440" d="m26.72499,8.27149l-4.9773,-5.77206l-0.98434,-1.14293l-11.75922,0c-1.57859,0 -2.8555,1.27873 -2.8555,2.85823l0,3.63203l1.76999,0l-0.00091,-2.93662c0.00182,-0.88864 0.71638,-1.60776 1.60229,-1.60776l10.05668,-0.00911l0,4.76494c0.00182,1.77455 1.4355,3.21095 3.21187,3.21095l3.48074,0l-0.17135,13.74522c-0.00273,0.88408 -0.72003,1.59773 -1.6032,1.60502l-15.10233,-0.00729c-0.80844,0 -1.45919,-0.79294 -1.46284,-1.76999l0,-1.16389l-1.77272,0l0,1.73536c0,1.74265 1.16845,3.16083 2.60303,3.16083l16.25164,-0.00365c1.57859,0 2.85914,-1.28511 2.85914,-2.86097l0,-16.10764l-1.14566,-1.33068" />
                                      <path fill="#08743B" d="m13.68177,22.05013l-13.18177,0l0,-12.58927l13.18177,0l0,12.58927m-11.91528,-1.26714l10.64619,0l0,-10.05499l-10.64619,0" />
                                      <polyline points="10.753141403198242,19.07782554626465 8.533692359924316,19.07782554626465 7.01885986328125,16.937246322631836 5.428417682647705,19.07782554626465 3.198538303375244,19.07782554626465 5.964865684509277,15.599708557128906 3.73107647895813,12.556357383728027 5.983116626739502,12.556357383728027 7.028637409210205,14.210678100585938 8.143250465393066,12.556357383728027 10.435053825378418,12.556357383728027 8.093060493469238,15.599708557128906 10.753141403198242,19.07782554626465 " fill="#08743B" />
                                      <path transform="rotate(-180 20.01045799255371,19.192615509033203)" d="m15.76046,19.17799l4.25,-6.07875l4.25,6.07875l-2.125,0l0,6.108l-4.25,0l0,-6.108l-2.125,0z" fill="#000" />
                                    </g>
                                  </svg>
                                </span>
                                <span className="indirButton"><a href={ lessonID && lessonID.toString() === "607d83859bb0823f3ca65542" ? "/excel/karisikderssablon.xlsx" :"/excel/sablon.xlsx" }  download>Şablon İndir</a></span>
                              </span>

                              <span className="headerButton" onClick={handleOpen} style={{ padding: 5 }}>
                                <span className="svg-icon svg-icon-2x svg-icon-success" style={{ margin: 0 }}>
                                  <svg viewBox="0 0 30 30">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <path fill="#434440" d="m26.72499,8.27149l-4.9773,-5.77206l-0.98434,-1.14293l-11.75922,0c-1.57859,0 -2.8555,1.27873 -2.8555,2.85823l0,3.63203l1.76999,0l-0.00091,-2.93662c0.00182,-0.88864 0.71638,-1.60776 1.60229,-1.60776l10.05668,-0.00911l0,4.76494c0.00182,1.77455 1.4355,3.21095 3.21187,3.21095l3.48074,0l-0.17135,13.74522c-0.00273,0.88408 -0.72003,1.59773 -1.6032,1.60502l-15.10233,-0.00729c-0.80844,0 -1.45919,-0.79294 -1.46284,-1.76999l0,-1.16389l-1.77272,0l0,1.73536c0,1.74265 1.16845,3.16083 2.60303,3.16083l16.25164,-0.00365c1.57859,0 2.85914,-1.28511 2.85914,-2.86097l0,-16.10764l-1.14566,-1.33068" />
                                      <path fill="#08743B" d="m13.68177,22.05013l-13.18177,0l0,-12.58927l13.18177,0l0,12.58927m-11.91528,-1.26714l10.64619,0l0,-10.05499l-10.64619,0" />
                                      <polyline points="10.753141403198242,19.07782554626465 8.533692359924316,19.07782554626465 7.01885986328125,16.937246322631836 5.428417682647705,19.07782554626465 3.198538303375244,19.07782554626465 5.964865684509277,15.599708557128906 3.73107647895813,12.556357383728027 5.983116626739502,12.556357383728027 7.028637409210205,14.210678100585938 8.143250465393066,12.556357383728027 10.435053825378418,12.556357383728027 8.093060493469238,15.599708557128906 10.753141403198242,19.07782554626465 " fill="#08743B" />
                                      <path d="m15.76046,19.17799l4.25,-6.07875l4.25,6.07875l-2.125,0l0,6.108l-4.25,0l0,-6.108l-2.125,0z" fill="#000" />
                                    </g>
                                  </svg>
                                </span>
                                <span style={{ marginLeft: 2 }}>Excel Aktarım</span>
                              </span>

                              <span className="headerButton" onClick={getSubjectList} style={{ padding: 5 }}>
                                <span className="svg-icon svg-icon-2x svg-icon-success" style={{ margin: 0 }}>
                                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                                </span>
                                <span style={{ marginLeft: 2 }}>Düzenle</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="tab-content">
                            <div className="table-responsive">
                              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                <thead>
                                <tr className="text-left text-uppercase">
                                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Konu Adı</span></th>
                                  <th style={{ minWidth: "100px" }}>Test Sayısı</th>
                                  <th style={{ minWidth: "100px" }}>Soru Sayısı</th>
                                  <th style={{ minWidth: "80px" }} />
                                </tr>
                                </thead>
                                <tbody>
                                {
                                  subjectList &&
                                  subjectList.map(item =>
                                          <tr key={item.subjectID} className="tableTR">
                                            <td>
                                              <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.subjectName}</span>
                                            </td>
                                            <td>
                                              <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.testCount} <span className="tableSpan">Test</span></span>
                                            </td>
                                            <td>
                                              <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.questionCount}  <span className="tableSpan">Soru</span></span>
                                            </td>
                                            <td className="pr-0 text-right">
                                                <span className="btn btn-light-success font-weight-bolder font-size-sm btn-sm" onClick={() => setSubjectID(item.subjectID)} >
                                                  Detaylı İncele
                                                </span>
                                            </td>
                                          </tr>
                                  )
                                }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
            }
          </>
    }

    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={alert}
      onClose={alertClose}
    >
      <div className="modelAlert">
        <div className="alertTitle">Uyarı</div>
        <div className="alertBody">{message}</div>
        <div className="alertFooter">
          <span className="btn font-weight-bolder font-size-sm btn-success" onClick={() => setAlert(false)}> Tamam </span>
        </div>
      </div>
    </Modal>

    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={editPublicationStatus}
      onClose={editPublicationClose}>
      <div className="basicModal">
        <div className="modalHeader">
          <div className="modalTitle">Cevap Anahtarı Düzenle</div>
          <div className="modalToolbar">
            <span className="closeButton" onClick={editPublicationClose}>
              <i className="flaticon2-cancel-music" style={{ fontSize: 14 }} />
            </span>
          </div>
        </div>
        <div className="modalBody">
          <div className="row">
            <div className="col-md-12">
              <div className="modelContainer">
                {
                  <div className="modelLabel">
                    { selectedItem?.text === 'konuDek' && 'Müfredattaki Konu Adını Seç'}
                    { selectedItem?.text === 'dersAdi' && 'Ders Adını Seç'}
                    { selectedItem?.text === 'konuAdi' && 'Konu Adı Düzenle'}
                    { selectedItem?.text === 'testAdi' && 'Test Adı Düzenle'}
                    { selectedItem?.text === 'sure' && 'Her Soru İçin Süre Düzenle'}
                    { selectedItem?.text === 'cevaplar' && 'Cevap Düzenle'}
                  </div>
                }

                {
                  selectedItem?.text === 'konuAdi' &&
                  <input className="modelInput" type="text" value={data[selectedItem.index]?.konu?.konuAdi} onChange={() => publicationChange('event')}/>
                }
                {
                  selectedItem?.text === 'testAdi' &&
                  <input className="modelInput" type="text" value={data[selectedItem.index].testler[selectedItem.i].testAdi} onChange={() => publicationChange('event')}/>
                }
                {
                  selectedItem?.text === 'sure' && !examPublication ?
                    <input className="modelInput" type="text" value={data[selectedItem.index].testler[selectedItem.i].sure} onChange={() => publicationChange('event')}/>
                    :
                    selectedItem?.text === 'sure' && examPublication && <input className="modelInput" type="text" value={data[selectedItem.index].sure} onChange={() => publicationChange('event')}/>
                }
                {
                  selectedItem?.text === 'cevaplar' &&
                      <input className="modelInput" type="text" value={data[selectedItem.index].testler[selectedItem.i].cevaplar[selectedItem.j].cevap} onChange={() => publicationChange('event')}/>
                }

                {
                  selectedItem?.text === 'konuDek' &&
                  <select className="modelInput" type="text"
                          value={data[selectedItem.index]?.konuDek.konuDekAdi || ''}
                          onChange={publicationChange}>
                    {
                      data[selectedItem.index]?.konuDek.konuDekID ?
                          <option value={data[selectedItem.index]?.konuDek.konuDekID}>{data[selectedItem.index]?.konuDek.konuDekAdi}</option>
                          :
                          <option value="">Seçiniz</option>
                    }

                    {
                      dekSubjectList.map((x, i) =>
                          <option key={i} value={x.id}>{x.name}</option>
                      )
                    }
                  </select>
                }
                {
                  selectedItem?.text === 'dersAdi' &&
                    <select className="modelInput" type="text" value={data[selectedItem.index]?.dersID} onChange={() => publicationChange('event')} >
                      {
                        data[selectedItem.index]?.ders ?
                          <option value={data[selectedItem.index]?.ders.dersID}>{data[selectedItem.index]?.ders.dersAdi}</option>
                          :
                          <option value="">Seçiniz</option>
                      }

                      {
                        dersListe.map((x, i) =>
                          <option key={i} value={x.id}>{x.name}</option>
                        )
                      }
                    </select>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="modalFooter" >
          <span className="btn font-weight-bolder font-size-sm btn-success" onClick={editPublicationClose}>
            Tamam
            </span>
        </div>

      </div>
    </Modal>

    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={editModelStatus}
      onClose={editModelClose}>
      <div className="basicModal">
        <div className="modalHeader">
          <div className="modalTitle">Cevap Anahtarı Düzenle</div>
          <div className="modalToolbar">
            <span className="closeButton" onClick={editModelClose}>
              <i className="flaticon2-cancel-music" style={{ fontSize: 14 }} />
            </span>
          </div>
        </div>
        <div className="modalBody">
          <div className="row">
            <div className="col-md-12">
              <div className="modelContainer">

                {
                  selectedItem?.text === 'konuAdi' ?
                      <input className="modelInput" type="text" value={data[selectedItem.index].konuAdi ? data[selectedItem.index].konuAdi : data[selectedItem.index].konu.konuAdi } onChange={modalChange} /> :
                      selectedItem?.text === 'testAdi' ?
                          <input className="modelInput" type="text" value={data[selectedItem.index].testler[selectedItem.i].testAdi} onChange={modalChange} /> :
                          selectedItem?.text === 'sure' ?
                              !examPublication ? <input className="modelInput" type="text" value={data[selectedItem.index].testler[selectedItem.i].sure} onChange={modalChange} /> : <input className="modelInput" type="text" value={data[selectedItem.index].sure} onChange={modalChange} /> :
                              selectedItem?.text === 'cevaplar' ?
                                  <input className="modelInput" type="text" value={data[selectedItem.index].testler[selectedItem.i].cevaplar[selectedItem.j]} onChange={modalChange} /> :
                                  selectedItem?.text === 'konuDek' ?
                                      <select className="modelInput" type="text" value={transporter?.value} onChange={modalChange}>
                                        {
                                          data[selectedItem.index]?.konuDekID ?
                                              <option value={data[selectedItem.index]?.konuDekID}>{data[selectedItem.index]?.konuDek}</option>
                                              :
                                              <option value="">Seçiniz</option>
                                        }
                                        {
                                          dekSubjectList.map((x, i) =>
                                              <option key={i} value={x.id}>{x.newName}</option>
                                          )
                                        }
                                      </select> :
                                      selectedItem?.text === 'dersAdi' &&
                                      <select className="modelInput" type="text" value={data[selectedItem.index]?.dersAdi} onChange={modalChange} >
                                        {
                                          data[selectedItem.index]?.konuDekID ?
                                              <option value={data[selectedItem.index]?.dersAdi}>{data[selectedItem.index]?.dersAdi}</option>
                                              :
                                              <option value="">Seçiniz</option>
                                        }

                                        {
                                          dersListe.map((x, i) =>
                                              <option key={i} value={x.id}>{x.name}</option>
                                          )
                                        }
                                      </select>
                }
              </div>

            </div>
          </div>
        </div>
        <div className="modalFooter" >
          <span className="btn font-weight-bolder font-size-sm btn-success" onClick={okay}>Kaydet</span>
        </div>
      </div>
    </Modal>

    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modelStatus}
      onClose={handleClose}>
      <div className="basicModal">
        <div className="modalHeader">
          <div className="modalTitle">Cevap Anahtarı Ekle</div>
          <div className="modalToolbar">
            <span className="closeButton" onClick={handleClose}>
              <i className="flaticon2-cancel-music" style={{ fontSize: 14 }} />
            </span>
          </div>

        </div>
        <div className="modalBody" style={{ paddingBottom: 0 }}>
          <div className="row publishAddContainer">
            <div className="col-md-12 imgView">

              <div>Yayına ait cevap anahtarını excel formatında giriniz.</div>
              <br />

              <label className="imageSelect svg-icon svg-icon-2x svg-icon-success" style={{ alignItems: 'center' }}>
                <svg viewBox="0 0 30 30">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path d="M28.705,7.506l-5.461-6.333l-1.08-1.254H9.262   c-1.732,0-3.133,1.403-3.133,3.136V7.04h1.942L8.07,3.818c0.002-0.975,0.786-1.764,1.758-1.764l11.034-0.01v5.228   c0.002,1.947,1.575,3.523,3.524,3.523h3.819l-0.188,15.081c-0.003,0.97-0.79,1.753-1.759,1.761l-16.57-0.008   c-0.887,0-1.601-0.87-1.605-1.942v-1.277H6.138v1.904c0,1.912,1.282,3.468,2.856,3.468l17.831-0.004   c1.732,0,3.137-1.41,3.137-3.139V8.966L28.705,7.506" fill="#434440" />
                    <path d="M20.223,25.382H0V6.068h20.223V25.382 M1.943,23.438h16.333V8.012H1.943" fill="#08743B" />
                    <polyline fill="#08743B" points="15.73,20.822 12.325,20.822 10.001,17.538 7.561,20.822 4.14,20.822 8.384,15.486 4.957,10.817    8.412,10.817 10.016,13.355 11.726,10.817 15.242,10.817 11.649,15.486 15.73,20.822  " />
                  </g>
                </svg>
                <span style={{ color: '#1BC5BD', marginTop: 5 }}>Dosya Seç</span>
                <input type="file" accept={SheetJSFT} onChange={handleChange} />
              </label>
              {
                file &&
                <div className="modalInfo font-size-sm">
                  <strong className="mr-5">Yüklenen Dosya: </strong>
                  <span>{file.name}</span>
                </div>
              }
            </div>
          </div>
          {
            error && <div style={{color: 'red'}}><strong>Dikkat: </strong>{error}</div>
          }
        </div>

        <div className="modalFooter" >
          <span className="btn font-weight-bolder font-size-sm btn-danger mr-2" onClick={cancelModal}>İptal</span>
          <span className={fileStatus ? "btn font-weight-bolder font-size-sm btn-success" : "btn font-weight-bolder font-size-sm btn-secondary"} onClick={() => { if (loadingLevel > 0) { fileProcess(); } }}>
            {
              loadingLevel > 0 &&
              (
                loadingLevel === 1 ?
                <span className="spinner spinner-dark spinnerTop" style={{ diplay: 'inline-block' }} />
                :
                <i className="flaticon2-check-mark" style={{ fontSize: 14 }} />
              )
            }
              Listeyi Gör
          </span>
        </div>

      </div>
    </Modal>
  </>);
}
