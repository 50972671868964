import React from "react";
import {useSubheader} from "../../_metronic/layout";
import {Notice, KTCodeExample} from "../../_metronic/_partials/controls";
import TextField from '@material-ui/core/TextField';

import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";

import {
    fade,
    withStyles,
    makeStyles,
    createMuiTheme
} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

export const Payments = () => {
    const className = 'primary';
    const baseColor = "primary";
    const widgetHeight = "150px";
    const suhbeader = useSubheader();
    suhbeader.setTitle("Ödeme Bilgileri");
    const classes = useStyles();
    return (<>
        <Notice>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className={`card card-custom bg-${baseColor} ${className}`} style={{height: widgetHeight, width: '25%'}}>
                    <div className="card-body">
                    <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}/>
                            </span>
                        <div className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}>5</div>
                        <a href="#" className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}> Toplam Kredi</a>
                    </div>
                </div>
                <div className={`card card-custom bg-${baseColor} ${className}`} style={{height: widgetHeight, width: '25%'}}>
                    <div className="card-body" style={{backgroundColor: '#c5a11b'}}>
                    <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}/>
                            </span>
                        <div className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}>4</div>
                        <a href="#" className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}> Kullanılan Kredi</a>
                    </div>
                </div>
                <div className={`card card-custom bg-${baseColor} ${className}`} style={{height: widgetHeight, width: '25%'}}>
                    <div className="card-body" style={{backgroundColor: '#1BC5BD'}}>
                    <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}/>
                            </span>
                        <div className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}>1</div>
                        <a href="#" className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}> Kalan Kredi </a>
                    </div>
                </div>
            </div>
        </Notice>

        <div className={`card card-custom`}>
            {/* Head */}
            <div className="card-header border-0 py-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">Ödeme Bilgilerim</span>
                </h3>
            </div>
            {/* Body */}
            <div className="card-body pt-0 pb-3">
                <div className="tab-content">
                    <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                            <tr className="text-left text-uppercase">
                                <th className="pl-7" style={{minWidth: "250px"}}><span
                                    className="text-dark-75">Tarih</span></th>
                                <th style={{minWidth: "100px"}}>Kitap Kredisi</th>
                                <th style={{minWidth: "100px"}}></th>
                                <th style={{minWidth: "100px"}}>Ödeme Tutarı</th>
                                <th style={{minWidth: "130px"}}></th>
                                <th style={{minWidth: "80px"}}/>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="pl-0 py-8">
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-50 symbol-light mr-4">
                                        </div>
                                        <div>
                                            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">22.07.2020</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        5
                      </span>
                                </td>
                                <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      </span>
                                </td>
                                <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        5000TL
                      </span>
                                </td>
                                <td>
                                </td>
                                <td className="pr-0 text-right">
                                    <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm">Fatura Detayı</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


    </>);
};


const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    buttonDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    sendInfoButton: {
        width: '100px',
        height: '40px'
    }
}));
