import React from "react";
import { usePublish } from "../../../hooks/use-publish";

export const Test = () => {
  const {subjectName, testList, setTestID} = usePublish();

  return (
    <>
      <div className={"card card-custom gutter-b"}>
          <div className="card-header">
            <div className="card-title">
            <h3 className="card-label">{subjectName} Testleri</h3>
            </div>
            <div className="card-toolbar">
              <div className="example-tools">
                <span className="example-toggle " title="View code"></span>
                <span className="example-copy " title="Copy code"></span>
              </div>
            </div>
          </div>
          <div className="card-body">

            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Test Adı</span></th>
                      <th style={{ minWidth: "100px" }}>Soru Sayısı</th>
                      <th style={{ minWidth: "80px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      testList &&
                      testList.map((item, i) => 
                        <tr key={i}>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {item.testAdi}
                            </span>
                            <span className="text-muted font-weight-bold">
                              {subjectName}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.cevaplar?.length}</span>
                            <span className="text-muted font-weight-bold">Soru</span>
                          </td>
                          <td className="pr-0 text-right">
                            <span className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setTestID(item.testID)}>
                              Detaylı İncele
                            </span>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>    
    </>
  );
}