import React from "react";
export function Row({ item, name, color="primary", click, desc="" }) {
    return (<>
        <div className={"d-flex align-items-center rounded p-5 pointer mb-1 bg-light-" + color}
        onClick={() => click(item)}>
            <span
                className={"svg-icon mr-5 svg-icon-lg svg-icon-" + color}>
                <svg width="24px" height="24px" viewBox="0 0 24 24">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <path fill="#000000" opacity="0.3"
                              d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                        <path fill="#000000" opacity="0.3"
                              d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                        <path fill="#000000"
                              d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"/>
                    </g>
                </svg>
            </span>
            <div className="d-flex flex-grow-1" style={{justifyContent: 'space-between'}}>
                <span className="font-weight-bold font-weight-bold">{name}</span>
                {
                    desc &&
                    <span className="text-muted font-weight-bold">{desc}</span>
                }
            </div>
        </div>
    </>)
}
